import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'record-progress',
  templateUrl: './record-progress.component.html',
  styleUrls: ['./record-progress.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordProgressComponent implements OnInit, AfterViewInit {
  @Input() data: any;

  recordData: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.recordData = changes['data'].currentValue;
    }
    this.cdr.detectChanges();
  }
}
