
<div class="container">

    <!------------------------------------------------Button--------------------------------->
    
    <h3>Assessor</h3>
    <nav style="display: inline-block;">
    <ol class="breadcrumb ml-auto">
      <li class="breadcrumb-item"><a href="#" [routerLink]="['/Dashboard']" >Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Assessor</li>
    </ol>
    </nav>
    
    <br>
    <mat-divider></mat-divider>
    <br>
    
    <!------------------------------------------------table content-------------------------->    
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput placeholder="Search" #input>
    </mat-form-field>
    
    <button class="add" (click)="addAssessor()" mat-mini-fab ><mat-icon class="white-icon" matTooltip="Add assessor">add</mat-icon></button>
    <button class="download" mat-mini-fab ><mat-icon class="white-icon" matTooltip="Download excel">download</mat-icon></button>
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef> S.No. </th>
          <td mat-cell *matCellDef="let element; let tableIndex = index"> {{(tableIndex + 1) || element.sno }} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Assessor </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="emailId">
            <th mat-header-cell *matHeaderCellDef> Email Id </th>
            <td mat-cell *matCellDef="let element"> {{element.emailId}} </td>
        </ng-container>
        <ng-container matColumnDef="adhaarNumber">
          <th mat-header-cell *matHeaderCellDef>Adhaar Number</th>
          <td mat-cell *matCellDef="let element"> {{element.adhaarNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="takshilaCertId">
          <th mat-header-cell *matHeaderCellDef>Takshshila Certificate Id</th>
          <td id="takshilaCertId" mat-cell *matCellDef="let element"> {{element.takshilaCertId}} </td>
        </ng-container>
        <ng-container matColumnDef="Mou">
          <th mat-header-cell *matHeaderCellDef>MoU</th>
          <td mat-cell *matCellDef="let element"> {{element.Mou}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
        <ng-container matColumnDef="activestatus">
            <th mat-header-cell *matHeaderCellDef> Active Status </th>
            <td mat-cell *matCellDef="let element"> {{element.activestatus}} </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
            <th mat-header-cell  *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let row" >
                <button mat-mini-fab matTooltip="Modify"><mat-icon class="matclass">create</mat-icon></button>
            </td>
          </ng-container>
     
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
    <!-------------------------------------------------end table content---------------------->   
    </div>
    




<!------------------------------------------------------Model ------------------------------------>
