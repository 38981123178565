<div class="auto-suggestions">
  <div>
    <input
      class="form-control"
      type="text"
      [value]="searchTerm"
      (input)="onSearchChange($event.target.value)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [placeholder]="placeholder"
    />
    <span
      *ngIf="searchTerm.length > 0"
      class="clear-icon"
      (click)="clearSearch()"
    >
      <mat-icon class="clear-icon">close</mat-icon>
    </span>
    <span *ngIf="searchTerm.length === 0" (click)="toggleDropdown()">
      <mat-icon class="down-arrow-icon" style="font-size: 18px"
        >expand_more</mat-icon
      >
    </span>
  </div>

  <ul
    *ngIf="isFocused && (isLoading || noSuggestions || suggestions.length)"
    class="suggestions-list"
  >
    <li *ngIf="isLoading && searchTerm.length > 0">Searching...</li>
    <li *ngIf="!isLoading && noSuggestions">No suggestions found</li>
    <li
      *ngFor="let suggestion of suggestions"
      (click)="onSuggestionClick(suggestion)"
    >
      {{ suggestion.value }}
    </li>
  </ul>
</div>
