export const convertDate = () => {
  var day;
  var month;
  var date = new Date();
  day = date.getDate();
  month = date.getMonth();
  month = month + 1;
  if (String(day).length == 1) day = '0' + day;
  if (String(month).length == 1) month = '0' + month;
  return date.getFullYear() + '' + month + '' + day;
};
