<ng-container>
  <div class="outer-container">
    <div class="inner-container">
      <div
        id="div1"
        *ngFor="let segment of segments; let i = index"
        [ngClass]="{
          segment: true,
          'segment-selected': isSelected(segment),
          'segment-not-selected': !isSelected(segment)
        }"
        (click)="onSegmentChange(segment)"
      >
        <ng-container *ngIf="segment !== 'datePicker'; else dateRangePicker">
          <div>{{ segment }}</div>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #dateRangePicker>
    <div id="dateRangePicker" class="date-range-container">
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input
          matInput
          [matDatepicker]="startPicker"
          (dateChange)="onStartDateChange($event.value)"
          [for]="startPicker"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input
          matInput
          [matDatepicker]="endPicker"
          (dateChange)="onEndDateChange($event.value)"
          [for]="startPicker"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
    </div>
  </ng-template>
</ng-container>
