import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilService } from '@app/shared/services/util.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-nonedittextbox',
  templateUrl: './nonedittextbox.component.html',
  styleUrls: ['./nonedittextbox.component.css']
})
export class NonedittextboxComponent implements OnInit {
  editorApiKey: string;
  mceOptionConfig: any;

  constructor(
    public dialogRef: MatDialogRef<NonedittextboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {text: any, title: string},
    private util: UtilService,
  ) {}

  ngOnInit(): void {
    this.mceOptionConfig = this.util.mceNonEditorConfig;
    this.editorApiKey = environment.EDITOR_API_KEY;
  }
}
