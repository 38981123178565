<mat-form-field class="tag-input" appearance="fill">
	<mat-chip-list #taglist>
		<mat-chip selected color="primary"
			*ngFor="let tag of selectedTags"
			[selectable]="selectable"
			[removable]="removable"
			(removed)="remove(tag)"
		>
			{{tag}}
			<mat-icon matChipRemove
				*ngIf="removable"
			>
				cancel
			</mat-icon>
		</mat-chip>
		<input placeholder="Add Tags"
			#tagInput
			[formControl]="tagCtrl"
			[matAutocomplete]="auto"
			[matChipInputFor]="taglist"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			(matChipInputTokenEnd)="add($event)"
		/>
	</mat-chip-list>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
		<mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
		  {{tag}}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
