import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { AddTagService } from '@app-shared/components/tags/add-tags.service';
import { UtilService } from '@app/shared/services/util.service';
import { GetService } from '@app/shared/table/get.service';

 @Component({
  	changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'add-tags',
    templateUrl: './add-tags.component.html',
    styleUrls: ['./add-tags.component.css'],
})
export class AddTagsComponent implements OnInit {
	@Input() allTags: any;	
	@Input() selectedTags: any;
	visible = true;
	selectable = true;
	removable = true;
	separatorKeysCodes: number[] = [ENTER, COMMA];
	tags: any = [];
	tagCtrl = new UntypedFormControl('');
	filteredTags: Observable<string[]>;

	@ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

	constructor(
		private tagSrvc: AddTagService,
		private util: UtilService,
		private cdr: ChangeDetectorRef,
		private getService: GetService
	) {
		this.filteredTags = this.tagCtrl.valueChanges.pipe(
			startWith(null),
			map((tag: string | null) => (tag ? this._filter(tag) : this.allTags.slice())),
		);
	}

	ngOnInit(): void {
		// this.getAllTags();
	}

	getAllTags() {
		this.getService.get('tagsByOrgId', '').subscribe((data: any) => {
			const response = data.body.responseBody;
			this.allTags = response.map((ele: any) => ele.tag_name);
			this.cdr.markForCheck();
		}, (err: any) => {
			this.util.errorNotification(err);
			this.cdr.markForCheck();
		});
	}

	

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();

		// Add our fruit
		if (value) {
			const splitedValue = value.split(' ');
			this.selectedTags.push(...splitedValue);
		}

		// Clear the input value
		event.input.value = '';

		this.tagCtrl.setValue(null);
	}

	remove(tag: string): void {
		const index = this.selectedTags.indexOf(tag);

		if (index >= 0) {
			this.selectedTags.splice(index, 1);
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.selectedTags.push(event.option.viewValue);
		this.tagInput.nativeElement.value = '';
		this.tagCtrl.setValue(null);
	}

	private _filter(value: string): string[] {
		const filterValue = value.toLowerCase();

		return this.allTags.filter(tag => tag.toLowerCase().includes(filterValue));
	}
	
}
