import { Injectable } from '@angular/core';

import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  
  constructor(
    private api: ApiService){}

  get jwt(): string | null{
    return localStorage.getItem('JwtToken');
  }

  getAdminById(adminID: number){
    const postData: APIRequest = {
       
      requestBody: {
        adminID
      }
    };
    
     
    return this.api.post(URL.ADMIN.GET_ADMIN, postData );
  }

  createOrEditAdmin(data: any) {
    const postData: APIRequest = {
       
      requestBody: {
        ...data
      }
    };
    
     
    return this.api.post(URL.ADMIN.CREATE_UPDATE_ADMIN, postData );
  }

  getMOUs(currentOrgID: number){
    const postData: APIRequest = {
      requestBody: {
        orgID: currentOrgID
      }
    };

    return this.api.post(URL.ADMIN.GET_ALL_MOUS, postData );
  }
}