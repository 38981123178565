import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TableColumn } from '@app/shared/table/TableColumn';

@Component({
  selector: 'activity-tracker-overview',
  templateUrl: 'activity-tracker-overview.component.html',
  styleUrls: ['activity-tracker-overview.component.css'],
})
export class ActivityTrackerOverviewComponent implements OnInit {
  @Output() onChangeTab: EventEmitter<any> = new EventEmitter<any>();
  @Input() data: any;
  selectedTab = 'Org Updates';
  dataSource: any = [];
  isOrgSuperAdmin: boolean = false;

  orgUpdatesColumns: Array<TableColumn> = [
    {
      name: 'S.No.',
      dataKey: 'sno',
      position: 'left',
      isSortable: true,
      type: 'sequence',
    },
    {
      name: 'Module',
      dataKey: 'module',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Action',
      dataKey: 'action',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Changed by',
      dataKey: 'changed_by',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Previous state',
      dataKey: 'previous_state',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Current state',
      dataKey: 'current_state',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Modified date',
      dataKey: 'modified_date',
      type: 'text',
      isSortable: true,
    },
  ];

  myUpdatesColumns: Array<TableColumn> = [
    {
      name: 'S.No.',
      dataKey: 'sno',
      position: 'left',
      isSortable: true,
      type: 'sequence',
    },
    {
      name: 'Module',
      dataKey: 'module',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Action',
      dataKey: 'action',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Previous state',
      dataKey: 'previous_state',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Current state',
      dataKey: 'current_state',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Modified date',
      dataKey: 'modified_date',
      type: 'text',
      isSortable: true,
    },
  ];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const superUserId = localStorage.getItem('superUserId');
    if (superUserId && parseInt(superUserId, 10) === 1) {
      this.isOrgSuperAdmin = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange) {
      this.dataSource = this.data;
      this.cdr.detectChanges();
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTab = event.tab.textLabel;
    this.onChangeTab.emit(this.selectedTab);
  }
}
