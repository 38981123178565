import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from '@app/shared/services/util.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = 'https://navrititechnologies.freshdesk.com/api/v2';

  private headers = new HttpHeaders({
    Authorization: 'Basic UnZRSVdDTVFHYm5pSU5Uelh4YUQ6WA==',
    Cookie: '_x_w=7_2',
  });

  constructor(private http: HttpClient, private util: UtilService) {}

  get orgID(): string | null {
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  getUser(userId: string): Observable<any> {
    const orgID = this.orgID;
    const uniqueExternalId = `${orgID}-${userId}`;
    const url = `${this.apiUrl}/contacts?unique_external_id=${uniqueExternalId}`;
    return this.http.get(url, { headers: this.headers });
  }

  createContact(userId: string): Observable<any> {
    const orgID = this.orgID;
    const uniqueExternalId = `${orgID}-${userId}`;
    const username = localStorage.getItem('username');
    const body = {
      name: username,
      unique_external_id: uniqueExternalId,
      custom_fields: {
        user: uniqueExternalId,
      },
    };
    const url = `${this.apiUrl}/contacts`;

    return this.http.post(url, body, { headers: this.headers });
  }

  createTicket(
    summary: string,
    description: string,
    file: any,
    priority: string,
    requester_id: string
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('email', 'support@certiplate.com');
    formData.append('subject', summary);
    formData.append('description', description);
    formData.append('priority', priority);
    formData.append('status', '2');
    formData.append('requester_id', requester_id);
    if (file) {
      file.forEach((file, index) => {
        formData.append(`attachments[]`, file);
      });
    }

    const url = `${this.apiUrl}/tickets`;
    return this.http.post(url, formData, { headers: this.headers });
  }

  getTickets(requester_id: any = 1060020792764): Observable<any> {
    const url = `${this.apiUrl}/tickets?requester_id=${requester_id}`;
    return this.http.get(url, { headers: this.headers });
  }
}
