import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TimelineEvent } from '@app-shared/components/ng-vertical-timeline/timeline-event';

import { environment } from '@env/environment';

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ng-vertical-timeline',
  templateUrl: './ng-vertical-timeline.component.html',
  styleUrls: ['./ng-vertical-timeline.component.scss']
})
export class NgVerticalTimelineComponent implements OnInit {
  @Input()
  data: TimelineEvent[];
  @Input()
  primaryColor: string;

  constructor(
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      this.data = data.events;
    });
  }

  isPair(id: number): boolean {
    return !(id % 2);
  }
  getExtension(filename: any) {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  }
  
  isImage(filename: any) {
    const ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png':
        return true;
    }
    return false;
  }
  previewFile(filename: any, isOrg: any) {
      if(this.isImage(filename)){
        let logoFile: any;
        const isOrgLogo = isOrg ? JSON.parse(isOrg) : false;
        if (isOrgLogo) {
          const [ logoHost, logoFileName] = filename.split('/logo/');
          //logoFile = `${environment.AWS_Certiplate}/${environment.Org_Logo_Folder}${logoFileName}`; 
        } else {
          //logoFile = environment.AWS_Certiplate + '/' + environment.Image_Logo_Folder + filename;
        }
        window.open(logoFile, '_blank');

      } else {
        let mouFile: any;
        const isOrgMou = isOrg ? JSON.parse(isOrg) : false;
        if (isOrgMou) {
          const [mouHost, mouFileName] = filename.split('/mou/');
          //mouFile = environment.AWS_Certiplate + '/' + environment.Org_MOU_Folder + mouFileName+'#zoom=85&toolbar=0&navpanes=0';
        } else {
          //mouFile = environment.AWS_Certiplate + '/' + environment.MOU_Folder + filename+'#zoom=85&toolbar=0&navpanes=0';
        }
        window.open(mouFile, '_blank');
      }
  }
}
