<div class="container">

<!------------------------------------------------table content-------------------------->

      <!-- Page content -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <div class="card bg-secondary shadow">
              
              <div class="card-body">
                <form>
                  
                  <div class="pl-lg-4">

                    <h4 class=" mb-3">Assessor information</h4>
                    <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Name</label>
                            <input type="text" id="txtAssessorName" class="form-control form-control-alternative" placeholder="Lead name" >
                            
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Gender</label>
                            <select class="form-control" type="text" value="" id="ddlSectorList" placeholder="State" size="0">
                                <option>-Select Gender-</option>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Other</option>
                            </select>
                          </div>
                        </div>

                      </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group required">
                          <label class="form-control-label" >Email Id</label>
                          <input type="text" id="txtEmailId" class="form-control form-control-alternative" placeholder="Email Id" >

                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label" >Alternate Email Id</label>
                          <input type="text" id="txtAlternateEmailId" class="form-control form-control-alternative" placeholder="Alternate email Id" >
                        </div>
                      </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Date of Birth</label>
                            <input type="text" id="dateDateOfBirth" class="form-control form-control-alternative" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MMM-YYYY', isAnimated: true }" placeholder="dd-mmm-yyyy">  
                          </div>
                        </div>
                      </div>
                    <hr class="my-4">

                    <h4 class=" mb-3">Assessor address information</h4>
                    <div class="row">
                      <div class="col-lg-6">
                        <div  class="form-group required">
                            <label class="form-control-label" >Address</label>
                            <input type="text" id="txtAddress" class="form-control form-control-alternative" placeholder="Address">
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group required">
                          <label class="form-control-label" >PIN Code</label>
                          <input type="text" id="txtPinCode" maxlength="6" class="form-control form-control-alternative col-lg-10" placeholder="Pin Code" >
                          <button mat-raised-button matTooltip="Load geography" style="float: right;padding: 10px 2px 5px 10px;margin-top: -7px;height: 42px;width:87px;"><mat-icon class="matIconURL">search</mat-icon></button>
                        </div> 
                      </div> 
                    </div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group required">
                          <label class="form-control-label" >State Name</label>
                          <input type="text" id="txtStateName" readonly class="form-control form-control-alternative" placeholder="State Name" >
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group required">
                          <label class="form-control-label" >District Name</label>
                          <input type="text" id="txtDistrictName" readonly class="form-control form-control-alternative" placeholder="District Name" >
                        </div>
                      </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Preferred States</label>
                            <ng-select bindLabel="Name" id="ddlPreferredStateList" placeholder="-Select States-" appendTo="body" multiple="true"
                              [searchable]="true" 
                              [clearable]="true"
                              [(ngModel)]="selectedItems" [ngModelOptions]="{standalone: true}">
                              <ng-option *ngFor="let item of dropdownList" [value]="item.Id">
                                  {{item.Name}}
                              </ng-option>
                          </ng-select>
                          </div>
                        </div>
                        </div>

                      <hr class="my-4">

                      <h4 class=" mb-3">Bank account information</h4>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Bank Name</label>
                            <div class="row">
                            <input type="text" id="txtBankName" class="form-control form-control-alternative col-sm-11" placeholder="Bank name" >
                            </div>
                            
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Account Holder Name</label>
                            <div class="row">
                              <input type="text" id="txtAccountHolderName" class="form-control form-control-alternative col-sm-11" placeholder="Account holder">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Account Number</label>
                            <div class="row">
                            <input type="text" id="txtAccountNumber" class="form-control form-control-alternative col-sm-11" placeholder="Account number" >
                            </div>
                            
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >IFSC Code</label>
                            <div class="row">
                              <input type="text" id="txtIfscCode" class="form-control form-control-alternative col-sm-11" placeholder="IFSC code">
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr class="my-4">

                      <h4 class=" mb-3">Other information</h4>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Sector</label>
                            <select class="form-control" type="text" value="" id="ddlSectorList" placeholder="State" size="0">
                                <option>-Select Sector-</option>
                                <option>Agriculture</option>
                                <option>Apparel</option>
                                <option>Automotive</option>
                                <option>BFSI</option>
                                <option>Construction</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >QP</label>
                            <select class="form-control" type="text" value="" id="ddlQpList" placeholder="State" size="0">
                                <option>-Select QP-</option>
                                <option>Mask Making</option>
                                <option>Salon Sanitation</option>
                                <option>Vehicle Disinfection</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Qualification Level</label>
                            <select class="form-control" type="text" value="" id="ddlQualification LevelList" placeholder="State" size="0">
                                <option>-Select Qualification Level-</option>
                                <option>Matriculation</option>
                                <option>Diploma</option>
                                <option>Graduate</option>
                                <option>Post Graduate</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Technical Qualification</label>
                            <input type="text" id="txtTechnicalQualification" class="form-control form-control-alternative col-sm-11" placeholder="Technical Qualification">
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Language(s)</label>
                            <select class="form-control" type="text" value="" id="ddlQpList" placeholder="State" size="0">
                                <option>-Select Languages-</option>
                                <option>English</option>
                                <option>Hindi</option>
                                <option>Kannad</option>
                                <option>Tamil</option>
                                <option>Telgu</option>
                                <option>Malayalam</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group required">
                              <label class="form-control-label" >Experience in Sector</label>
                              <input type="text" id="txtSectorExperience" class="form-control form-control-alternative col-sm-11" placeholder="Sector experience" >
                            </div>
                          </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Cluster(s)</label>
                            <select class="form-control" type="text" value="" id="ddlSourcedByList" placeholder="State" size="0">
                                <option>-Select Source Type-</option>
                                <option>South2</option>
                                <option>South2 T & C</option>
                                <option>PMIS West 2</option>
                                <option>PMIS Central 1</option>
                                <option>North 3 (CII)</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group required">
                            <label class="form-control-label" >Sourced By</label>
                            <select class="form-control" type="text" value="" id="ddlSourcedByList" placeholder="State" size="0">
                                <option>-Select Source Type-</option>
                                <option>Self</option>
                                <option>Vendor</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <!----------------------------------------success modal---------------------------------------->

                      <div id="myModal" class="modal fade">
                        <div class="modal-dialog modal-confirm">
                          <div class="modal-content">
                            <div class="modal-header justify-content-center">
                              <div class="icon-box">
                                <i class="material-icons">&#xE876;</i>
                              </div>
                              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                            </div>
                            <div class="modal-body text-center">
                              <h4>Great!</h4>	
                              <p>Your details has been saved successfully.</p>
                              
                            </div>
                          </div>
                        </div>
                      </div>     

                      <!----------------------------------------------------------------------------------------------->
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <button type="submit" id="submit" class="bounce-out-on-hover">Save changes</button>
                          </div>
                        </div>
                      </div>
                    

                    </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    
</div>



