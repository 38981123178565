import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

interface FilteredDataElement {
  filters: any;
}

export enum constants {
  'LIST' = 'list',
  'DATE' = 'date'
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit {
  @Input() filterData = [];
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Input() filteredData: FilteredDataElement = {
    filters: {},
  };
  @Input() noOfFilters: number
  isClicked = false;
  hoverActive = false;
  seeAllClickedName;
  selectedItem: any;
  selected = 0;
  originalData = [];
  itemIndex = 0;
  filterForm: UntypedFormGroup;
  searchDetails = { defaultValue: [], searchValue: [] };
  @ViewChild('filterName') filterName;
  range = new FormGroup({
    start: new FormControl<Date | null>(new Date()),
    end: new FormControl<Date | null>(null),
  });
  readonly constants: typeof constants = constants;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.originalData = JSON.parse(JSON.stringify(this.filterData));
    this.selectedItem = this.filterData[0];
    const startDate = new Date(new Date().setDate(new Date().getDate() - 30));
    const endDate = new Date();
    this.filterForm = this.fb.group({
      option: [''],
      start: [startDate],
      end: [endDate],
      value: [''],
      tags: [{}],
      search: ['']
    });
    this.range = new FormGroup({
      start: new FormControl(startDate),
      end: new FormControl(endDate),
    });
  }
  seeAllHandler(event, name, list) {
    event.stopPropagation();
    this.seeAllClickedName = name;
    this.searchDetails.defaultValue = list;
    this.searchDetails.searchValue = list;
  }

  searchHandler(search) {
    this.searchDetails.searchValue = this.searchDetails.defaultValue.filter(
      (element) => {
        return element.toLowerCase().includes(search.toLowerCase());
      }
    );
  }
  checkboxHandler(event, isClicked, item, parent) {
    event.stopPropagation();

    if (isClicked) {
      if (!this.filterForm.value?.tags?.[parent.dataKeyName]) {
        this.filterForm.value.tags[parent.dataKeyName] = [];
      }
      this.filterForm.value.tags[parent.dataKeyName].push(item);
    } else {
      this.filterForm.value.tags[parent.dataKeyName] = this.filterForm.value.tags[parent.dataKeyName].filter(
        (element) => element != item
      );
    }
  }

  containerHandler() {
    this.seeAllClickedName = null;
  }
  searchMenuHandler(event) {
    event.stopPropagation();
  }

  filterClickHandler() {
    this.isClicked = !this.isClicked;
  }

  clearFilter(type: string) {
    this.filterForm.reset();

    if (type === constants.DATE) {
      this.range.reset();
    } else if (type === constants.LIST) {
      this.selectedItem.data = this.originalData[this.selected].data
    }
  }

  applyFilter(type: string, datakey: string, name: string) {
    this.onSelect.emit({ type, ...this.filterForm.value, datakey, name });
    this.filterClickHandler();
  }

  onInputChange(event: any) {
    this.selectedItem.data = this.originalData[this.selected].data.filter(
      (item) => item.toLowerCase().includes(event.toLowerCase())
    );
  }
}
