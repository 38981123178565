<div *ngIf="isLoading" id="spinner">
  <mat-progress-spinner
    class="mat-spinner-color"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>

<div class="container">
  <div class="pt-2 pb-2 d-flex" style="justify-content: space-between">
    <p class="ticketId" style="line-height: 0.8em; margin: 0">
      Ticket Id: {{ ticketData.id }}
    </p>
    <p style="font-size: 0.8rem">
      {{ getFormattedDate(ticketData.created_at) }}
    </p>
  </div>
  <div
    class="d-flex"
    style="justify-content: space-between; align-items: center"
  >
    <div class="d-flex" style="gap: 3rem; font-size: 1rem">
      <span
        >Status: <strong>{{ status }}</strong></span
      >
      <span
        >Priority: <strong>{{ priority }}</strong></span
      >
    </div>
    <p
      *ngIf="ticketData.status != 5"
      href="#"
      class="close-ticket"
      (click)="closeTicket()"
      style="
        color: #379ae6;
        display: inline-block;
        margin-top: 1rem;
        font-size: 1rem;
        text-decoration: underline;
        cursor: pointer;
      "
    >
      Close Ticket
    </p>
  </div>

  <div style="margin-top: 1rem">
    <textarea
      rows="3"
      style="
        width: 100%;
        padding: 0.5rem;
        border-radius: 5px;
        border-color: #bcc1ca;
      "
      readonly
      >{{ ticketData.subject }}</textarea
    >
  </div>
  <div style="margin-top: 1rem">
    <textarea
      rows="5"
      style="
        width: 100%;
        padding: 0.5rem;
        border-radius: 5px;
        border-color: #bcc1ca;
      "
      readonly
      >{{ ticketData.description_text }}</textarea
    >
  </div>

  <!-- Attachments -->
  <div
    style="display: flex; margin-top: 1rem; flex-wrap: wrap"
    *ngIf="ticketData.attachments.length"
  >
    <p style="margin-right: 0.5rem; font-size: 1rem; flex-basis: 100%">
      Attachments:
    </p>
    <div
      *ngFor="let attachment of ticketData.attachments; let i = index"
      style="margin-bottom: 0.5rem"
    >
      <div
        style="
          color: #379ae6;
          text-decoration: none;
          margin-right: 0.5rem;
          cursor: pointer;
          text-decoration: underline;
          word-break: break-all;
        "
        (click)="onDownloadClick(attachment.attachment_url)"
      >
        {{
          attachment.name + (i < ticketData.attachments.length - 1 ? "," : "")
        }}
      </div>
    </div>
  </div>

  <!-- Comments Section -->
  <div class="pb-1">
    <p class="pb-1" style="font-size: 1rem"><strong>Comments</strong></p>
    <div style="display: flex; align-items: center">
      <input
        type="text"
        placeholder="Add comment"
        [(ngModel)]="comment"
        style="
          flex: 1;
          padding: 0.5rem;
          border-radius: 5px;
          border-color: #bcc1ca;
        "
      />
      <button
        (click)="sendComment()"
        style="
          margin-left: 1rem;
          padding: 0.5rem 1rem;
          background-color: #22c95cfa;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        "
      >
        Send
      </button>
    </div>

    <div class="conversation-container">
      <div
        class="d-flex pt-2 px-2"
        style="gap: 1rem; align-items: center; justify-content: space-between"
        *ngFor="let conversation of ticketData.conversations"
      >
        <div class="d-flex" style="gap: 1rem; align-items: center">
          <div class="avatar-circle">
            <div class="initials">
              {{ conversation.user_id == requesterId ? username[0] : "A" }}
            </div>
          </div>
          <div>{{ conversation.body_text }}</div>
        </div>
        <div>
          <p style="font-size: 0.8rem; margin: 0">
            {{ getFormattedDate(conversation.created_at) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
