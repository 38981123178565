<div style="display: flex;">
    <button class="mat-close-btn" style="top:1.5rem;right:1rem;" mat-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <h2 mat-dialog-title style="margin:0px;width:50%;">List of MOU Files</h2>
    <button mat-mini-fab matTooltip="Upload MOU" (click)="addMOUPopup()" style="display: block;margin-left: auto; margin-right: 4rem;">
        <mat-icon class="matclass">upload</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <mat-form-field appearance="standard" class="filter-position">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Type to search any" #input>
    </mat-form-field>
    <div class="table-container mat-elevation-z8">
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No.</th>
                <td mat-cell *matCellDef="let element; let tableIndex = index"> {{(tableIndex + 1) || element.sno }} </td>
            </ng-container>
            <ng-container matColumnDef="agreement_start_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Agreement Start Date</th>
                <td mat-cell *matCellDef="let element">{{element.agreement_start_date | date: 'dd-MMM-yyyy'}}</td>
            </ng-container>
            <ng-container matColumnDef="agreement_expiry_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Agreement Expiry Date</th>
                <td mat-cell *matCellDef="let element">{{element.agreement_expiry_date | date: 'dd-MMM-yyyy'}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th style="padding-left: 52px;" mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-mini-fab matTooltip="Preview MOU File" (click)="previewMouFile(element.agreement_file)">
                        <mat-icon class="matclass">remove_red_eye</mat-icon>
                    </button>
                    <button mat-mini-fab matTooltip="Modify MOU" (click)="modifyMOU(element.id)" [disabled]="!element.is_active">
                        <mat-icon class="matclass">edit</mat-icon>
                    </button>
                    <button type="button" class="btnTableRow" mat-mini-fab matTooltip="Show Revision History" (click)="showRevisionHistory(element)"><i class="fa fa-history matclass" style="margin-bottom: 5px;" aria-hidden="true"></i></button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <ng-template matNoDataRow>
                <tr>
                    <td colspan="6" style="text-align: center;padding: 10px;">
                        No records found!
                    </td>
                </tr>
            </ng-template>
        </table>
    </div>
</mat-dialog-content>