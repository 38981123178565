import { Injectable } from '@angular/core';
import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';
import { CREATE, OPERATION_UPDATE, DELETE, VIEW } from '@app/shared/constants/application-constants';

@Injectable({
  providedIn: 'root',
})
export class FinanceService {
  constructor(private api: ApiService, private util: UtilService) {}

  get jwt(): string | null {
    return localStorage.getItem('JwtToken');
  }

  get userId(): number {
    return parseInt(localStorage.getItem('UserId'));
  }

  get userRoleId(): number {
    return parseInt(localStorage.getItem('UserRoleId'));
  }
  get orgID(): string | null {
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  get getAPIHeaders(): object {
    return {
      "x-operation" : VIEW
    }
  }

  /**
   * get Rates
   */
  getRates(fromDate?: string, toDate?: string) {
    if (!fromDate && !toDate) {
      return this.api.get(`${URL.FINANCE.RATE_MASTER}`, undefined, this.getAPIHeaders);
    }
    return this.api.get(`${URL.FINANCE.RATE_MASTER}?from_date=${fromDate}&to_date=${toDate}`, undefined, this.getAPIHeaders);
  }

  getRate(id: number) {
    return this.api.get(`${URL.FINANCE.RATE_MASTER}/${id}`, undefined, this.getAPIHeaders);
  }

  addorEditRateMaster(data: any, id: any) {
    const headers = {
      "x-operation" : id && id !== 'null' ? OPERATION_UPDATE : CREATE
    }
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    if (id) {
      return this.api.put(`${URL.FINANCE.RATE_MASTER}/${parseInt(id)}`, postData, headers)
    }
    return this.api.post(URL.FINANCE.RATE_MASTER, postData, headers)
  }

  getInvoices(fromDate?: string, toDate?: string) {
    if (fromDate && toDate) {
      return this.api.get(`${URL.FINANCE.INVOICES}?from_date=${fromDate}&to_date=${toDate}`, undefined, this.getAPIHeaders);
    }
    return this.api.get(`${URL.FINANCE.INVOICES}`, undefined, this.getAPIHeaders);
  }

  getBills(fromDate?: string, toDate?: string) {
    if (fromDate && toDate) {
      return this.api.get(`${URL.FINANCE.BILLS}?from_date=${fromDate}&to_date=${toDate}`, undefined, this.getAPIHeaders);
    }
    return this.api.get(`${URL.FINANCE.BILLS}`, undefined, this.getAPIHeaders);
  }

  getInvoice(id: string, customerId?: string, fromDate?: string, toDate?: string) {
    if (customerId) {
      return this.api.get(`${URL.FINANCE.INVOICES}/${customerId}/${id}`, undefined, this.getAPIHeaders);
    }
    if (fromDate && toDate) {
      return this.api.get(`${URL.FINANCE.INVOICES}/${id}?from_date=${fromDate}&to_date=${toDate}`, undefined, this.getAPIHeaders);
    }
    return this.api.get(`${URL.FINANCE.INVOICES}/${id}`, undefined, this.getAPIHeaders);
  }

  getReceipts() {
    return this.api.get(`${URL.FINANCE.RECEIPTS}`, undefined, this.getAPIHeaders);
  }

  getReceipt(id: string) {
    return this.api.get(`${URL.FINANCE.RECEIPTS}/${id}`, undefined, this.getAPIHeaders);
  }

  addorEditInvoices(data: any, id: any) {
    const headers = {
      "x-operation" : id && id !== 'null' ? OPERATION_UPDATE : CREATE
    }
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    if (id) {
      return this.api.put(`${URL.FINANCE.INVOICES}/${parseInt(id)}`, postData, headers)
    }
    return this.api.post(URL.FINANCE.INVOICES, postData, headers)
  }

  addorEditReceipt(data: any, id: any) {
    const headers = {
      "x-operation" : id && id !== 'null' ? OPERATION_UPDATE : CREATE
    }
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    if (id) {
      return this.api.put(`${URL.FINANCE.RECEIPTS}/${parseInt(id)}`, postData, headers)
    }
    return this.api.post(URL.FINANCE.RECEIPTS, postData, headers)
  }

  deleteReceipt(id: string) {
     const headers = {
      "x-operation" : DELETE
    }
    return this.api.delete(`${URL.FINANCE.RECEIPTS}`, id, headers);
  }

  getCustomers() {
    return this.api.get(URL.SALES.CUSTOMERS, undefined, this.getAPIHeaders);
  }

  getAddresses() {
    return this.api.get(URL.FINANCE.BILLING_ADDRESS, undefined, this.getAPIHeaders)
  }

  addAddress(data: any) {
    const headers = {
      "x-operation" : CREATE
    }
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    return this.api.post(`${URL.FINANCE.BILLING_ADDRESS}`, postData, headers)
  }

  getRateFilter(customer: number, service: string, date: string) {
    return this.api.get(`${URL.FINANCE.RATE_MASTER}?customer=${customer}&service=${service}&invoice_date=${date}`, undefined, this.getAPIHeaders);
  }

  addorEditBills(data: any, id: any) {
    const headers = {
      "x-operation" : id && id !== 'null' ? OPERATION_UPDATE : CREATE
    }
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    if (id) {
      return this.api.put(`${URL.FINANCE.BILLS}/${parseInt(id)}`, postData, headers)
    }
    return this.api.post(URL.FINANCE.BILLS, postData, headers)
  }

  addorEditPayments(data: any, id: any) {
    const headers = {
      "x-operation" : id && id !== 'null' ? OPERATION_UPDATE : CREATE
    }
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    if (id) {
      return this.api.put(`${URL.FINANCE.PAYMENTS}/${parseInt(id)}`, postData, headers)
    }
    return this.api.post(URL.FINANCE.PAYMENTS, postData, headers)
  }

  getPayments(fromDate?: string, toDate?: string) {
    if (fromDate && toDate) {
      return this.api.get(`${URL.FINANCE.PAYMENTS}?from_date=${fromDate}&to_date=${toDate}`, undefined, this.getAPIHeaders);
    }
    return this.api.get(`${URL.FINANCE.PAYMENTS}`, undefined, this.getAPIHeaders);
  }

  getPayment(id: string) {
    return this.api.get(`${URL.FINANCE.PAYMENTS}/${id}`, undefined, this.getAPIHeaders);
  }

  deletePayment(id: string) {
    return this.api.delete(`${URL.FINANCE.PAYMENTS}`, id, this.getAPIHeaders);
  }
}
