import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.css'],
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() chartOptions: any = {};
  @Input() categories: any = [];
  @Input() seriesData: any = [];
  @Input() updateCategories: boolean;
  updateFlag = false;

  Highcharts = Highcharts;
  initialized = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    // this.chartOptions = {
    //   chart: {
    //     type: 'column',
    //   },
    //   title: {
    //     text: '',
    //   },
    //   xAxis: {
    //     categories: this.categories,
    //     crosshair: true,
    //   },
    //   yAxis: {
    //     min: 0,
    //     title: {
    //       text: 'Questions Count',
    //     },
    //   },
    //   tooltip: {
    //     headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    //     pointFormat:
    //       '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    //       '<td style="padding:0"><b>{point.y}</b></td></tr>',
    //     footerFormat: '</table>',
    //     shared: true,
    //     useHTML: true,
    //   },
    //   plotOptions: {
    //     column: {
    //       pointPadding: 0.2,
    //       borderWidth: 0,
    //     },
    //   },
    //   series: this.seriesData,
    // };
    this.initialized = true;

    HC_exporting(Highcharts);
    HC_exportData(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initialized = true;

    this.updateFlag = false;

    if (this.initialized) {
      if (changes.seriesData || changes.categories) {
        if (this.updateCategories && changes.categories) {
          // Update categories if they have changed
          this.chartOptions.xAxis.categories = this.categories;
        }

        if (changes.seriesData) {
          this.chartOptions.series = [...this.seriesData];
        }

        this.updateFlag = true;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }
    }
  }
}
