import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { getXReferer } from '@app/operation/project/project.constants';
import { HEADER_X_REFFERER } from '@app/shared/constants/application-constants';
declare var $ :any;

export interface AssessorElement {
  sno:number
  name: string;
  sector:string;
  emailId:string;
  altEmailId:string;
  accountHolder:string;
  accountNumber:string;
  bankName:string;
  adhaarNumber:string;
  panNumber:string;
  takshilaCertId:string;
  Mou:string;
  resume:string;
  sourceType:string;
  sourceBy:string;
  activestatus:string;
  status:string;


}

const ELEMENT_DATA: AssessorElement[] = [
  {sno: 1, name: 'Retheesh raj', emailId:'rethiesh@gmail.com', altEmailId:'',sector:'Agriculture',accountHolder:'Retheesh raj',accountNumber:'0492118000499',bankName:'CANARA BANK',adhaarNumber:'No AadharNo',panNumber:'Not Uploaded',takshilaCertId:'Not Certified',Mou:'	Not Uploaded',resume:'Not Uploaded',sourceType:'NA', sourceBy:'NA',activestatus:'Active',status:'Status Info'},
  {sno: 2, name: 'K S Kanna', emailId:'	kanna@navriti.com', altEmailId:'',sector:'Agriculture',accountHolder:'K S Kanna',accountNumber:'5.01E+13',bankName:'HDFC',adhaarNumber:'No AadharNo',panNumber:'Not Uploaded',takshilaCertId:'Not Certified',Mou:'	Not Uploaded',resume:'Not Uploaded',sourceType:'NA', sourceBy:'NA',activestatus:'Active',status:'Status Info'},
  {sno: 3, name: 'Dharmendra', emailId:'dharmendraawasthi123@rediffmail.com', altEmailId:'',sector:'Agriculture',accountHolder:'Dharmendra kumar Awasthi',accountNumber:'MSB 30641449545',bankName:'SBI',adhaarNumber:'No AadharNo',panNumber:'Not Uploaded',takshilaCertId:'Not Certified',Mou:'	Not Uploaded',resume:'Not Uploaded',sourceType:'NA', sourceBy:'NA',activestatus:'Active',status:'Status Info'},
  {sno: 4, name: 'Rajesh J', emailId:'rajesh@navriti.com', altEmailId:'',sector:'Agriculture',accountHolder:'Rajesh J',accountNumber:'3.85053E+13',bankName:'South indian bank',adhaarNumber:'No AadharNo',panNumber:'Not Uploaded',takshilaCertId:'Not Certified',Mou:'	Not Uploaded',resume:'Not Uploaded',sourceType:'NA', sourceBy:'NA',activestatus:'Active',status:'Status Info'},
  {sno: 5, name: 'Pooja Kamboj', emailId:'poojakamboj12@gmail.com', altEmailId:'',sector:'Agriculture',accountHolder:'poojakamboj12@gmail.com',accountNumber:'82852200056662',bankName:'CANARA BANK',adhaarNumber:'No AadharNo',panNumber:'Not Uploaded',takshilaCertId:'Not Certified',Mou:'	Not Uploaded',resume:'Not Uploaded',sourceType:'NA', sourceBy:'NA',activestatus:'Active',status:'Status Info'},
  {sno: 6, name: 'Mahesh Kumar', emailId:'maheshkumar123@gmail.com', altEmailId:'',sector:'Agriculture',accountHolder:'',accountNumber:'',bankName:'',adhaarNumber:'No AadharNo',panNumber:'Not Uploaded',takshilaCertId:'Not Certified',Mou:'	Not Uploaded',resume:'Not Uploaded',sourceType:'NA', sourceBy:'NA',activestatus:'Active',status:'Status Info'},
  {sno: 7, name: 'Chandrashekhar Aapte', emailId:'chandrashekharapte@gmail.com', altEmailId:'',sector:'Agriculture',accountHolder:'Smita Pagnis',accountNumber:'00621140014379',bankName:'CANARA BANK',adhaarNumber:'No AadharNo',panNumber:'Not Uploaded',takshilaCertId:'Not Certified',Mou:'	Not Uploaded',resume:'Not Uploaded',sourceType:'NA', sourceBy:'NA',activestatus:'Active',status:'Status Info'},
  {sno: 8, name: 'Arvind kumar', emailId:'arvindkumarv2433@gmail.com', altEmailId:'',sector:'Agriculture',accountHolder:'',accountNumber:'',bankName:'CANARA BANK',adhaarNumber:'No AadharNo',panNumber:'Not Uploaded',takshilaCertId:'Not Certified',Mou:'	Not Uploaded',resume:'Not Uploaded',sourceType:'NA', sourceBy:'NA',activestatus:'Active',status:'Status Info'},
  {sno: 9, name: 'Ambuj Tiwari', emailId:'ambujtiwari93@gmail.com', altEmailId:'',sector:'Agriculture',accountHolder:'Rahul Verma',accountNumber:'86010100300155',bankName:'CANARA BANK',adhaarNumber:'No AadharNo',panNumber:'Not Uploaded',takshilaCertId:'Not Certified',Mou:'	Not Uploaded',resume:'Not Uploaded',sourceType:'NA', sourceBy:'NA',activestatus:'Active',status:'Status Info'},

];

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-assessor',
  templateUrl: './assessor.component.html',
  styleUrls: ['./assessor.component.css']
})
export class AssessorComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['sno', 'name','emailId','activestatus','adhaarNumber','takshilaCertId','Mou','status','Actions'];
  dataSource = ELEMENT_DATA;

  constructor(private route: Router) { }

  ngOnInit(): void {
    const x_referer = getXReferer(this.route.url)
    localStorage.setItem(HEADER_X_REFFERER, x_referer);
  }

  ngOnDestroy(): void {
    localStorage.setItem(HEADER_X_REFFERER, "");
  }

  addAssessor(){
    this.route.navigate(['Resources/Assessor/AddAssessor'])
  }

}
