<ng-container>
  <div class="mt-3">
    <div *ngIf="categories && categories.length > 0; else noRecords">
      <app-barchart
        [chartOptions]="chartOptions"
        [categories]="categories"
        [updateCategories]="true"
        [seriesData]="seriesData"
      ></app-barchart>
    </div>
    <ng-template #noRecords>
      <div class="no_records_found">
        <img
          src="assets/img/svgicons/no_records_found.svg"
          width="36"
          height="36"
        />
        <p style="padding-top: 6px">No records found</p>
      </div>
    </ng-template>
  </div>
</ng-container>
