<div class="row">
  <h2 style="width: 86%; margin-left: 1rem">{{ data?.title }} Upload</h2>
  <div class="col-1">
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<hr />
<div class="row">
  <div class="col-6">
    <label class="btn btn-default p-0">
      <input type="file" multiple (change)="selectFiles($event)" multiple />
    </label>
  </div>

  <div class="col-4">
    <button
      class="btn btn-success btn-sm"
      [disabled]="!selectedFiles"
      (click)="uploadFiles()"
    >
      Upload
    </button>
  </div>
</div>

<div
  *ngIf="message.length"
  class="row alert alert-secondary my-16"
  role="alert"
>
  <div *ngFor="let msg of message; let i = index" class="flex-row">
    <li>{{ msg?.message }}</li>
    <button class="download" mat-mini-fab (click)="downloadFile(msg)">
      <mat-icon class="white-icon" matTooltip="Download File"
        >download</mat-icon
      >
    </button>
  </div>
</div>

<!-- <div class="card mt-3" *ngIf="fileInfos">
    <div class="card-header">List of Files</div>
    <ul
      class="list-group list-group-flush"
      *ngFor="let file of fileInfos | async"
    >
      <li class="list-group-item">
        <a href="{{ file.url }}">{{ file.name }}</a>
      </li>
    </ul>
  </div> -->

<div class="mt-3" *ngIf="!files?.length">No Files Uploaded Yet</div>
<div class="form-group mt-3 required" *ngIf="data.title === constants.ASSESSMENT_PLAN">
  <label class="form-control-label"
    >Remarks<span class="text-red">*</span></label
  >
  <textarea
    name="remarks"
    class="form-control"
    (input)="remarks = $event.target.value"
  ></textarea>
</div>

<div class="form-group mt-5">
  <button
    style="width: 10%; float: right"
    type="submit"
    id="submit"
    class="form-submit-btn mat-raised-button"
    [disabled]="data.title === constants.ASSESSMENT_PLAN ? !files?.length || !remarks : !files?.length"
    (click)="saveFile()"
  >
    Save
  </button>
</div>
