<div class="outer-container">
  <div class="inner-content">
    <div class="title">{{ recordData?.title }}</div>
    <div class="value">{{ recordData?.totalCount }}</div>
  </div>
  <div class="inner-content">
    <div class="trend_up_or_down">
      <div *ngIf="recordData?.isIncreased; else trendDown">
        <img src="assets/img/svgicons/trend_up.svg" width="42" height="42" />
      </div>
      <ng-template #trendDown>
        <img src="assets/img/svgicons/trend_down.svg" width="42" height="42" />
      </ng-template>
      <div class="percentage">{{ recordData?.percentage }} %</div>
    </div>
    <div class="period_text">
      In the last {{ recordData?.selectedDateFilter }}
    </div>
  </div>
</div>
