<div class="timeline" *ngIf="data; else elseBlock">
  <div
    *ngFor="let objectItem of data | keyvalue; index as i"
    style="display: flex; flex-direction: column"
  >
    <div
      [ngClass]="isPair(i) === true ? 'dateTemplateRight' : 'dateTemplateLeft'"
    >
      <span>{{ objectItem?.key?.split(":")[1] | date : "yyyy-MMM-dd" }}</span>
      <div *ngIf="objectItem?.key?.split(':')[0]">
        <span>{{ objectItem?.key?.split(":")[0] }}</span>
      </div>
    </div>
    <div *ngFor="let item of objectItem.value">
      <div
        class="container left"
        [ngClass]="isPair(i) === true ? 'left' : 'right'"
      >
        <div class="inner">
          <div class="head">
            <div class="title">
              {{ item.title }}
            </div>
          </div>
          <div class="body">
            <p
              *ngIf="item.title === 'Created'; else elseBlock"
              [innerHTML]="item.content"
            ></p>
            <ng-template #elseBlock>
              <div class="change-info" *ngFor="let con of item.content">
                <div>
                  <span class="label">Label Name: </span>
                  <span>{{ con.label }}</span>
                </div>
                <div>
                  <span class="label">Old Value: </span>
                  <span *ngIf="con.isFile; else elseBlock">
                    <a
                      href="javascript:void(0)"
                      (click)="previewFile(con.oldValue, con.isOrg)"
                      >{{ con.oldValue }}</a
                    >
                  </span>
                  <ng-template #elseBlock>
                    <span *ngIf="con.isLink; else elseLinkBlock1">
                      <a href="{{ con.oldValue }}" target="_blank">{{
                        con.oldValue
                      }}</a>
                    </span>
                    <ng-template #elseLinkBlock1>
                      <span
                        *ngIf="con.isList; else elseIsListBlock"
                        [innerHTML]="con.oldValue | json"
                      >
                      </span>
                      <ng-template #elseIsListBlock>
                        <span>{{
                          con.oldValue == "f"
                            ? "In-Active"
                            : ((con.oldValue == "t" ? "Active" : con.oldValue)
                              | json)
                        }}</span>
                      </ng-template>
                    </ng-template>
                  </ng-template>
                </div>
                <div>
                  <span class="label">New Value: </span>
                  <span *ngIf="con.isFile; else elseBlock2">
                    <a
                      href="javascript:void(0)"
                      (click)="previewFile(con.newValue, con.isOrg)"
                      >{{ con.newValue }}</a
                    >
                  </span>
                  <ng-template #elseBlock2>
                    <span *ngIf="con.isLink; else elseLinkBlock2">
                      <a href="{{ con.newValue }}" target="_blank">{{
                        con.newValue
                      }}</a>
                    </span>
                    <ng-template #elseLinkBlock2>
                      <span
                        *ngIf="con.isList; else elseIsListBlock2"
                        [innerHTML]="con.newValue | json"
                      >
                      </span>
                      <ng-template #elseIsListBlock2>
                        <span>{{
                          con.newValue == "f"
                            ? "In-Active"
                            : ((con.newValue == "t" ? "Active" : con.newValue)
                              | json)
                        }}</span>
                      </ng-template>
                    </ng-template>
                  </ng-template>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <div class="label" style="text-align: center">
    No changes were found to show the revision history logs.
  </div>
</ng-template>
