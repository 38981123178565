import { KeyValue, Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Route, Router } from '@angular/router';
import {
  goToAll,
  tableMappings,
} from '@app/shared/constants/application-constants';
import { SearchService } from './search.service';
import { UtilService } from '@app/shared/services/util.service';
import { AppNotificationsService } from '@app/shared/services/app-notifications.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { CdkPortal } from '@angular/cdk/portal';
import { convertObjectToArray } from '@app/operation/project/project.constants';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @ViewChild(CdkPortal) portal: CdkPortal;
  excludedKeys = [
    'id',
    'is_deleted',
    'tags',
    'custom_attrs',
    'is_active',
    'modified_datetime',
    'created_datetime',
    'is_archived',
  ];
  excludedSubstring = '_id';
  goToAll = goToAll;
  recentSearches = [];
  panelOpenState = false;
  searchResults: any = [];
  allResults = 0;
  pageSize = 5;
  showResults = [];
  tags = {
    Candidates: 0,
    Customers: 0,
    Projects: 0,
    Courses: 0,
    Questions: 0,
    Leads: 0,
    Schemes: 0,
    Assessments: 0,
    Users: 0,
    Oppurtunities: 0,
    Rates: 0,
    Bills: 0,
    Invoices: 0,
    Receipt: 0,
    'User Categories': 0,
  };
  sub: any;
  isSearchInput = false;
  length = 0;
  options: string[] = [];
  searchInput: string = '';
  @Output() inputChange = new EventEmitter<number>();
  searchObject: any;
  suggestions: any = [];
  isOpen: boolean = false;
  toggle: boolean = false;
  isLoading: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private searchSrvc: SearchService,
    private util: UtilService,
    private location: Location,
    private changeDetectorRef: ChangeDetectorRef,
    private notificationsService: AppNotificationsService
  ) {}

  ngOnInit(): void {
    this.sub = this.router.snapshot.paramMap.get('key');

    const nav = this.route.getCurrentNavigation();
    this.searchObject = nav?.extras.state?.['search'];
    // this.suggestions = nav?.extras.state?.['suggestions'];

    if (!this.searchObject) {
      this.searchObject = history.state.search;
    }

    if (!this.suggestions) {
      this.suggestions = history.state.suggestions;
    }

    if (this.searchObject) {
      const data = {
        schema: this.searchObject?.found_in?.schema,
        table: this.searchObject?.found_in?.table,
        column: this.searchObject?.found_in?.column,
        search: this.searchObject?.found,
      };
      this.getSearchResults(data);
      this.getRecentSearches();
    } else {
      this.getRecentSearches();
    }
  }

  onClickSearchSuggestion(event: any) {
    const data = {
      schema: event.found_in?.schema,
      table: event.found_in?.table,
      column: event.found_in?.column,
      search: event.found,
    };
    this.searchInput = event.found;
    this.suggestions = [];
    this.getSearchResults(data);
  }

  getSearchResults(data: any) {
    this.searchResults = [];
    this.searchSrvc.postSearch(data).subscribe(
      (data: any) => {
        this.tags = {
          Candidates: 0,
          Customers: 0,
          Projects: 0,
          Courses: 0,
          Questions: 0,
          Leads: 0,
          Schemes: 0,
          Assessments: 0,
          Users: 0,
          Oppurtunities: 0,
          Rates: 0,
          Bills: 0,
          Invoices: 0,
          Receipt: 0,
          'User Categories': 0,
        };
        this.searchResults = data?.body?.responseBody;
        this.isSearchInput = true;
        this.length = 1;
        this.suggestions = [];
        this.showResults = [];
        const tableName = this.capitalizeFirstLetterAndAppendS(
          this.searchResults.label?.toLowerCase()
        );
        if (tableName && this.tags.hasOwnProperty(tableName)) {
          console.log({ tableName });
          this.tags[tableName] = this.tags[tableName] + 1;
        }
        this.changeDetectorRef.detectChanges();
        this.toggle = false;
      },
      (err: any) => {
        this.isSearchInput = true;
        this.toggle = false;
        this.util.errorNotification(err);
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  getRecentSearches() {
    this.searchSrvc.getRecentSearches().subscribe(
      (data: any) => {
        this.tags = {
          Candidates: 0,
          Customers: 0,
          Projects: 0,
          Courses: 0,
          Questions: 0,
          Leads: 0,
          Schemes: 0,
          Assessments: 0,
          Users: 0,
          Oppurtunities: 0,
          Rates: 0,
          Bills: 0,
          Invoices: 0,
          Receipt: 0,
          'User Categories': 0,
        };
        this.recentSearches = data?.body?.responseBody;
        this.length = this.recentSearches.length;
        if (!this.isSearchInput) {
          this.showResults = this.recentSearches.slice(0, this.pageSize);
          this.showResults.forEach((item) => {
            const tableName = this.capitalizeFirstLetterAndAppendS(
              item?.label?.toLowerCase()
            );
            if (tableName && this.tags.hasOwnProperty(tableName)) {
              this.tags[tableName] = this.tags[tableName] + 1;
            }
          });
        }
        this.changeDetectorRef.detectChanges();
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  onChangePage(pe: PageEvent) {
    this.tags = {
      Candidates: 0,
      Customers: 0,
      Projects: 0,
      Courses: 0,
      Questions: 0,
      Leads: 0,
      Schemes: 0,
      Assessments: 0,
      Users: 0,
      Oppurtunities: 0,
      Rates: 0,
      Bills: 0,
      Invoices: 0,
      Receipt: 0,
      'User Categories': 0,
    };
    this.showResults = this.recentSearches.slice(
      pe.pageIndex * pe.pageSize,
      pe.pageIndex * pe.pageSize + pe.pageSize
    );
    this.showResults.forEach((item) => {
      console.log(item?.label, 'label');
      const tableName = this.capitalizeFirstLetterAndAppendS(
        item?.label?.toLowerCase()
      );
      console.log({ tableName }, this.tags.hasOwnProperty(tableName));
      if (tableName && this.tags.hasOwnProperty(tableName)) {
        this.tags[tableName] = this.tags[tableName] + 1;
      }
    });
  }

  onInputChange() {
    this.isLoading = true;
    if (this.searchInput.length >= 3) {
      this.searchSrvc.getAutoSuggestions(this.searchInput).subscribe(
        (data: any) => {
          this.isLoading = false;
          this.suggestions = data?.body?.responseBody;
          this.allResults = this.suggestions.length;
          if (this.allResults === 0) {
            this.toggle = true;
          }
          this.changeDetectorRef.detectChanges();
        },
        (err: any) => {
          this.isLoading = false;
          this.util.errorNotification(err);
          this.changeDetectorRef.markForCheck();
        }
      );
    }
    this.notificationsService.open();
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  orderOriginal = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.value > b.value ? -1 : b.value > a.value ? 1 : 0;
  };

  search() {
    // this.isSearchInput = false;
    this.onInputChange();
  }

  capitalizeFirstLetterAndAppendS(string: string) {
    console.log({ string });
    const capitalizedString = string.charAt(0).toUpperCase() + string.slice(1);
    console.log({ capitalizedString }, capitalizedString.endsWith('s'));
    return capitalizedString.endsWith('s')
      ? capitalizedString
      : capitalizedString + 's';
  }

  getFilteredResults(results: any[]): any[] {
    return results.map((result) => {
      return Object.keys(result)
        .filter(
          (key) =>
            !this.excludedKeys.includes(key) &&
            !key.includes(this.excludedSubstring) &&
            result[key] !== null &&
            result[key] !== ''
        )
        .reduce((obj, key) => {
          const formattedKey = this.formatKey(key);
          obj[formattedKey] = result[key];
          return obj;
        }, {} as any);
    });
  }

  private formatKey(key: string): string {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase())
      .replace('Question Text', 'Question Text:');
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  getRouteUrl(table): string {
    return tableMappings[table].url;
  }

  getDisplayName(table): string {
    return tableMappings[table].displayName;
  }
}
