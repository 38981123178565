import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  homevisible: boolean;
  salesvisible: boolean;
  

  constructor() { this.homevisible = true; }

  homehide() { this.homevisible = false; }
  homeshow() { this.homevisible = true; }

  saleshide(){this.salesvisible = false;}
  salesshow(){this.salesvisible = true;}

  toggle() { this.homevisible = !this.homevisible; }
}
