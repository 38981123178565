export const messages = {
    LOGIN: {
        "1001": {
            "en": "You've entered an incorrect Email ID/Password. Please try again!"
        }
    },
    LOGOUT: {
        "1002": {
            "en": "Your session has been already logged out!"
        },
        "1003": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    FORGOT_PWD: {
        "0000": {
            "en": "We've sent you an email with reset password link!"
        },
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Always send success message due to security concerns
            "en": "We've sent you an email with reset password link!"
        }
    },
    RESET_PWD: {
        "0000": {
            "en": "Your password has been reset successfully!"
        },
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    CHANGE_PWD: {
        "0000": {
            "en": "Your password has been changed successfully!"
        },
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    LIST_ORGS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    PIN_FINDER: {
        "1004": {
            "en": "This is the restricted PIN Code, Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    MANAGE_ADMINS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    ADMIN_ROLES: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    USER_CATEGORY: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    ORG_USERS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    SESSION_EXPIRED: {
        "9998": {
            "en": "Your session has expired. You will be redirected to the login page."
        },
        "9999": {
            // Ask what message to show
            "en": "Your session has expired. You will be redirected to the login page."
        }
    },
    INVALID_REQUEST: {
        "1007": {
            "en": "Invalid request. If you made the request by mistake, logout and login again or visit the landing page after login."
        }
    },
    UNAUTHORIZED_REQUEST: {
        "1007": {
            "en": "Invalid request. Either the session has expired or invalidated. Please login again to complete the request successfully."
        }
    },
    GET_ALL_COURSE_FRAMEWORKS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    CREATE_UPDATE_COURSE_GROUP: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    CREATE_UPDATE_COURSE_SUB_GROUP: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    CREATE_UPDATE_COURSE: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9998": {
            "en": "already exists with the provided code and/or name."
        }
    },
    CREATE_UPDATE_COURSE_VERSION: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9998": {
            "en": "already exists with the provided version."
        }
    },
    CREATE_UPDATE_COURSE_MODULE: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9998": {
            "en": "already exists with the provided code and/or name."
        }
    },
    CREATE_UPDATE_COURSE_TOPIC: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9998": {
            "en": "already exists with the provided code and/or name."
        }
    },
    DELETE_COURSE_GROUP: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    DELETE_COURSE_SUB_GROUP: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    UPDATE_COURSE_VERSION_STATUS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    UPDATE_COURSE_MODULE_STATUS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    UPDATE_COURSE_TOPIC_STATUS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    DELETE_COURSE_BY_ID: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    UPDATE_COURSE_STATUS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    CREATE_UPDATE_QUESTION_BANK: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    BULK_UPLOAD: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            // Ask what message to show
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    UPLOAD: {
        "header-mismatch": {
            "en": "The following headers are missing in the uploaded file: $1. Please try again with the correct upload format."
        },
        "required": {
            "en": "Line $1 :: Missing data in the column $2 which is a required field. Please try again with all the required data."
        },
        "datatype-mismatch": {
            "en": "Line $1 :: Incorrect data. Value $2 should be of type $3 in column $4. Please try again with the correct data."
        },
        "pattern-mismatch": {
            "en": "Line $1 :: Incorrect data. Value $2 should be of type $3 in column $4. Please try again with the correct data."
        },
        "empty-records": {
            "en": "There are no records in the file. Please try again with valid data."
        },
        "file-size-error": {
            "en": "The file size cannot be more than 2MB. Please try again with valid file."
        },
        "header-repeat": {
            "en": "Some of the fields in the uploaded file are not unique. Please make sure there is no repetitive fields."
        }
    },
    IN_APP_NOTIFICATIONS: {
        "1004": {
            "en": "Something went wrong at the moment. Please try again later."
        },
        "9997": {
            "en": "Something went wrong at the moment. Please try again later."
        }
    },
    NOTIFICATION_SETTING_UPDATE: {
        "1004": {
            "en": "Something went wrong at the moment. Please try again later."
        },
        "9997": {
            "en": "Something went wrong at the moment. Please try again later."
        }
    },
    DELETE_EVALUATION_CRITERIA: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    DELETE_PROJECT: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    UPDATE_QUESTION_PAPER_EVALUATION_CRITERIA_STATUS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    UPDATE_PROJECT_STATUS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    UPDATE_RATE_MASTER: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    ADD_OR_EDIT_EVALUATION_CRITERIA: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "VALUE_VALIDATION": {
            "en": "Please provide all the added criteria values to proceed"
        },
        "GRADING_CRITERIA_VALIDATION": {
            "en": "The grading criteria for grade:$grade already exists!"
        },
        "EVALUATION_PARAM_VALIDATION": {
            "en": "Type $type already exists on evaluation parameter:$param"
        },
    },
    ADD_OR_EDIT_RATE_MASTER: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    ADD_OR_EDIT_PROJECT: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
    },
    ADD_OR_EDIT_INVOICES: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    IMAGE_UPLOAD: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    ADD_OR_EDIT_RECEIPTS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    ADD_BILLING_ADDRESS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    ADD_OR_EDIT_BILLS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    ADD_OR_EDIT_PAYMENT: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    DELETE_PAYMENT: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    UPDATE_QUESTION_STATUS: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    CREATE_CANDIDATE: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    CREATE_ASSESSMENT: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
    DELETE_ASSESSMENT: {
        "1004": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        },
        "9997": {
            "en": "We're sorry for any inconvenience. Please contact your administrator"
        }
    },
}
