import { Injectable } from '@angular/core';
import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';
import { Router } from "@angular/router";
import { UPDATE } from '../constants/application-constants';

@Injectable({
  providedIn: 'root'
})
export class UpdateStatusService {
    serviceError: string;
  
  constructor(
    private api: ApiService,
    private util: UtilService,
    private route: Router
  ) {}

  get jwt(): string | null {
    return localStorage.getItem('JwtToken');
  }
  get orgID(): string | null{
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  updateStatus(event: any, id: any = 0, generic: boolean = true, module: string = '') {
    let payload: any = {
        status: event.checked,
        orgID: parseInt(this.orgID,10)
      };;
    const path = module? module : window.location.pathname;
    var postURL = null;
    var successIdentifier = null;
    var errorIdentifier = null;
    switch (path) {
        case '/Setup/IndustryVerticals':
            postURL = URL.ADMIN.UPDATE_INDUSTRY_VERTICAL_STATUS;
            successIdentifier = 'UPDATE_INDUSTRY_VERTICAL_STATUS';
            errorIdentifier="ADMIN_ROLES";
            payload = {
              ...payload,
              impactedRowId: parseInt(id, 10),
            }
            break;
        case '/Setup/BusinessVerticals':
            postURL = URL.ADMIN.UPDATE_BUSINESS_VERTICAL_STATUS;
            successIdentifier = 'UPDATE_BUSINESS_VERTICAL_STATUS';
            errorIdentifier="ADMIN_ROLES";
            payload = {
              ...payload,
              impactedRowId: parseInt(id, 10),
            }
            break;
        case '/Setup/Products':
            postURL = URL.ADMIN.UPDATE_PRODUCT_STATUS;
            successIdentifier = 'UPDATE_PRODUCT_STATUS';
            errorIdentifier="ADMIN_ROLES";
            payload = {
              ...payload,
              impactedRowId: parseInt(id, 10),
            }
            break;
        case '/Setup/CourseGroups':
            postURL = URL.CONTENT.UPDATE_COURSE_GROUP_STATUS;
            successIdentifier = 'UPDATE_COURSE_GROUP_STATUS';
            errorIdentifier="CREATE_UPDATE_COURSE_GROUPS";
            payload = {
              ...payload,
              impactedRowId: parseInt(id, 10),
            }
            break;
        case '/Setup/CourseSubGroups':
            postURL = URL.CONTENT.UPDATE_COURSE_SUB_GROUP_STATUS;
            successIdentifier = 'UPDATE_COURSE_SUB_GROUP_STATUS';
            errorIdentifier="CREATE_UPDATE_COURSE_SUB_GROUPS";
            payload = {
              ...payload,
              impactedRowId: parseInt(id, 10),
            }
            break; 
        case '/Resources/UserCategory':
            postURL = URL.ADMIN.UPDATE_USER_CATEGORY_STATUS;
            successIdentifier = 'UPDATE_USER_CATEGORY_STATUS';
            errorIdentifier="USER_CATEGORY";
            payload = {
              ...payload,
              userCategoryId: parseInt(id, 10),
            }
            break;
        case '/Resources/Users':
            postURL = URL.ADMIN.UPDATE_USER_STATUS;
            successIdentifier = 'UPDATE_USER_CATEGORY_STATUS';
            errorIdentifier="USER_CATEGORY";
            payload = {
              ...payload,
              userId: parseInt(event.row.user_id, 10),
            }
            break;
        case '/admin/org-list':
            postURL = URL.ADMIN.UPDATE_ORG_STATUS;
            successIdentifier = 'UPDATE_ORG_STATUS';
            errorIdentifier="LIST_ORGS";
            payload = {
              ...payload,
              orgID: id,
            }
            break;
        case 'updateOrgAdmin':
            postURL = URL.ADMIN.UPDATE_ORG_ADMIN_STATUS;
            successIdentifier = 'UPDATE_ORG_STATUS';
            errorIdentifier="LIST_ORGS";
            payload = {
              is_org_admin: event.checked,
              adminID: id
            }
            break;
        case 'adminStatus':
            postURL = URL.ADMIN.UPDATE_ADMIN_STATUS;
            payload = {
              status: event.checked,
              adminID: id
            }
            break;
        case 'questionPaperEvaluation':
          postURL = URL.CONTENT.UPDATE_QUESTION_PAPER_EVALUATION_STATUS;
          payload = {
            status: event.checked,
            questionPaperEvaluationId: id
          }
          break;
        case '/Resources/Roles':
            postURL = URL.ADMIN.UPDATE_ADMIN_ROLE_STATUS;
            successIdentifier = 'UPDATE_USER_ROLE_STATUS';
            errorIdentifier="ADMIN_ROLES";
             payload = {
              status: event.checked,
              roleId: id,
        };
            break;
        default:
            throw "migration to new router not happened yet";
    }


    if (generic) {
        this.updateStatusHelper(payload, postURL).subscribe((data: any)=>{
          const responseHead = data.body.responseHead;
          const successMsg = responseHead ? this.util.getSuccessMessage(successIdentifier, responseHead.statusCode, responseHead.statusDesc) : responseHead;
          this.util.simpleDialog({
            title: 'Updated',
            message: successMsg, 
          });
          this.route.navigate([path]);
          this.util.showLoader = false;
        }, (err)=>{
          this.util.showLoader = false;
          this.serviceError = err.responseHead 
            ? this.util.getIntlErrorMessage(errorIdentifier, err.responseHead.statusCode, err.responseHead.statusDesc)
            : err;
            this.util.simpleDialog({
              title: 'Error',
              message: this.serviceError,
            });
            this.route.navigate([path]);
        });
    } else {
      return this.updateStatusHelper(payload, postURL)
    }
  }

  updateStatusHelper(data: any, postURL: any) {
    const headers = {
      "x-operation" : UPDATE
    }
    const postData: APIRequest = {
       
      requestBody: {
        ...data
      }
    };
    
    return this.api.post(postURL, postData, headers)
  }
}