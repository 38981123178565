import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@app-shared/services/config.service';
import { APIResponse } from '@app-shared/services/req-res.types';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HEADER_X_REFFERER } from '../constants/application-constants';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient, private config: ConfigService) {
    this.headers = this.headers.set('Api-Key', this.config.developerAPIKey);
  }

  get xRefferer(): string {
    return localStorage.getItem(HEADER_X_REFFERER);
  }

  /**
   * get() - GET request
   * @param path string
   * @param queryParams optional
   * @param reqHeaders optional
   * @returns Observable
   */
  get(path: string, queryParams?: object, reqHeaders?: object) {
    let requestHeaders = {}
    if (reqHeaders && reqHeaders.hasOwnProperty("x-referer")) {
        requestHeaders = {
          ...reqHeaders
        }
    } else {
      requestHeaders = {
        ...reqHeaders,
        "x-referer" : this.xRefferer
      }
    }
    const options = this.constructOptions(queryParams, requestHeaders);
    return this.http
      .get<APIResponse>(this.config.apiBaseURL + path, options)
      .pipe(catchError(this.handleError));
  }

  /**
   * post() - POST request
   * @param path string
   * @param params object
   * @param reqHeaders optional
   * @returns Observable
   */
  post(path: string, params: object, reqHeaders?: object) {
    let requestHeaders = {}
    if (reqHeaders && reqHeaders.hasOwnProperty("x-referer")) {
        requestHeaders = {
          ...reqHeaders
        }
    } else {
      requestHeaders = {
        ...reqHeaders,
        "x-referer" : this.xRefferer
      }
    }
    const options = this.constructOptions(undefined, requestHeaders);
    //@ts-ignore
    const baseurl = params?.candidate ? this.config.candidateApiBaseURL : this.config.apiBaseURL;
    return this.http
      .post<APIResponse>(baseurl + path, params, options)
      .pipe(catchError(this.handleError));
  }

  /**
  * put() - PUT request
  * @param path string
  * @param params object
  * @param reqHeaders optional
  * @returns Observable
  */
  put(path: string, params?: object, reqHeaders?: object) {
    let requestHeaders = {}
    if (reqHeaders && reqHeaders.hasOwnProperty("x-referer")) {
        requestHeaders = {
          ...reqHeaders
        }
    } else {
      requestHeaders = {
        ...reqHeaders,
        "x-referer" : this.xRefferer
      }
    }
    const options = this.constructOptions(undefined, requestHeaders);
    return this.http
      .put<APIResponse>(this.config.apiBaseURL + path, params, options)
      .pipe(catchError(this.handleError));
  }

  delete(path:string, id:string, reqHeaders?:object) {
    let requestHeaders = {}
    if (reqHeaders && reqHeaders.hasOwnProperty("x-referer")) {
        requestHeaders = {
          ...reqHeaders
        }
    } else {
      requestHeaders = {
        ...reqHeaders,
        "x-referer" : this.xRefferer
      }
    }
    const options = this.constructOptions(undefined, requestHeaders);
    return this.http
      .delete<APIResponse>(`${this.config.apiBaseURL}${path}/${id}`, options)
      .pipe(catchError(this.handleError));
  }
  /**
   * getExternal() - GET request
   * @param path string
   * @param queryParams optional
   * @param reqHeaders optional
   * @returns Observable
   */
  getExternal(path: string, queryParams?: object, reqHeaders?: object) {
    return this.http.get(path, {}).pipe(catchError(this.handleError));
  }

  /**
   * handleError
   * @param error HttpErrorResponse
   * @returns error handle / error message
   */
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(
        'Service backend might not be reachable, please contact administrator'
      );
    }
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    return error?.error ? throwError(error.error): throwError(error);
  }

  private constructOptions(queryParams?: object, reqHeaders?: object): Object {
    let params = new HttpParams();
    // Set Query Params
    if (queryParams && Object.keys(queryParams).length > 0) {
      Object.keys(queryParams).forEach((key) => {
        params = params.set(key, queryParams[key]);
      });
    }

    // Set Custom HeadFers
    if (reqHeaders && Object.keys(reqHeaders).length > 0) {
      Object.keys(reqHeaders).forEach((headerKey) => {
        this.headers = this.headers.set(headerKey, reqHeaders[headerKey]);
      });
    }

    // http options
    return {
      headers: this.headers,
      observe: 'response' as const,
      responseType: 'json' as const,
      params: params,
    };
  }
}
