import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import { APIRequest } from '@app/shared/services/req-res.types';
import { URL } from '@app-shared/constants/service-urls';
import { UtilService } from '@app/shared/services/util.service';
import { Observable, throwError } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { GLOBAL, PUSH_TOPIC, WEB } from '../constants/application-constants';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(private api: ApiService, private util: UtilService, private fireMessaging: AngularFireMessaging) {}

  get orgId(): string | null {
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  get username(): string | null {
    return localStorage.getItem('username');
  }

  get useremail(): string | null {
    return localStorage.getItem('useremail');
  }

  get phonenumber(): string | null {
    const value = localStorage.getItem('custom_attributes');

    if (value) {
      const attributesArray = JSON.parse(value)
      const attribute = attributesArray.find((item: any) => item.type === 'Phone Number')

      if (attribute && attribute?.value && attribute?.value !== "null") return attribute.value
      return null
    }
    return null;
  }

  requestToken() {
    this.fireMessaging.tokenChanges
    this.fireMessaging.getToken.subscribe({
        next: token => {
            const postData: APIRequest = {
                requestBody: {
                  topicName: PUSH_TOPIC,
                  deviceToken: token,
                  deviceType: WEB
                },
              };

            const headers = {
              "x-referer": GLOBAL
            }
          
              this.api.post(URL.NOTIFICATIONS.SUBSCRIBE_TO_PUSH, postData, headers).subscribe(
                (payload) => {
                  console.log('Message received 1:', payload);
                },
                (error) => {
                  console.error('Error receiving message:', error);
                }
              );;
        },
        error: err => {
           console.log(err);
        }
    })
  }

  receiveMessage() {
    this.fireMessaging.messages.subscribe(
      (message) => {
        console.log('Message received:', message);
        // Handle the received message
        if (message.notification) {
          //@ts-ignore
          this.showNotification(message.notification.title, message.notification.body, message.notification.icon);
        }
      },
      (error) => {
        console.error('Error receiving message:', error);
      }
    );
  }

  showNotification(title: string, body: string, icon: string) {
    if (Notification.permission === 'granted') {
      new Notification(title, {
        body,
        icon: icon,
      });
    }
  }

  /**
   * Send Notification
   */
  sendNotification(data: any) {
    if (data.serviceType == 'EMAIL' && !this.useremail) {
      return throwError('Failed to send notification. Email Id not provided.')
    } 

    if (data.serviceType == 'SMS' && !this.phonenumber) {
      return throwError('Failed to send notification. Phone number not provided.')
    }

    const postData: APIRequest = {
      requestBody: {
        orgId: this.orgId,
        eventType: data.eventType,
        serviceType: data.serviceType,
        templateFields: {
          username: this.username,
          ...data?.templateFields,
        },
        destination: {
          to: data.serviceType == 'SMS' ? [this.phonenumber] : [this.useremail],
        },
      },
    };

    return this.api.post(URL.NOTIFICATIONS.SEND_NOTIFICATION, postData);
  }
}