import {Pipe, PipeTransform} from '@angular/core';
import { result } from 'lodash';

@Pipe({
  name: 'dataPropertyGetter'
})
export class DataPropertyGetterPipe implements PipeTransform {

  transform(object: any, keyName: string, ...args: unknown[]): unknown {

    // Get the value associated with the keyName
    let value = result(object, keyName);

    if (value && typeof value === 'string') {
      // Remove HTML tags from the value
      const cleanedValue = value.replace(/(<([^>]+)>)/ig, "");
      
      // Update the object with the cleaned value
      const keyParts = keyName.split('.');
      let target = object;
      
      // Traverse the object to reach the desired key
      for (let i = 0; i < keyParts.length - 1; i++) {
        target = target[keyParts[i]];
      }

      // Set the cleaned value back to the object
      target[keyParts[keyParts.length - 1]] = cleanedValue;
    }

    // Return the updated result
    return result(object, keyName);
  }
}