import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';


 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-fileviewer',
  templateUrl: './fileviewer.component.html',
  styleUrls: ['./fileviewer.component.css']
})
export class FileviewerComponent implements OnInit {
  imageBoolean = false;
  docBoolean=false;
  pdfBoolean=false;
  audioBoolean=false;
  imageUrl:any;
  pdfUrl:any;
  docUrl:any;
  audioUrl:any;
  videoURL:any;

  constructor() {
   }

  ngOnInit(): void {

    let url = window.location.href;
    let filename = url.split('/').pop();    
  }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }
  
  isImage(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png':
        this.imageBoolean = true
        return true;
    }
    this.imageBoolean = false
    return false;
  }

  isDoc(filename){
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'xls':
      case 'xlsx':
      case 'doc':
      case 'docx':
        this.docBoolean = true
        return true;
    }
    this.docBoolean = false
    return false;
  }

  isPdf(filename){
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'pdf':
        this.pdfBoolean = true
        return true;
    }
    this.pdfBoolean = false
    return false;
  }

  isAudio(filename){
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'mp3':
      case 'ogg':
      case 'mp4':
      case 'aac':
      case 'wma':

        this.audioBoolean = true
        return true;
    }
    this.audioBoolean = false
    return false;
  }



}
