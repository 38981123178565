import { Injectable } from '@angular/core';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class AddOrEditService {
  
  constructor(
    private api: ApiService,
    private util: UtilService
  ) {}

  get jwt(): string | null {
    return localStorage.getItem('JwtToken');
  }
  get orgID(): string | null{
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  addOrEdit(module: any, data: any) {
    let requestBody = {
        ...data,
    }
    let postData: APIRequest = {}
    var postURL = '/addoredit/' + module;
    //console.log(module);
    switch (module) {
        case 'businessVertical':
            postData = {
                requestBody: {
                    ...requestBody,
                    organization_id: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'industryVertical':
            postData = {
                requestBody: {
                    ...requestBody,
                    organization_id: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'product':
            postData = {
                requestBody: {
                    ...requestBody,
                    organization_id: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'adminRole':
            postData = {
                requestBody: {
                    ...requestBody,
                    organization_id: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'courseGroup':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'courseSubGroup':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'userCategoryAttributes':
            postData = {
                requestBody: {
                    ...requestBody,
                }
            }
            break;
        case 'userAttributesValues':
            postData = {
                requestBody: {
                    ...requestBody,
                }
            }
            break;
        case 'Mou':
        case 'userCategory':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgID: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'users':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'updateSalesLeadStatus':
            postData = {
                requestBody: {
                    ...requestBody,
                }
            }
            break;
        case 'courseModule':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'course':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'courseVersion':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;  
        case 'customerGroups':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break; 
        case 'opportunityMou':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'question':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10)
                }
            }
            break;
        case 'salesCustomer':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'salesOpportunity':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'questionPaperEvaluation':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'tag':
            postData = {
                requestBody: {
                    ...requestBody,
                    orgId: parseInt(this.orgID, 10),
                }
            }
            break;
        case 'awsBucketDetails':
        default:
            break;  
    }
    return this.api.post(postURL, postData);
    }

    addOrEditMou(data){
   
        let requestBody = {
            ...data,
        }
         let postData = {
            requestBody: {
                ...requestBody,
            }
        }
        var postURL = '/addorEditMou';
        return this.api.post(postURL, postData);
    }

    addOrEditUpdateSalesLeadStatus(data){
        let requestBody = {
            ...data,
        }
         let postData = {
            requestBody: {
                ...requestBody,
            }
    }
    var postURL = '/updateStatus/salesLeadStatus';
    return this.api.post(postURL, postData);
}
}