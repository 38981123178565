import { Injectable } from '@angular/core';

import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app-shared/services/api.service';
import { UtilService } from '@app-shared/services/util.service';
import { VIEW } from '@app/shared/constants/application-constants';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private api: ApiService) {}

  activityTracerData(
    start_date: string,
    end_date: string,
    page: number,
    my_activity: boolean
  ) {
    const headers = {
      'x-operation': VIEW,
    };

    // TODO: Change the limit

    return this.api.get(
      `${URL.DASHBOARD.ACTIVITY_TRACKER}?start_date=${start_date}&end_date=${end_date}&page=${page}&limit=-1&my_activity=${my_activity}`,
      undefined,
      headers
    );
  }

  getComparisionsData(
    comparision_option: string,
    current_start_date: string,
    current_end_date: string,
    previous_start_date: string,
    previous_end_date: string,
    org_id: number
  ) {
    const headers = {
      'x-operation': VIEW,
    };

    let isSuperOrgAdmin = localStorage.getItem('isOrgSuperAdmin');

    console.log({ isSuperOrgAdmin, org_id });

    if (isSuperOrgAdmin) {
      return this.api.get(
        `${URL.DASHBOARD.COMPARISIONS}/${comparision_option}?current_start_date=${current_start_date}&current_end_date=${current_end_date}&previous_start_date=${previous_start_date}&previous_end_date=${previous_end_date}&org_id=${org_id == -1 ? 0: org_id}`,
        undefined,
        headers
      );
    } else {
      return this.api.get(
        `${URL.DASHBOARD.COMPARISIONS}/${comparision_option}?current_start_date=${current_start_date}&current_end_date=${current_end_date}&previous_start_date=${previous_start_date}&previous_end_date=${previous_end_date}`,
        undefined,
        headers
      );
    }
  }
}
