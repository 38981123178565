import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'organisation-overview',
  templateUrl: './organisation-overview.component.html',
  styleUrls: ['./organisation-overview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationOverviewComponent implements OnInit, AfterViewInit {
  @Input() option: any;
  @Input() overviewData: any;
  @Input() dateFilter: any;

  @Input() barchartOptions: any;
  chartOptions: any = [];
  dataSource: any = [];
  categories: any;
  seriesData: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.chartOptions = this.barchartOptions;

    if (changes['overviewData']) {
      this.dataSource = changes['overviewData'].currentValue;
      const industryVerticals = Object.values(this.dataSource);

      this.categories = industryVerticals.map((item: any) => item.name);

      const thisWeekData = industryVerticals.map(
        (item: any) => item.current_period
      );
      const lastWeekData = industryVerticals.map(
        (item: any) => item.previous_period
      );

      const currentPeriodName =
        this.dateFilter === 'day'
          ? 'This day'
          : this.dateFilter === '7 days'
          ? 'This week'
          : this.dateFilter === '2 weeks'
          ? 'This fortnight'
          : this.dateFilter === 'month'
          ? 'This month'
          : 'This period';

      const previousPeriodName =
        this.dateFilter === 'day'
          ? 'Last day'
          : this.dateFilter === '7 days'
          ? 'Last week'
          : this.dateFilter === '2 weeks'
          ? 'Last fortnight'
          : this.dateFilter === 'month'
          ? 'Last month'
          : 'Last period';

      this.seriesData = [
        { name: currentPeriodName, data: thisWeekData, color: '#27CA5F' },
        {
          name: previousPeriodName,
          data: lastWeekData,
          color: '#BD3630',
        },
      ];
      this.cdr.detectChanges();
    }
  }
}
