import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { get } from 'lodash';
import { filter, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { ChatService } from './chat-service';
import { PushNotificationService } from './shared/services/pushNotification.service';
import { AuthService } from './auth/auth.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AuthService]
})
export class AppComponent implements OnInit{
  title = 'Certiplate';
  showHeader = true;
  showFooter = true;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  token = null;
  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private chatService: ChatService,
    private fcmService: PushNotificationService,
    private authService: AuthService
  ) {
    this.token = localStorage.getItem('JwtToken');

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.showHeader = get(event,'showHeader', true); // Decides whether to show Header or not
        this.showFooter = get(event,'showFooter', true); // Decides whether to show Footer or not
      });

    idle.setIdle(5);
    idle.setTimeout(5);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => (this.idleState = 'No longer idle.'));
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
    });
    idle.onIdleStart.subscribe(() => (this.idleState = "You've gone idle!"));
    idle.onTimeoutWarning.subscribe(
      (countdown) =>
        (this.idleState = 'You will time out in ' + countdown + ' seconds!')
    );
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  ngOnInit() {
    if (this.token) {
      this.chatService.loadChatScript();
      this.fcmService.requestToken();
      this.fcmService.receiveMessage();
    }
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
