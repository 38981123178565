<button class="mat-close-btn" title="Close" mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
</button>
<h2 mat-dialog-title>{{pageType}} Admin</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row">
        <div class="col-xl-12">
            <div class="card bg-secondary shadow">
                <div class="card-body">
                    <form [formGroup]="adminForm" (ngSubmit)="createAdmin()">
                        <div class="pl-lg-4">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group required">
                                        <label class="form-control-label">Name</label>
                                        <input type="text" formControlName="name" class="form-control form-control-alternative" placeholder="Enter Name">
                                        <mat-error *ngIf="adminForm.get('name').invalid && (adminForm.get('name').dirty || adminForm.get('name').touched)">Name is required</mat-error>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group required">
                                        <label class="form-control-label">Designation</label>
                                        <input type="text" formControlName="designation" class="form-control form-control-alternative" placeholder="Enter Designation">
                                        <mat-error *ngIf="adminForm.get('designation').invalid && (adminForm.get('designation').dirty || adminForm.get('designation').touched)">Designation is required</mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group required">
                                        <label class="form-control-label">Email</label>
                                        <input type="text" formControlName="email" class="form-control form-control-alternative" placeholder="Enter email">
                                        <mat-error *ngIf="adminForm.get('email').invalid && (adminForm.get('email').dirty || adminForm.get('email').touched)">
                                            <span *ngIf="adminForm.get('email').errors?.required">Email is required</span>
                                            <span *ngIf="adminForm.get('email').errors?.pattern">Email should be abc@example.com</span>
                                        </mat-error>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label">Alternate Email</label>
                                        <input type="text" formControlName="alternate_email" class="form-control form-control-alternative" placeholder="Enter email">
                                        <mat-error *ngIf="adminForm.get('alternate_email').invalid && (adminForm.get('alternate_email').dirty || adminForm.get('alternate_email').touched)">
                                            <span *ngIf="adminForm.get('alternate_email').errors?.pattern">Email should be abc@example.com</span>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group required">
                                    <label class="form-control-label">Phone</label>
                                    <input type="text" formControlName="phone" class="form-control form-control-alternative" placeholder="Enter Phone No">
                                    <mat-error *ngIf="adminForm.get('phone').invalid && (adminForm.get('phone').dirty || adminForm.get('phone').touched)">Phone is required</mat-error>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label class="form-control-label">Alternate Phone</label>
                                        <input type="text" formControlName="alternate_phone" class="form-control form-control-alternative" placeholder="Enter Phone">
                                    </div>
                                </div>
                            </div>
                            <div class="row pinCodeBlock" *ngIf="adminForm.contains('wantPassword')">
                                <div class="col-lg-12">
                                    <div class="form-group required">
                                        <label class="form-control-label">Do you want to update password :</label><br/>
                                        <mat-radio-group aria-label="Select an option" formControlName="wantPassword" (change)="updatePwdFields()">
                                            <mat-radio-button value="true">Yes</mat-radio-button>
                                            <mat-radio-button value="false">No</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="adminForm.contains('setPassword')">
                                <div class="col-lg-6">
                                    <div class="form-group required">
                                        <label class="form-control-label">Set Password :</label><br/>
                                        <mat-radio-group aria-label="Select an option" formControlName="setPassword" (change)="updatePassword()">
                                            <mat-radio-button value="false">Type Manually</mat-radio-button>
                                            <mat-radio-button value="true">Auto Generate</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group required">
                                        <label class="form-control-label">Password</label>
                                        <input type="password" [readonly]="adminForm.get('setPassword').value === 'true'" formControlName="password" class="form-control form-control-alternative" placeholder="Password">
                                        <mat-error *ngIf="adminForm.get('password').invalid && (adminForm.get('password').dirty || adminForm.get('password').touched)">
                                            <span *ngIf="adminForm.get('password').errors?.required">Password is required</span>
                                            <span *ngIf="adminForm.get('password').errors?.missingNumber">Password must have a atleast one digit</span>
                                            <span *ngIf="adminForm.get('password').errors?.missingUpperCase">Password must have a atleast one uppercase letter</span>
                                            <span *ngIf="adminForm.get('password').errors?.missingLowerCase">Password must have a atleast one lowercase letter</span>
                                            <span *ngIf="adminForm.get('password').errors?.missingMinLength">Password must have a atleast 8 character</span>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                            <div class="row pinCodeBlock">
                                <div class="col-lg-6">
                                    <div class="form-group required">
                                        <label class="form-control-label">Do you have PIN Code :</label><br/>
                                        <mat-radio-group aria-label="Select an option" formControlName="havePinCode" (change)="updatePinCodeValidation()">
                                            <mat-radio-button value="true">Yes</mat-radio-button>
                                            <mat-radio-button value="false">No</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class="col-lg-6" *ngIf="adminForm.get('havePinCode').value == 'true'">
                                    <div class="form-group required">
                                        <label class="form-control-label">PIN Code</label>
                                        <input type="text" formControlName="pinCode" maxlength="6" class="form-control form-control-alternative col-lg-10" placeholder="Pin Code" (keyup.enter)="loadGeography()"/>
                                        <button mat-raised-button matTooltip="Load geography" class="search-btn" (click)="loadGeography()" type="button">
                                            <mat-icon class="matIconURL">search</mat-icon>
                                        </button>
                                        <mat-error *ngIf="adminForm.get('pinCode').errors?.required && (adminForm.get('pinCode').dirty || adminForm.get('pinCode').touched)">Pin Code is required</mat-error>
                                        <mat-error *ngIf="adminForm.get('pinCode').errors?.minlength && (adminForm.get('pinCode').dirty || adminForm.get('pinCode').touched)">Pin Code should be 6 digit number</mat-error>
                                    </div> 
                                </div>
                            </div>
                            <div class="pinCodeBlock">
                                <div class="row">
                                    <div class="col-lg-6">
                                    <div class="form-group required">
                                        <label class="form-control-label">State</label>
                                        <input type="text" class="form-control form-control-alternative" *ngIf="adminForm.get('havePinCode').value == 'true'" readonly formControlName="state" (change)="stateDropdownList($event)" placeholder="State"/>
                                        <select class="form-control" type="text" formControlName="state" (change)="stateDropdownList($event)" placeholder="State" size="0" *ngIf="adminForm.get('havePinCode').value != 'true'">
                                        <option value="">-Select State-</option>
                                        <option *ngFor="let state of allStateNames" [value]="state">{{state}}</option>
                                        </select>
                                        <mat-error *ngIf="adminForm.get('state').invalid && (adminForm.get('state').dirty || adminForm.get('state').touched)">State is required</mat-error>
                                    </div>
                                    </div>
                                    <div class="col-lg-6">
                                    <div class="form-group required">
                                        <label class="form-control-label">District</label>
                                        <input type="text" class="form-control form-control-alternative" *ngIf="adminForm.get('havePinCode').value == 'true'" readonly formControlName="district" placeholder="District"/>
                                        <select class="form-control" type="text" id="ddlDistrictList" formControlName="district" placeholder="District" size="0" *ngIf="adminForm.get('havePinCode').value != 'true'">
                                            <option value="">-Select District-</option>
                                            <option *ngFor="let name of districts" [value]="name">{{name}}</option> 
                                        </select>
                                        <mat-error *ngIf="adminForm.get('district').invalid && (adminForm.get('district').dirty || adminForm.get('district').touched)">District is required</mat-error>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex" style="justify-content: flex-end;">
                                      <button type="button" style="margin-right: 1rem;" class="btn btn-success" id="modal-btn-cancel" mat-dialog-close>Cancel</button>
                                      <button type="submit" class="btn btn-success mat-raised-button" id="modal-btn-confirm" [disabled]="!adminForm.valid">Save</button>
                                    </div>
                                    <!-- <div class="form-group">
                                        <button type="submit" class="form-submit-btn mat-raised-button bounce-out-on-hover" [disabled]="!adminForm.valid">Save</button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </div>
    </div>
</mat-dialog-content>
