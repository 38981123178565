import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ApiService } from "../services/api.service";
import { UtilService } from "../services/util.service";
import { URL } from '@app-shared/constants/service-urls';
import { APIRequest } from "../services/req-res.types";
import { BULK_UPLOAD } from "../constants/application-constants";

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    constructor(
        private api: ApiService,
        private util: UtilService){}

    public upload(file:any): Observable<any>{
        const headers = {
            "x-operation" : BULK_UPLOAD
        }
        const postData: APIRequest = {       
            requestBody: {
              file : file
            }
        };
        return this.api.post(URL.CONTENT.CONTENT_FILE_UPLOAD, postData, headers);
    }

    public getFiles(): Observable<any> {
        return of(null);
    }
}