import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AppNotificationsService } from '../../services/app-notifications.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { KeyValue } from '@angular/common';
import * as moment from 'moment';
import { UtilService } from '../../services/util.service';
import { NOTIFICATIONS_LIST } from '../../constants/application-constants';
import { SocketService } from '@app/socket.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './app-notifications.component.html',
  styleUrls: ['./app-notifications.component.css'],
  exportAs: 'menuNotificationComponent',
  encapsulation: ViewEncapsulation.None,
})
export class AppNotificationsComponent implements OnInit {
  private readonly notificationService: AppNotificationsService;
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  distance = 2;
  throttle = 50;
  page = 1;
  size = 100;
  hoverActive: boolean = false;
  notifications: any;
  itemIndex = -1;
  itemKey = '';
  checked: boolean = false;
  notificationList = NOTIFICATIONS_LIST;
  notificationsError = false;
  allNotifications: any;
  @Input() count!: number;
  @Output() countChange = new EventEmitter<number>();
  previousPage = 0;
  isLoading = false;
  isMenuOpen: boolean = false;

  constructor(
    service: AppNotificationsService,
    private util: UtilService,
    private socketService: SocketService
  ) {
    this.notificationService = service;
    this.notificationService.menuEmitter.subscribe(this.toggleMenu.bind(this));
  }

  ngOnInit() {
    this.getAllNotifications(this.page, this.size);
  }

  onScroll(): void {
    if (this.isLoading || !this.isMenuOpen) return;
    this.getAllNotifications(++this.page, this.size);
  }
  handle_no_url(event: Event) {
    event.stopPropagation(); // Stops the click event from propagating to the parent div.
  }

  printInitial(username: string): string {
    const [first, last] = (username || '').split(' ');
    return `${first.charAt(0).toUpperCase()}${
      last ? last.charAt(0).toUpperCase() : ''
    }`;
  }

  getAllNotifications(page: number, size: number) {
    if (this.previousPage === page) {
      return;
    }
    this.isLoading = true;
    this.notificationService.getNotifications(page, size).subscribe(
      (data: any) => {
        const notifications = data.body.responseBody;
        this.allNotifications = this.allNotifications?.length
          ? [...this.allNotifications, ...notifications]
          : notifications;
        this.notifications = this.util.getFormattedNotifications(
          this.allNotifications
        );
        this.count = this.allNotifications?.[0]?.total_count || 0;
        this.countChange.emit(this.count);
        this.previousPage = page;
        this.isLoading = false;
      },
      (err: any) => {
        this.notificationsError = true;
      }
    );
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.getAllNotifications(this.page, this.size);
  }

  orderOriginal = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  getTime(date: any) {
    return moment(date).fromNow();
  }

  onInputChange() {
    this.checked = !this.checked;
    if (this.checked) {
      const list = this.allNotifications.filter((item: any) => !item.is_read);
      this.notifications = this.util.getFormattedNotifications(list);
    } else {
      this.notifications = this.util.getFormattedNotifications(
        this.allNotifications
      );
    }
  }

  markAsRead(event: any, id?: number) {
    event.stopPropagation();
    this.notificationService.markAsRead(id).subscribe(
      (data: any) => {
        this.getAllNotifications(this.page, this.size);
      },
      (err: any) => {
        const serviceError = err.responseHead
          ? this.util.getIntlErrorMessage(
              'IN_APP_NOTIFICATIONS',
              err.responseHead.statusCode,
              err.responseHead.statusDesc
            )
          : err;
        this.util.simpleDialog({
          title: 'Error',
          message: serviceError,
        });
      }
    );
  }
}
