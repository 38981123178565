import { Injectable } from '@angular/core';
import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';
import { GLOBAL, VIEW } from '@app/shared/constants/application-constants';
import { head } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private api: ApiService, private util: UtilService) {}

  get jwt(): string | null {
    return localStorage.getItem('JwtToken');
  }

  get userId(): number {
    return parseInt(localStorage.getItem('UserId'));
  }

  get userRoleId(): number {
    return parseInt(localStorage.getItem('UserRoleId'));
  }
  get orgID(): string | null {
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  /**
   * Account Login
   */
  login(data: any) {
    const headers = {
      "x-referer": GLOBAL,
      "x-operation": ''
    }
    const [latitude, longitude] = this.util.clientGeoLocation.split(',');
    const postData: APIRequest = {
       
      requestBody: {
        ...data,
        browser: this.util.clientBrowser,
        ipaddress: this.util.clientIPAddr,
        latitude,
        longitude,
      },
    };
    return this.api.post(URL.ACCOUNT.LOGIN, postData, headers);
  }

  /**
   * Account Logout
   */
  logout() {
    const headers = {
      "x-referer": GLOBAL,
      "x-operation": ''
    }
    const postData: APIRequest = {
       
      requestBody: {
        sessionId: this.jwt,
        userId: this.userId,
      },
    };

    return this.api.post(URL.ACCOUNT.LOGOUT, postData, headers);
  }

  /**
   * Forgot Password
   */
  forgotPassword(emailId: string) {
    const headers = {
      "x-referer": GLOBAL
    }
    const postData: APIRequest = {
       
      requestBody: {
        emailId: emailId,
      },
    };

    return this.api.post(URL.ACCOUNT.FORGOT_PWD, postData, headers);
  }

  /**
   * Reset Password
   */
  resetPassword(encId: string, password: string, confirmPassword: string) {
    const headers = {
      "x-referer": GLOBAL
    }
    const postData: APIRequest = {
       
      requestBody: {
        encId,
        password,
        confirmPassword,
      },
    };

    return this.api.post(URL.ACCOUNT.RESET_PWD, postData, headers);
  }

  /**
   * Change Password
   */
  changePassword(currentPassword: string, newPassword: string) {
    const headers = {
      "x-referer": GLOBAL
    }
    const postData: APIRequest = {
       
      requestBody: {
        currentPassword,
        newPassword,
      },
    };

    return this.api.post(URL.ACCOUNT.CHANGE_PWD, postData, headers);
  }

  /**
   * get Menus
   */
  getMenus() {
    const headers = {
      "x-referer": GLOBAL
    }
    return this.api.get(URL.ACCOUNT.MENU, undefined, headers);
  }

  getMenusNew() {
    const headers = {
      "x-referer": GLOBAL
    }
    return this.api.get(URL.ACCOUNT.NEW_MENU, undefined, headers)
  }

  getRolePermissions(roleId, type) {
    const headers = {
      "x-referer": GLOBAL
    }
    return this.api.get(`${URL.ACCOUNT.USER_ROLES}/${roleId}${URL.ACCOUNT.PERMISSIONS}?create=${type}`, undefined, headers)
  }

  /**
   * get User Org List
   */
  getUserOrgList(data: any) {
    const headers = {
      "x-referer": GLOBAL
    }
    const [latitude, longitude] = this.util.clientGeoLocation.split(',');
    const postData: APIRequest = {
       
      requestBody: {
        ...data,
        browser: this.util.clientBrowser,
        ipaddress: this.util.clientIPAddr,
        latitude,
        longitude,
      },
    };

    return this.api.post(URL.ACCOUNT.GET_USER_ORG_LIST, postData, headers);
  }

  /**
   * Account Login with Orgnization
   */
  loginWithOrg(data: any) {
    const headers = {
      "x-referer": GLOBAL
    }
    const [latitude, longitude] = this.util.clientGeoLocation.split(',');
    const postData: APIRequest = {
       
      requestBody: {
        ...data,
        browser: this.util.clientBrowser,
        ipaddress: this.util.clientIPAddr,
        latitude,
        longitude,
      },
    };

    return this.api.post(URL.ACCOUNT.LOGIN_WITH_ORG, postData, headers);
  }

  getUserOrgId() {
    return this.orgID;
  }
}
