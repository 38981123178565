import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  env: string;
  apiBaseURL: string;
  candidateApiBaseURL: string;
  candidatePortalURL: string;
  developerAPIKey: string = 'f48113fd-06ca-6413-3919-f22d7ef95cd8';
  protected apiUrls = {
    local: 'http://localhost:8080/api/v3',
    'app-dev': 'https://api-dev.certiplate.com/api/v3',
    'app2-dev': 'https://api2-dev.certiplate.com/api/v3',
    'app-qa': 'https://api-qa.certiplate.com/api/v3',
    'app-uat': 'https://api-uat.certiplate.com/api/v3',
    prod: 'https://api.certiplate.com/api/v3'
  }
  protected candidateUrls = {
    local: 'https://api-exams-dev.certiplate.com/api/v3',
    'app-dev': 'https://api-exams-dev.certiplate.com/api/v3',
    'app2-dev': 'https://api-exams-dev.certiplate.com/api/v3',
    'app-qa': 'https://api-exams-qa.certiplate.com/api/v3',
    'app-uat': 'https://api-exams-uat.certiplate.com/api/v3',
    prod: 'https://api.certiplate.com/api/v3'
  }
  protected candidatePortalUrls = {
    local: 'http://localhost:3000/',
    'app-dev': 'https://exams-dev.certiplate.com/',
    'app2-dev': 'https://exams-dev.certiplate.com/',
    'app-qa': 'https://exams-qa.certiplate.com/',
    'app-uat': 'https://exams-uat.certiplate.com/',
    prod: 'https://exams.certiplate.com/'
  }

  constructor() {
    this.init();
  }

  init(){
    // setup env
    if (window.location.hostname === 'localhost') {
      this.env = 'local';
    } else {
      const environment = window.location.host.replace('.certiplate.com', '');
      this.env = ['app-dev','app2-dev', 'app-qa', 'app-uat'].includes(environment) ? environment : 'prod';
    }
    this.apiBaseURL = this.apiUrls[this.env];
    this.candidateApiBaseURL = this.candidateUrls[this.env];
    this.candidatePortalURL = this.candidatePortalUrls[this.env];
  }
}

