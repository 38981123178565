import { Injectable } from '@angular/core';

import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';

@Injectable({
  providedIn: 'root'
})
export class AddTagService {
  
  constructor(
    private api: ApiService,
    private util: UtilService
  ) {}

  get jwt(): string | null {
    return localStorage.getItem('JwtToken');
  }
  get orgID(): string | null{
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  addOrEditTags(data: any) {
    const postData: APIRequest = {
       
      requestBody: {
        ...data,
        orgId: parseInt(this.orgID, 10),
      }
    };
    
    return this.api.post(URL.ADMIN.CREATE_UPDATE_TAG, postData );
  }
  searchTags(data: any) {
    const postData: APIRequest = {
       
      requestBody: {
        text: data,
        orgId: parseInt(this.orgID, 10),
      }
    };
    
    return this.api.post(URL.ADMIN.SEARCH_TAG, postData );
  }
}