import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'custom-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.css'],
})
export class SegmentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() segmentValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() segmentsArray: any;

  public segments: any;
  public selectedSegment: string;
  public startDate: any;
  public endDate: any;

  ngOnInit(): void {
    this.segments = this.segmentsArray;
    this.selectedSegment = this.segments[0];
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.segments = [];
  }

  onSegmentChange(segment: string) {
    if (segment === 'datePicker') {
      return;
    } else if (this.segments.includes('Custom') && segment === 'Custom') {
      this.selectedSegment = 'datePicker';
      this.segments[4] = 'datePicker';
    } else if (segment !== 'Custom') {
      this.endDate = null;
      this.startDate = null;
      this.segmentValue.emit({ segmentName: segment, segmentValue: segment });
      this.selectedSegment = segment;
      if (
        this.segments.includes('Custom') ||
        this.segments.includes('datePicker')
      ) {
        this.segments[4] = 'Custom';
      }
    }
  }

  isSelected(segment: string): boolean {
    return this.selectedSegment === segment;
  }

  onStartDateChange(date: Date): void {
    this.startDate = date;
    if (date && this.endDate) {
      this.segmentValue.emit({
        segmentName: this.selectedSegment,
        segmentValue: { startDate: date, endDate: this.endDate },
      });
    }
  }

  onEndDateChange(date: Date): void {
    this.endDate = date;
    if (date && this.startDate) {
      this.segmentValue.emit({
        segmentName: this.selectedSegment,
        segmentValue: { startDate: this.startDate, endDate: date },
      });
    }
  }
}
