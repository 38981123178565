import { Injectable } from '@angular/core';
import { URL } from '@app-shared/constants/service-urls';
import { GLOBAL, UPDATE, VIEW } from '@app/shared/constants/application-constants';
import { ApiService } from '@app/shared/services/api.service';
import { APIRequest } from '@app/shared/services/req-res.types';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private api: ApiService) { }

  getAllNotificationEvents() {
    const headers = {
      "x-operation" : VIEW,
      "x-referer": GLOBAL
    }
   return this.api.get(`${URL.NOTIFICATIONS.NOTIFICATIONS_EVENTS}`, undefined, headers);
  };

  getNotificationSettings() {
    const headers = {
      "x-operation" : VIEW,
      "x-referer": GLOBAL
    }
    return this.api.get(`${URL.NOTIFICATIONS.NOTIFICATION_SETTINGS}`, undefined, headers);
  }

  updateNotificationSettings(id: number, data: any) {
    const headers = {
      "x-operation" : UPDATE,
      "x-referer": GLOBAL
    }
    const postData: APIRequest = {
        requestBody: {
          ...data,
        },
      };
    return this.api.put(`${URL.NOTIFICATIONS.NOTIFICATION_SETTINGS}/${id}`, postData, headers)
  }

  saveNotificationSettings(data: any) {
    const headers = {
      "x-operation" : UPDATE,
      "x-referer": GLOBAL
    }
    const postData: APIRequest = {
        requestBody: {
          ...data,
        },
      };
    return this.api.post(`${URL.NOTIFICATIONS.NOTIFICATION_SETTINGS}`, postData, headers)
  }
 }
