<div class="container">
  <div class="row">
    <div class="col-lg-3">
      <div class="box-search">
        <div class="font-weight-bold">On this page</div>

        <div class="pr-3 mt-3">
          <div
            *ngFor="let item of tags | keyvalue : orderOriginal; let index = i"
            class="ml-2"
          >
            <div class="mb-3">{{ item.key }} ({{ item.value }})</div>
          </div>
        </div>
      </div>
      <div class="box-search mt-4" *ngIf="isSearchInput">
        <div class="font-weight-bold">Recent Searches</div>
        <div class="pr-3 mt-3">
          <div *ngFor="let search of recentSearches; let index = i">
            <div class="mb-3">
              <mat-icon class="mr-2">search</mat-icon
              >{{ search.searched | striphtml
              }}<span *ngIf="search.module" class="module"
                >in {{ search.table }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8 ml-4">
      <div class="input-group mb-3">
        <input
          type="search"
          class="form-control"
          placeholder="Search"
          [(ngModel)]="searchInput"
          [matAutocomplete]="auto"
          (input)="onInputChange()"
        />
        <div class="input-group-prepend">
          <i style="font-size: 16px; color: #404259" class="input-group-text fa"
            >&#xf002;</i
          >
        </div>
      </div>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of options" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <div class="no-results" *ngIf="isLoading && searchInput != ''">
        Searching...
      </div>

      <div class="box" *ngIf="suggestions?.length; else noResultsTemplate">
        <div *ngIf="suggestions?.length">
          <div
            class="d-flex justify-content-between heading pl-3 pr-3 font-weight-bold"
          >
            <div>SEARCH RESULTS</div>
            <div>All results ({{ allResults }})</div>
          </div>
          <div class="pl-3 pr-3 mt-3">
            <div *ngFor="let search of suggestions; let index = i">
              <div
                class="mb-3"
                (mouseover)="
                  hoverActive = true; itemIndex = i; itemKey = search.found
                "
                (mouseout)="hoverActive = false; itemIndex = -1; itemKey = ''"
                [ngStyle]="
                  hoverActive &&
                  i === itemIndex &&
                  itemKey == search.found && {
                    'background-color': 'rgb(221, 220, 231)',
                    cursor: 'pointer'
                  }
                "
              >
                <div (click)="onClickSearchSuggestion(search)">
                  <mat-icon class="mr-2">search</mat-icon
                  >{{ search.found | striphtml }}
                  <span *ngIf="search.found_in" class="module">
                    in {{ search.found_in.table }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noResultsTemplate>
        <div class="no-results" *ngIf="suggestions.length === 0 && searchInput">
          <p *ngIf="toggle && !isLoading">No results found</p>
        </div>
      </ng-template>

      <div class="d-flex justify-content-between" *ngIf="isSearchInput">
        <h2>
          You searched for: {{ searchInput || searchObject.found | striphtml }}
        </h2>
        <div class="all-results">All results ({{ length }})</div>
      </div>

      <div
        class="mt-3"
        *ngIf="isSearchInput && searchResults.search; else noSearchResults"
      >
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ searchResults.search | striphtml }}
              </mat-panel-title>
              <mat-panel-description *ngIf="searchResults.table">
                {{ searchResults.table }}
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div
              *ngIf="
                searchResults.results && searchResults.results.length > 0;
                else noAttributes
              "
            >
              <div
                *ngFor="let result of getFilteredResults(searchResults.results)"
                class="mb-3"
              >
                <div *ngFor="let key of objectKeys(result)">
                  <p>{{ key }}: {{ result[key] | striphtml }}</p>
                </div>
              </div>
            </div>

            <div *ngIf="searchResults.table">
              <a [routerLink]="getRouteUrl(searchResults.table)"
                >Go to {{ getDisplayName(searchResults.table) }}</a
              >
            </div>

            <ng-template #noAttributes>
              <p>No attributes found</p>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <ng-template #noSearchResults>
        <div class="no-results" *ngIf="!searchResults.search && isSearchInput">
          <p>No results found for {{ searchInput || searchObject?.found }}</p>
        </div>
      </ng-template>

      <div class="d-flex justify-content-between" *ngIf="!isSearchInput">
        <h2>Recent Searches</h2>
        <div class="all-results">
          All results ({{ recentSearches?.length }})
        </div>
      </div>

      <div class="mt-3" *ngIf="!isSearchInput">
        <div class="results-container">
          <div *ngFor="let search of showResults" class="result-item">
            <div class="result-text">
              <div>{{ search.searched | striphtml }} in {{ search.table }}</div>
            </div>
          </div>
        </div>
        <mat-paginator
          [length]="length"
          (page)="onChangePage($event)"
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 10, 25]"
          aria-label="Select page"
          class="mb-5"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
