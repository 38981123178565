<div [matMenuTriggerFor]="globalSearchMenu" #menuTrigger="matMenuTrigger"></div>
<mat-menu #globalSearchMenu="matMenu" xPosition="before" class="search-menu">
  <div class="search-menu-box">
    <input
      type="search"
      class="form-control"
      placeholder="Search"
      [(ngModel)]="searchInput"
      (input)="onSearchInputChange()"
      (click)="$event.stopPropagation()"
    />
    <div class="box">
        <div>
          <div>
            <div *ngIf="searchResults?.length; else noResultsTemplate">
              <div
                class="d-flex justify-content-between heading pl-3 pr-3 font-weight-bold"
              >
                <div>SEARCH RESULTS</div>
                <div>All results ({{ allResults }})</div>
              </div>
              <div class="pl-3 pr-3 mt-3">
                <div class="results" *ngIf="isLoading">Searching...</div>
                <div *ngFor="let search of searchResults; let index = i">
                  <div
                    class="mb-3"
                    (mouseover)="
                      hoverActive = true; itemIndex = i; itemKey = search.found
                    "
                    (mouseout)="hoverActive = false; itemIndex = -1; itemKey = ''"
                    [ngStyle]="
                      hoverActive &&
                      i === itemIndex &&
                      itemKey == search.found && {
                        'background-color': 'rgb(221, 220, 231)'
                      }
                    "
                  >
                    <a *ngIf="search.found" [routerLink]="['/search']" [state]="{ search: search }">
                      <mat-icon class="mr-2">search</mat-icon>{{ search.found | striphtml }}
                      <span *ngIf="search.found_in" class="module">
                        in {{ search.found_in.table }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #noResultsTemplate>
              <div class="results" *ngIf="searchInput !== '' && !isSearchInput && !isLoading">
                <p>No results found</p>
              </div>
            </ng-template>
          </div>
          <div class="heading pl-3 font-weight-bold">RECENT SEARCHES</div>
          <div class="pl-3 pr-3 mt-3" *ngIf="recentSearches.length; else noRecentSearchTemplate">
            <div *ngFor="let search of recentSearches; let index = i">
              <div
                class="mb-3"
                (mouseover)="
                  hoverActive = true; itemIndex = i; itemKey = search.searched
                "
                (mouseout)="hoverActive = false; itemIndex = -1; itemKey = ''"
                [ngStyle]="
                  hoverActive &&
                  i === itemIndex &&
                  itemKey == search.searched && {
                    'background-color': 'rgb(221, 220, 231)'
                  }
                "
              >
                <mat-icon class="mr-2">search</mat-icon>{{ search.searched | striphtml
                }}<span *ngIf="search.searched" class="module"
                  >in {{ search.table }}</span
                >
              </div>
            </div>
          </div>
          <ng-template #noRecentSearchTemplate>
            <div class="results" *ngIf="recentSearches.length === 0">
              <p>No recent searches found</p>
            </div>
          </ng-template>
        </div>
        <div>
          <div class="footer">
          <a [routerLink]="['/search']" [state]="{ suggestions: searchResults }"
            >See all results</a
          >
          <!-- <a href="#" [routerLink]="['/search', { key: this.searchInput }]"
            >See all results</a
          > -->
        </div>
      </div>
    </div>
  </div>
</mat-menu>
