import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-areachart',
  templateUrl: './areachart.component.html',
  styleUrls: ['./areachart.component.css']
})
export class AreachartComponent implements OnInit {
  chartOptions: {};
  @Input() data: any = [];
  @Input() chartoptions: any = [];

  Highcharts = Highcharts;

  constructor() { }

  ngOnInit(): void {
    this.chartOptions = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Annual Report'
        },
        xAxis: {
            categories: [
                'Construction',
                'Telecom',
                'Retail',
                'BFSI',
                'Electronics',
                'Apparel',
                'Power',
                'Domestic Worker'
            ],
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Total (candidates)'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: 'Allocated',
            data: [251, 676, 896, 15, 102, 169, 459, 608]
    
        }, {
            name: 'Billable',
            data: [258, 476, 496, 25, 528, 109, 405, 480]
    
        }, {
            name: 'Scheduled',
            data: [84,178,29,0,30,0,30,10]
    
        }, {
            name: 'Planned',
            data: [613,92,0,0,347,0,116,27]
    
        },
        {
            name: 'Assessed',
            data: [421,86,90,2,246,0,55,60]
    
        },
        {
            name: 'Invoiced',
            data: [251, 106, 108, 515, 210, 516, 334, 117]
    
        },
        {
            name: 'Cancelled',
            data: [30, 0, 16, 0, 0, 0, 30, 0]
    
        }]
    }
      

        HC_exporting(Highcharts);
        HC_exportData(Highcharts);

        setTimeout(() => {
          window.dispatchEvent(
            new Event('resize')
          );
        }, 300);
                  
    }
  }

