import { Injectable } from '@angular/core';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';
import { GLOBAL, VIEW } from '../constants/application-constants';

@Injectable({
  providedIn: 'root'
})
export class GetService {
  
  constructor(
    private api: ApiService,
    private util: UtilService
  ) {}

  get jwt(): string | null {
    return localStorage.getItem('JwtToken');
  }
  get orgID(): string | null{
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  get(module: any, data: any) {
    const headers = {
      "x-referer" : GLOBAL
    }
    var postData: APIRequest = {   
        requestBody: {
          ...data
        }
      }; 
    var postURL = '/get/' + module;
    switch (module) {
        case 'categoryAttributesById':
        case 'courseFrameworkLabels':
        case 'courseVersionDropdownList':
        case 'custAttrValuesById':
        case 'customerSpocsList':
        case 'difficultyLevel':
        case 'questionTypes':
            break;
        case 'geoByPincode':
            postData.requestBody["pinCode"] = data;
            break;
        case 'mouById':
            postData.requestBody["mouID"] = data;
            break;
        case 'filteredUsers':
        case 'salesCustomerCounts':
        case 'salesCustomerDetails'://
        case 'questionList':
        case 'courseDropdownList':
        case 'salesAccountManagers':          
        case 'salesCustomerGroupsCustomers':
        case 'salesCustomerGroups':
        case 'salesCustomerOpportunityList':
        case 'salesLeadDetails':
        case 'salesOpportunityDetails'://
        case 'salesOpportunityList':
        case 'salesOpportunityMouDetails':
        case 'salesOpportunityMouList':
        case 'salesScheme':
        case 'salesSpocList':
        case 'tagsByOrgId':
            postData.requestBody["orgId"] = parseInt(this.orgID, 10)
            break;
        //case 'urlMetaData':
        //    break;
        case 'salesOpportunityCounts':
          postData.requestBody["organization_id"] = parseInt(this.orgID, 10)
          break; 
        case 'questionPaperEvaluationList':
            postData.requestBody["orgId"] = parseInt(this.orgID, 10)
            break;
        case 'awsBucketDetails':
            postData.requestBody["organization_id"] = parseInt(this.orgID, 10)
            break;      
        default:
            break;  
    }
    return this.api.post(postURL, postData, headers);
    }
}