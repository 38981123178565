import {
  EVALUATION_TAG_TYPES,
  MENU_ACCESS,
} from '@app/shared/constants/application-constants';
import { UtilService } from '@app/shared/services/util.service';
import { TAG_TYPES } from '../addproject/addproject.constants';
import * as Sentry from '@sentry/angular';

export const PROJECT_TABLE_DATA = [
  {
    sno: 1,
    alias: 'title',
    short_name: 'Short Name',
    description:
      'a project/application which utilizes our skills and enables us to experiment on a virtual plan. In a real-time scenario, this setting is of a little help. There are no guidelines and routines followed.',
    tags: ['dinesh', 'dinesh babu', 'dinesh', 'dinesh babu'],
    status: 'Active',
  },
];

export const PROJECT_TABLE_ROW_ACTIONS = [
  {
    iconName: 'edit',
    iconTooltip: 'Modify Project',
    name: 'edit',
    additionalRowPermission: 'is_archived',
    conditionalOperator: 'not',
  },
  {
    iconName: 'history',
    iconTooltip: 'Show Revision History',
    name: 'history',
  },
  {
    iconName: 'archive',
    iconTooltip: 'Archive',
    name: 'archive',
    additionalRowPermission: 'is_archived',
    conditionalOperator: 'not',
  },
  {
    iconName: 'trash',
    iconTooltip: 'Delete Project',
    name: 'delete',
  },
];

export const PROJECT_TABLE_COLUMNS = [
  {
    name: 'S.No.',
    dataKey: 'sno',
    position: 'left',
    isSortable: true,
    type: '',
  },
  {
    name: 'Alias',
    dataKey: 'alias',
    type: 'text',
    isSortable: true,
  },
  {
    name: 'Short Name',
    dataKey: 'short_name',
    position: 'left',
    isSortable: true,
    type: 'text',
  },
  {
    name: 'Description',
    dataKey: 'description',
    position: 'left',
    isSortable: true,
    type: 'desc_text',
  },
  {
    name: 'Tags',
    dataKey: 'formattedTags',
    position: 'left',
    isSortable: true,
    type: 'tags',
  },
  {
    name: 'Status',
    dataKey: 'status',
    type: 'toogle',
    isFilterable: true,
    conditionalNaming: {
      "3": {
        "name" : "Archived",
        "disabled": true,
        "checked" : true
      },
      "2" : {
         "name" : "Active",
         "disabled" : false,
         "checked" : true
      } ,
      "1" : {
        "name" : "Inactive",
         "disabled" : false,
         "checked" : false
      }
    },
  },
  {
    name: 'Actions',
    type: 'threeDotActions',
    rowActions: PROJECT_TABLE_ROW_ACTIONS,
  },
];

export const PROJECT_TABLE_ACTIONS = [
  {
    iconName: 'plus',
    iconTooltip: 'Add Project',
    permission: 'Create',
    name: 'add',
  },

  {
    iconName: 'upload',
    iconTooltip: 'Bulk upload',
    permission: 'View',
    name: 'upload',
  },
  {
    iconName: 'download',
    permission: 'View',
    iconTooltip: 'Download sample file',
    name: 'inlineDownload',
  },
];

export const PROJECT_TABLE_WARNINGS = {
  archive: {
    title: 'This action will archive the selected entry and will lock the entry from any further modification. Are you sure, you want to proceed with this action?',
    buttonTitle: 'Archive',
  },
  delete: {
    title: 'This action will delete the selected entry and will remove the entry from the list screen. Are you sure, you want to proceed with this action?',
    buttonTitle: 'Delete',
  },
};

export const PROJECT_FILTER_ATTRIBUTES = [
  {
    dataKeyName: 'status',
    name: 'Status',
    type: 'list',
    data: ['Active', 'Inactive', 'Archived'],
  },
  {
    dataKeyName: 'tags',
    name: 'Tags',
    type: 'list',
    data: [],
  },
  {
    dataKeyName: 'all',
    name: 'All Text',
    type: 'all',
    data: []
  },
];

export const validateInput = (control) => {
  const value = control.value;
  if (/^[a-zA-Z0-9\s]*$/.test(value)) {
    return null;
  } else {
    return { invalidInput: true };
  }
};

export const setTagTypesBasedOnMenuAccess = (
  util: UtilService,
  from?: string
): string[] => {

  const isCreateGranted = (permissions) => Object.values(permissions).some((permission: any) => 
    permission.name === 'Create' && permission.is_granted
  );

  const tags = [];
  const menuAccess = JSON.parse(
    util.decrypt(localStorage.getItem(MENU_ACCESS))
  );
  if (from === 'operation_analysis') {
    return ['Customer', 'Scheme', 'Course', 'Project'];
  }
  const salesIndex = menuAccess.findIndex((menu) => menu.menu_name === 'Sales');
  if (salesIndex > -1) {
    if (menuAccess[salesIndex].pages.some((item) => item.menu_name === 'Customers') && isCreateGranted(JSON.parse(menuAccess[salesIndex].pages.find((item) => item.menu_name === 'Customers')?.features || "{}"))) {
      tags.push('Customer')
    }
    if (menuAccess[salesIndex].pages.some((item) => item.menu_name === 'Schemes') && isCreateGranted(JSON.parse(menuAccess[salesIndex].pages.find((item) => item.menu_name === 'Schemes')?.features || "{}"))) {
      tags.push('Scheme')
    }
  }
  const contentIndex = menuAccess.findIndex((menu) => menu.menu_name === 'Content');
  if (contentIndex > -1 && from !== 'evaluation_criteria') {
    if (menuAccess[contentIndex].pages.some((item) => item.menu_name === 'Courses') && isCreateGranted(JSON.parse(menuAccess[contentIndex].pages.find((item) => item.menu_name === 'Courses')?.features || "{}"))) {
      tags.push('Course')
    }
  }
  const operationsIndex = menuAccess.findIndex((menu) => menu.menu_name === 'Operations');
  if (operationsIndex > -1) {
    if (menuAccess[operationsIndex].pages.some((item) => item.menu_name === 'Project') && isCreateGranted(JSON.parse(menuAccess[operationsIndex].pages.find((item) => item.menu_name === 'Project')?.features || "{}"))) {
      tags.push('Project')
    }
  }
  tags.push('Custom');
  return tags;
};
  

export const getXReferer = (url: string) => {
  const split = url.split('/');
  if (split.length === 2 && split[1]) {
    return `/${split[1]}`;
  } else if (split.length >= 3 && split[1] && split[2]) {
    return `/${split[1]}/${split[2]}`;
  }
  return '/';
};

export const sortMenuItems = (menuItems) => {
  // Function to recursively sort pages
  const sortPages = (pages) => {
    pages.sort((a, b) => a.sort_order - b.sort_order);
    pages.forEach((page) => {
      if (page.pages && page.pages.length > 0) {
        sortPages(page.pages);
      }
    });
  };

  // Sort the main menu items
  menuItems.sort((a, b) => a.sort_order - b.sort_order);

  // Recursively sort each menu's pages
  menuItems.forEach((menuItem) => {
    if (menuItem.pages && menuItem.pages.length > 0) {
      sortPages(menuItem.pages);
    }
  });

  return menuItems;
};

export const convertObjectToArray = (inputObject) => {
  const resultArray = [];

  for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
          resultArray.push(inputObject[key]);
      }
  }

  return resultArray;
}

export const setSentryUser = async (id, userName, userEmail) => {
  const user = {
    id: id,
    name: userName,
    email: userEmail,
  };

  Sentry.setUser(user);
};

export const CONTENT_OPERATIONS_SEARCH = {
  courseGroup: {
    schema: 'content',
    table: 'course_group_master',
    column: 'name',
  },
  courseSubGroup: {
    schema: 'content',
    table: 'course_subgroup_master',
    column: 'name',
  },
  course: {
    schema: 'content',
    table: 'course_master',
    column: 'name',
  },
  courseVersion: {
    schema: 'content',
    table: 'course_versions',
    column: 'name',
  },
  geography: {
    schema: 'app_master_data',
    table: 'geography_pincodes',
    column: 'pin_code',
  },
  stakeholders: {
    schema: 'organization',
    table: 'users',
    column: 'full_name',
  },
};

export const convertDate = () => {
  var day;
  var month;
  var date = new Date();
  day = date.getDate();
  month = date.getMonth();
  month = month + 1;
  if (String(day).length == 1) day = '0' + day;
  if (String(month).length == 1) month = '0' + month;
  return date.getFullYear() + '' + month + '' + day;
};
