import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { CdkTableModule } from '@angular/cdk/table';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AppHttpInterceptor } from '@app/AppHttpInterceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import { AngularEditorModule } from '@kolkov/angular-editor';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { SharedModule } from '@app-shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { NgIdleModule } from '@ng-idle/core'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { DataTablesModule } from 'angular-datatables';
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { HighchartsChartModule } from 'highcharts-angular';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatTableExporterModule } from 'mat-table-exporter';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ImageViewerModule } from 'ngx-image-viewer';
import { TreeviewModule } from 'ngx-treeview';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddassessorComponent } from '@app/addassessor/addassessor.component';
import { AppComponent } from '@app/app.component';
import { AreachartComponent } from '@app/areachart/areachart.component';
import { AssessorComponent } from '@app/assessor/assessor.component';
import { CardComponent } from '@app/card/card.component';
import { DashboardComponent } from '@app/dashboard/dashboard.component';
import { FileviewerComponent } from '@app/fileviewer/fileviewer.component';
import { NavbarComponent } from '@app/navbar/navbar.component';
import { ReleasenoteComponent } from '@app/releasenote/releasenote.component';
import { ReportComponent } from '@app/report/report.component';
import { SidenavbarComponent } from '@app/sidenavbar/sidenavbar.component';
import { TicketComponent } from '@app/ticket/ticket.component';
import { TutorialsComponent } from '@app/tutorials/tutorials.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { PiechartComponent } from './piechart/piechart.component';
import { SearchComponent } from './search/search.component';
import { NotificationsettingsComponent } from './settings/notificationsettings/notificationsettings.component';
import { SettingsComponent } from './settings/settings.component';
import { ViewTicketComponent } from './view-ticket/viewTicket.component';

import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireMessagingModule,
  SERVICE_WORKER,
  VAPID_KEY,
} from '@angular/fire/compat/messaging';
import { environment } from '../environments/environment';
import { SalesContentOperationsOverviewComponent } from './sales-content-operations-overview/sales-content-operations-overview.component';
import { RecordProgressComponent } from './record-progress/record-progress.component';
import { FinanceAndResourcesOverviewComponent } from './finance-and-resources-overview/finance-and-resources-overview.component';
import { ActivityTrackerOverviewComponent } from './activity-tracker-overview/activity-tracker-overview.component';
import { OrganisationOverviewComponent } from './organisation-overview/organisation-overview.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    DashboardComponent,
    ReportComponent,
    SidenavbarComponent,
    AreachartComponent,
    CardComponent,
    PiechartComponent,
    AssessorComponent,
    AddassessorComponent,
    FileviewerComponent,
    ReleasenoteComponent,
    TicketComponent,
    TutorialsComponent,
    SettingsComponent,
    NotificationsettingsComponent,
    SearchComponent,
    ViewTicketComponent,
    SalesContentOperationsOverviewComponent,
    RecordProgressComponent,
    FinanceAndResourcesOverviewComponent,
    ActivityTrackerOverviewComponent,
    OrganisationOverviewComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    InfiniteScrollModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FlexLayoutModule,
    HighchartsChartModule,
    CdkTableModule,
    MatTableExporterModule,
    DataTablesModule,
    MatSelectFilterModule,
    EditorModule,
    //CKEditorModule,
    //AngularEditorModule,
    BrowserAnimationsModule,
    MomentModule,
    NgxDocViewerModule,
    NgxExtendedPdfViewerModule,
    NgIdleModule.forRoot(),
    ImageViewerModule.forRoot(),
    TreeviewModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgSelectModule,
    MatIconModule,
    MatButtonModule,
    MatStepperModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 30000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }), // ToastrModule added , Required Angular Animations
  ],
  exports: [MatSlideToggleModule],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    {
      provide: SERVICE_WORKER,
      useFactory: () =>
        (typeof navigator !== 'undefined' &&
          navigator.serviceWorker?.register('firebase-messaging-sw.js', {
            scope: '__',
          })) ||
        undefined,
    },
    { provide: VAPID_KEY, useValue: environment.vapidKey },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
