import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

declare var $ :any;

export interface TravelRequestElement {
  sno:number;
  title:string;
  type:string;
  url: string;

}

const ELEMENT_DATA: TravelRequestElement[] = [
  {sno: 1, title:'User manual for course module', type:'User Manual', url:'https://angular.io/docs'},
  {sno: 2, title:'User manual for Finance module', type:'User Manual', url:'https://angular.io/docs'},

  {sno: 3, title:'User manual for Admin Role', type:'User Manual', url:'https://angular.io/docs'},

  {sno: 4, title:'User manual for Sales Module', type:'User Manual', url:'https://angular.io/docs'},
  {sno: 5, title:'User manual for Logistics module', type:'User Manual', url:'https://angular.io/docs'},

    
];

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.css']
})
export class TutorialsComponent implements OnInit {
  displayedColumns: string[] = ['sno','title', 'type','url','attachments','status','action'];
  dataSource = ELEMENT_DATA;

  tutorialTypeValue='0';

  imageBoolean = false;
  docBoolean=false;
  pdfBoolean=false;
  audioBoolean=false;


  constructor(private route: Router) { }

  ngOnInit(): void {
    $('.btnClass').on('click', function(){
      $('.btnClass').removeClass('selected');
      $(this).addClass('selected');
  });
  }

  addTutorial(){
    $('#AddTutorialPopup').modal('show');
  }

  getTutorialType(value){
    this.tutorialTypeValue = value;
  }

  apiDocWindow(){
    window.location.href = "https://v3api-dev.certiplate.com/#/";
  }

  viewAttachments(){
    $('#AttachmentLinkPopup').modal('show')
  }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }
  
  isImage(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png':
        this.imageBoolean = true
        return true;
    }
    this.imageBoolean = false
    return false;
  }

  isDoc(filename){
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'xls':
      case 'xlsx':
      case 'doc':
      case 'docx':
        this.docBoolean = true
        return true;
    }
    this.docBoolean = false
    return false;
  }

  isPdf(filename){
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'pdf':
        this.pdfBoolean = true
        return true;
    }
    this.pdfBoolean = false
    return false;
  }

  isAudio(filename){
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'mp3':
      case 'ogg':
      case 'mp4':
      case 'aac':
      case 'wma':

        this.audioBoolean = true
        return true;
    }
    this.audioBoolean = false
    return false;
  }

  ShowAttachmentPopup(file){
    this.imageBoolean = false;
    this.docBoolean=false;
    this.pdfBoolean=false;
    this.audioBoolean=false;

    if(this.isImage(file)){
      $('#imgPreview').attr('src',file)
      $('#AttachmentLinkPopup').modal('hide')
      $('#AttachmentViewPopup').modal('show')
    }
    if(this.isPdf(file)){
      $('#docPreview').attr('src',file+"#toolbar=0&navpanes=0&scrollbar=0")
      $('#AttachmentLinkPopup').modal('hide')
      $('#AttachmentViewPopup').modal('show')
    }
    if(this.isDoc(file)){
      $('#docPreview').attr('src',"http://docs.google.com/viewer?url="+file+"&embedded=true")
      $('#AttachmentLinkPopup').modal('hide')
      $('#AttachmentViewPopup').modal('show')
    }
    
  }


}




