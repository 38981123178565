<ng-container class="container">
  <div class="dropdown-container" *ngIf="isOrgSuperAdmin">
    <select
      class="dropdown"
      (change)="handleOrgChange($event)"
    >
      <option value="" disabled [selected]="!selectedUserOrg">
        -Select Org-
      </option>
      <option *ngIf="userOrgList.length === 0" value="" disabled>
        No Orgs found
      </option>
      <option *ngFor="let list of userOrgList" [value]="list.id">
        {{ list.name }}
      </option>
    </select>
  </div>
  <div class="row record-overview-row">
    <div class="record-progress-container" *ngFor="let record of data">
      <record-progress [data]="record"></record-progress>
    </div>
  </div>
</ng-container>
