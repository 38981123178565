<div [matMenuTriggerFor]="notificationMenu" #menuTrigger="matMenuTrigger"></div>
<mat-menu
  #notificationMenu="matMenu"
  xPosition="before"
  class="notification-menu"
>
  <div class="container-notfications">
    <h4>Notifications</h4>
    <mat-slide-toggle
      (click)="$event.stopPropagation()"
      (input)="onInputChange()"
      class="float-right toggle-class"
      >Show unread</mat-slide-toggle
    >
    <hr class="line" />
    <div class="float-right mark-read" (click)="markAsRead($event)">
      Mark all as read
    </div>
    <div
      *ngIf="notificationsError || (notifications | json) == '{}'"
      class="container"
    >
      <div
        class="row align-items-center empty-notifications justify-content-center"
      >
        <div><i class="fas fa-bell fa-5x empty-notification-icon"></i></div>
        <div
          class="col-md-12 font-weight-bold empty-notification-text text-center"
        >
          No new notifications
        </div>
      </div>
    </div>
    <div *ngIf="!notificationsError && (notifications | json) != '{}'">
      <div
        infinite-scroll
        [infiniteScrollDistance]="distance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onScroll()"
      >
        <div
          class="row notifications-list mb-1"
          *ngFor="let notification of notifications | keyvalue : orderOriginal"
        >
          <div class="date">{{ notification.key }}</div>
          <br />
          <div *ngFor="let item of notification.value; let i = index">
            <div
              class="notification-card"
              (mouseover)="
                hoverActive = true; itemIndex = i; itemKey = notification.key
              "
              (mouseout)="hoverActive = false; itemIndex = -1; itemKey = ''"
              [ngStyle]="
                hoverActive &&
                i === itemIndex &&
                itemKey == notification.key && {
                  'background-color': 'rgb(221, 226, 231)'
                }
              "
              (click)="handle_no_url($event)"
            >
              <div class="row d-flex justify-content-start align-items-center">
                <div class="col-lg-2 col-image">
                  <div class="image">
                    <p
                      class="image-text"
                      matTooltip="{{
                        item.created_user_id === item.notifier_user_id
                          ? 'System Notification'
                          : 'A'
                      }}"
                      [ngClass]="{
                        'font-italic':
                          item.created_user_id === item.notifier_user_id,
                        'font-weight-normal':
                          item.created_user_id !== item.notifier_user_id
                      }"
                    >
                      {{
                        item.created_user_id === item.notifier_user_id
                          ? "s"
                          : printInitial(item.sender)
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="col-lg-9 w-100 notification-text d-flex justify-content-between mr-2"
                >
                  <div class="mr-2">
                    <div class="notification-header">
                      {{ item.title }}
                      <br />
                      <span class="time">{{
                        getTime(item.created_datetime)
                      }}</span>
                    </div>
                    <div
                      *ngIf="!item.url"
                      class="notification"
                      (click)="handle_no_url($event)"
                    >
                      {{ item.message }}
                    </div>
                    <div *ngIf="item.url" class="notification">
                      <a [routerLink]="[item.url]"
                        >{{ item.message
                        }}<span class="ml-2"
                          ><i class="fas fa-arrow-alt-circle-right"></i></span
                      ></a>
                    </div>
                  </div>
                  <i
                    *ngIf="item.is_read"
                    class="fas fa-envelope-open envelope"
                    matTooltip="Read"
                    (click)="$event.stopPropagation()"
                  ></i>
                  <i
                    *ngIf="!item.is_read"
                    class="fas fa-envelope envelope-open"
                    matTooltip="Unread"
                    (click)="markAsRead($event, item?.id)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-menu>
