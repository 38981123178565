import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import packageJson from '../package.json'

let env = 'dev'
if (window.location.hostname === 'localhost') {
  env = 'local';
} else {
  const environment = window.location.host.replace('.certiplate.com', '');
  const env_item = ['app-dev','app2-dev', 'app-qa', 'app-uat'].includes(environment) ? environment : 'prod';
  env = env_item == 'prod' ? 'production' : env.split('-')[1]
}

Sentry.init({
  dsn: environment.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/app2-dev.certiplate\.com/, /^https:\/\/app-qa.certiplate\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: env || 'dev',
  release: packageJson.version || '',
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
