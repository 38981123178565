import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { DialogService } from '@app-admin/dialogs/dialog.service';
import { UploadMouComponent } from '@app-admin/dialogs/upload-mou/upload-mou.component';
import { UtilService } from '@app-shared/services/util.service';
import { AdminService } from '@app/admin/admin.service';
import { getXReferer } from '@app/operation/project/project.constants';
import {
  FOLDER_TYPES,
  HEADER_X_REFFERER,
} from '@app/shared/constants/application-constants';
import { GetService } from '@app/shared/table/get.service';
import { GetAllService } from '@app/shared/table/getAll.service';

// const tableData: any[] = [
//   {sno: 1, agreement_date: '2021-12-01', agreement_expiry_date: '2022-12-01', mou_file_url: 'http://www.africau.edu/images/default/sample.pdf', is_active: true},
//   {sno: 2, agreement_date: '2021-12-01', agreement_expiry_date: '2022-12-01', mou_file_url: 'http://www.africau.edu/images/default/sample.pdf', is_active: false},
//   {sno: 3, agreement_date: '2021-12-01', agreement_expiry_date: '2022-12-01', mou_file_url: 'http://www.africau.edu/images/default/sample.pdf', is_active: false},
//   {sno: 4, agreement_date: '2021-12-01', agreement_expiry_date: '2022-12-01', mou_file_url: 'http://www.africau.edu/images/default/sample.pdf', is_active: false},
//   {sno: 5, agreement_date: '2021-12-01', agreement_expiry_date: '2022-12-01', mou_file_url: 'http://www.africau.edu/images/default/sample.pdf', is_active: false}
// ];

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-list-mou',
  templateUrl: './list-mou.component.html',
  styleUrls: ['./list-mou.component.css'],
})
export class ListMouComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'sno',
    'agreement_start_date',
    'agreement_expiry_date',
    'action',
  ];
  dataSource: MatTableDataSource<any>;
  serviceError: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dialogSrvc: DialogService,
    private util: UtilService,
    private route: Router,
    private cdr: ChangeDetectorRef,
    private getService: GetService,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    const x_referer = getXReferer(this.route.url)
    localStorage.setItem(HEADER_X_REFFERER, x_referer);
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dialogSrvc.getMOUs(parseInt(this.data)).subscribe(
      (data: any) => {
        data.body.responseBody.forEach(element => {
          let todayDate:any = new Date();
          let expiryDate:any = new Date(element.agreement_expiry_date);
          let diff:any;
          diff =  expiryDate - todayDate;
          if(diff>0){
            element.is_active = true;
          }else{
            element.is_active = false;
          }
        });
        const tableData = data.body.responseBody;
        if (tableData.length === 0) {
          this.addMOUPopup();
        }
        this.dataSource.data = tableData;
        this.dataSource._updateChangeSubscription();
        this.cdr.markForCheck();
      },
      (err: any) => {
        this.util.errorNotification(err);
        this.cdr.markForCheck();
      }
    );
  }

  ngOnDestroy(): void {
    localStorage.setItem(HEADER_X_REFFERER, '');
  }

  addMOUPopup(data?: any) {
    const dialogRef = this.dialog.open(UploadMouComponent, {
      width: '100vw',
      autoFocus: false,
      data: data || {orgId: this.data}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.dialogSrvc.getMOUs(parseInt(this.data)).subscribe(
        (data: any) => {
          data.body.responseBody.forEach(element => {
            let todayDate:any = new Date();
            let expiryDate:any = new Date(element.agreement_expiry_date);
            let diff:any;
            diff =  expiryDate - todayDate;
            if(diff>0){
              element.is_active = true;
            }else{
              element.is_active = false;
            }
          });
          const tableData = data.body.responseBody;
          this.dataSource.data = tableData;
          this.dataSource._updateChangeSubscription();
          this.cdr.markForCheck();
        },
        (err: any) => {
          this.util.errorNotification(err);
          this.cdr.markForCheck();
        }
      );
    });
  }

  modifyMOU(id) {
    this.getService.get('mouById',parseInt(id)).subscribe(
      (data: any) => {
        this.addMOUPopup(data.body.responseBody[0]);
      },
      (err: any) => {
        this.util.errorNotification(err);
        this.cdr.markForCheck();
      }
    );
  }

  showRevisionHistory(row: any) {
    this.dialog.closeAll();
    this.route.navigate(['/admin/org-mou/RevisionHistory',row.id,row.agreement_start_date]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  previewMouFile(fileUrl: string){
    const data = {
      key: fileUrl
    }
    this.adminService.download(data).subscribe(
      (data: any) => {
        const response = data.body.responseBody
        window.open(response.url, '_blank');
      },
      (err: any) => {
        this.util.errorNotification(err);
        this.cdr.markForCheck();
      }
    );

  }

}
