<div *ngIf="isLoading" id="spinner">
  <mat-progress-spinner
    class="mat-spinner-color"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>
<div class="container">
  <div class="top-div">
    <custom-segment
      (segmentValue)="onSegmentSelected($event)"
      [segmentsArray]="segmentsArray"
    ></custom-segment>
  </div>

  <div class="accordion-container">
    <mat-accordion class="accordion" multi>
      <div *ngFor="let element of dashboardElements; let i = index">
        <mat-expansion-panel
          class="pb-2"
          [expanded]="element.isPanelOpened"
          [attr.id]="element.type"
        >
          <mat-expansion-panel-header (click)="togglePanel(i, element.type)">
            <mat-icon
              *ngIf="element.isPanelOpened"
              class="download-icon"
              (click)="$event.stopPropagation(); downloadScreenshot()"
            >
              download
            </mat-icon>
            <mat-panel-title> {{ element.type }} </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-container [ngSwitch]="element.type">
            <ng-container *ngSwitchCase="'Activity Tracker'">
              <activity-tracker-overview
                [data]="element.data"
                (onChangeTab)="onChangeTab($event)"
              ></activity-tracker-overview>
            </ng-container>
            <ng-container *ngSwitchCase="'Sales'">
              <sales-content-operations-overview
                [option]="option"
                [dateFilter]="element.selectedDateFilter"
                [overviewData]="element.data"
                [barchartOptions]="element.barChartOptions"
                [selectedOrg]="element.selectedOrg"
                (onOrganizationChange)="onChangeOrganization($event)"
              ></sales-content-operations-overview>
            </ng-container>
            <ng-container *ngSwitchCase="'Content'">
              <sales-content-operations-overview
                [option]="option"
                [dateFilter]="element.selectedDateFilter"
                [overviewData]="element.data"
                [barchartOptions]="element.barChartOptions"
                [selectedOrg]="element.selectedOrg"
                (onOrganizationChange)="onChangeOrganization($event)"
              ></sales-content-operations-overview>
            </ng-container>
            <ng-container *ngSwitchCase="'Operations'">
              <sales-content-operations-overview
                [option]="option"
                [dateFilter]="element.selectedDateFilter"
                [overviewData]="element.data"
                [barchartOptions]="element.barChartOptions"
                [selectedOrg]="element.selectedOrg"
                (onOrganizationChange)="onChangeOrganization($event)"
              ></sales-content-operations-overview>
            </ng-container>
            <ng-container *ngSwitchCase="'Finance'">
              <finance-and-resources-overview
                [option]="option"
                [dateFilter]="element.selectedDateFilter"
                [overviewData]="element.data"
                [selectedOrg]="element.selectedOrg"
                (onOrganizationChange)="onChangeOrganization($event)"
              ></finance-and-resources-overview>
            </ng-container>
            <ng-container *ngSwitchCase="'Resources'">
              <finance-and-resources-overview
                [option]="option"
                [dateFilter]="element.selectedDateFilter"
                [overviewData]="element.data"
                [selectedOrg]="element.selectedOrg"
                (onOrganizationChange)="onChangeOrganization($event)"
              ></finance-and-resources-overview>
            </ng-container>
            <ng-container *ngSwitchCase="'Organization data by industry'">
              <div *ngIf="isOrgSuperAdmin">
                <organisation-overview
                  [option]="option"
                  [overviewData]="element.data"
                  [barchartOptions]="element.barChartOptions"
                  [dateFilter]="element.selectedDateFilter"
                ></organisation-overview>
              </div>
            </ng-container>
          </ng-container>

          <ng-template #noData>
            <div class="no-records-container">No records found</div>
          </ng-template>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>
</div>
