import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'sales-content-operations-overview',
  templateUrl: './sales-content-operations-overview.component.html',
  styleUrls: ['./sales-content-operations-overview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesContentOperationsOverviewComponent
  implements OnInit, AfterViewInit
{
  @Input() option: any;
  @Input() overviewData: any;
  @Input() barchartOptions: any;
  @Input() dateFilter: any;
  @Input() selectedOrg: number

  @Output() onOrganizationChange: EventEmitter<any> = new EventEmitter<any>();
  isOrgSuperAdmin: boolean = false;

  selectedOption: string = '';
  selectedDateFilter: string = '';
  dataSource: any = [];
  isIncreased: boolean = false;
  data: any;
  categories: any;
  seriesData: any;
  userOrgList: any;
  selectedUserOrg: any;

  chartOptions: any = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.chartOptions = this.barchartOptions;
    const superUserId = localStorage.getItem('superUserId');
    if (superUserId && parseInt(superUserId, 10) === 1) {
      this.isOrgSuperAdmin = true;
    }
    this.userOrgList = JSON.parse(localStorage.getItem('allOrgList'));
    this.selectedUserOrg = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chartOptions = this.barchartOptions;

    if (changes['overviewData']) {
      this.dataSource = changes['overviewData'].currentValue;
      this.cdr.detectChanges();
    }
    if (changes['option']) {
      this.selectedOption = changes['option'].currentValue;
      this.cdr.detectChanges();
    }
    if (changes['dateFilter']) {
      this.selectedDateFilter = changes['dateFilter'].currentValue;
      this.cdr.detectChanges();
    }
    if (changes['selectedOrg']) {
      this.selectedUserOrg = this.selectedOrg
    }
    if (this.selectedOption === 'sales') {
      this.data = [
        {
          title: 'Leads',
          totalCount: this.dataSource.leads.current_period || 0,
          percentage:
            this.getIncreasedOrDecreasedPercentage(
              this.dataSource.leads.current_period,
              this.dataSource.leads.previous_period
            ) || 0,
          isIncreased: this.isIncreased,
          selectedDateFilter: this.selectedDateFilter,
        },
        {
          title: 'Customers',
          totalCount: this.dataSource.customers.current_period || 0,
          percentage:
            this.getIncreasedOrDecreasedPercentage(
              this.dataSource.customers.current_period,
              this.dataSource.customers.previous_period
            ) || 0,
          isIncreased: this.isIncreased,
          selectedDateFilter: this.selectedDateFilter,
        },
      ];

      const industryVerticals = Object.values(
        this.dataSource.industry_vertical_data
      );

      this.categories = industryVerticals.map(
        (item: any) => item.industry_vertical_name
      );
      const leadsData = industryVerticals.map((item: any) => item.leads);
      const customersData = industryVerticals.map(
        (item: any) => item.customers
      );

      this.seriesData = [
        { name: 'Leads', data: leadsData, color: '#BD3630' },
        { name: 'Customers', data: customersData, color: '#27CA5F' },
      ];
      this.cdr.detectChanges();
    } else if (this.selectedOption === 'content') {
      this.data = [
        {
          title: 'Courses',
          totalCount: this.dataSource.course_stats.current_period || 0,
          percentage: this.getIncreasedOrDecreasedPercentage(
            this.dataSource.course_stats.current_period,
            this.dataSource.course_stats.previous_period
          ),
          isIncreased: this.isIncreased,
          selectedDateFilter: this.selectedDateFilter,
        },
        {
          title: 'Question Banks',
          totalCount: this.dataSource.question_bank_stats.current_period || 0,
          percentage: this.getIncreasedOrDecreasedPercentage(
            this.dataSource.question_bank_stats.current_period,
            this.dataSource.question_bank_stats.previous_period
          ),
          isIncreased: this.isIncreased,
          selectedDateFilter: this.selectedDateFilter,
        },
      ];

      const difficultyLevels = this.dataSource.questions_by_difficulty_level;

      this.categories = difficultyLevels.map((item: any) => {
        switch (item.difficulty_level_id) {
          case 1:
            return 'Easy';
          case 2:
            return 'Medium';
          case 3:
            return 'Difficult';
        }
      });

      const previousCount = difficultyLevels.map(
        (item: any) => item.previous_period
      );
      const customersData = difficultyLevels.map(
        (item: any) => item.current_period
      );

      this.seriesData = [
        {
          name: 'Previous Question Count',
          data: previousCount,
          color: '#BD3630',
        },
        {
          name: 'Current Question Count',
          data: customersData,
          color: '#27CA5F',
        },
      ];
    } else if (this.selectedOption === 'operations') {
      this.data = [
        {
          title: 'Candidates',
          totalCount: this.dataSource.candidate_stats.current_period || 0,
          percentage: this.getIncreasedOrDecreasedPercentage(
            this.dataSource.candidate_stats.current_period,
            this.dataSource.candidate_stats.previous_period
          ),
          isIncreased: this.isIncreased,
          selectedDateFilter: this.selectedDateFilter,
        },
        {
          title: 'Assessments',
          totalCount: this.dataSource.assessment_stats.current_period || 0,
          percentage: this.getIncreasedOrDecreasedPercentage(
            this.dataSource.assessment_stats.current_period,
            this.dataSource.assessment_stats.previous_period
          ),
          isIncreased: this.isIncreased,
          selectedDateFilter: this.selectedDateFilter,
        },
      ];

      const violations = this.dataSource.assessment_violation_stats;

      this.categories = violations
        .filter((item: any) => item.count > 0)
        .map((item: any) => item.title);

      const violationsData = violations
        .filter((item: any) => item.count > 0)
        .map((item: any) => item.count);

      this.seriesData = [
        {
          name: 'Violation Count',
          data: violationsData,
          color: '#27CA5F',
        },
      ];
    }
    this.cdr.detectChanges();
  }

  getIncreasedOrDecreasedPercentage(current: number, previous: number) {
    let percentage: any = ((current - previous) / previous) * 100;
    if (percentage === Infinity) {
      this.isIncreased = true;
      return 100;
    } else if (!Number.isNaN(percentage) || percentage < 0) {
      this.isIncreased = false;
      return percentage === 0 ? 0 : Math.abs(percentage).toFixed(2);
    } else if (!Number.isNaN(percentage) || percentage > 0) {
      this.isIncreased = true;
      return percentage === 0 ? 0 : percentage.toFixed(2);
    } else {
      this.isIncreased = true;
      return 0;
    }
  }

  ngAfterViewInit(): void {}

  handleOrgChange(e: any) {
    const orgId = e.target.value;
    this.selectedUserOrg = orgId
    this.onOrganizationChange.emit({orgId, option : this.selectedOption});
  }
}
