<div style="display:flex;">
    <button class="mat-close-btn" style="top:1.5rem;right:1rem;" mat-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <h2 mat-dialog-title style="margin:0px;width:50%;">List of Admins</h2>
    <button mat-mini-fab matTooltip="Add Admin" (click)="addAdminPopup()" style="display: block;margin-left: auto;margin-right: 2rem;">
        <mat-icon class="matclass">add</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <mat-form-field appearance="standard" class="filter-position">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Type to search any" #input>
    </mat-form-field>
    <div class="table-container mat-elevation-z8">
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>S.No. </th>
                <td mat-cell *matCellDef="let element; let tableIndex = index"> {{(tableIndex + 1) || element.sno }} </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">{{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let element">{{element.email}} </td>
            </ng-container>
            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
                <td mat-cell *matCellDef="let element">{{element.phone}} </td>
            </ng-container>
            <ng-container matColumnDef="designation">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Designation</th>
                <td mat-cell *matCellDef="let element">{{element.designation}} </td>
            </ng-container>
            <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
                <td mat-cell *matCellDef="let element">{{element.location}} </td>
            </ng-container>
            <ng-container matColumnDef="is_active">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">
                    <mat-slide-toggle [disabled]="!isOrgSuperAdmin && !isSuperAdmin" [checked]="element.is_active" (change)="updateStatus($event, element.id)">Active</mat-slide-toggle>
                </td>
            </ng-container>
            <ng-container *ngIf="isSuperAdmin" matColumnDef="is_org_admin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Org Super Admin</th>
                <td mat-cell *matCellDef="let element">
                    <mat-slide-toggle [checked]="element.is_org_admin" (change)="updateOrgAdmin($event, element.id)">Yes</mat-slide-toggle>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th style="padding-left: 52px;" mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let element">
                    <button class="btnTableRow" mat-mini-fab matTooltip="Modify Admin" (click)="modifyAdmin(element.id)">
                        <mat-icon class="matclass">create</mat-icon>
                    </button>
                    <button type="button" class="btnTableRow" mat-mini-fab matTooltip="Show Revision History" (click)="showRevisionHistory(element)"><i class="fa fa-history matclass" style="margin-bottom: 5px;" aria-hidden="true"></i></button>
                    <button class="btnTableRow" mat-mini-fab matTooltip="Delete Admin" (click)="deleteAdmin(element.id)">
                        <mat-icon class="matclass">delete</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <ng-template matNoDataRow>
                <tr>
                    <td colspan="8" style="text-align: center;padding: 10px;">
                        No records found!
                    </td>
                </tr>
            </ng-template>
        </table>
    </div>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions> -->