<div class="modal-header text-center">
  <h3 id="hdrImageTitle" class="modal-title text-dark">{{data.title}}</h3>
  <button
    type="button"
    title="Close"
    class="close float-right text-dark"
    data-dismiss="modal"
    aria-label="Close"
    mat-dialog-close
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content>
  <div class="mb-4">
    <editor #editor
      id="tinymce_non_editor"
      apiKey="editorApiKey"
      [init]="mceOptionConfig"
      initialValue="{{data.text}}"
    >
    </editor>
  </div>
</mat-dialog-content>
<div class="mt-5"></div>
