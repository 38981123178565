export const fields = {
    COURSE_MASTER: [
        { field: 'QP Framework', datatype: 'string', required: true, regex: null, dataDescription: 'string (Ex: abcd)', patternDescription: '' },
        { field: 'QP Course', datatype: 'string', required: true, regex: null, dataDescription: 'string (Ex: abcd)', patternDescription: '' },
        { field: 'QP', datatype: 'string', required: true, regex: null, dataDescription: 'string (Ex: abcd)', patternDescription: '' },
    ],
    BUSINESS_VERTICAL: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'code', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
    ],
    RATE_MASTER: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'customer', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'service', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'code', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
    ],
    PROJECT: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'alias', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'short_name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'tags', datatype: 'json', required: false, regex: null, dataDescription: 'json (Ex: {"Course": ["Test", "Test 3"]}', patternDescription: '' },
    ],
    USERS: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'full_name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample)', patternDescription: '' },
        { field: 'phone', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: 9999999999)', patternDescription: '' },
        { field: 'email', datatype: 'string', required: true, regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, dataDescription: 'text (Ex: xyz@gmail.com)', patternDescription: 'text (Ex: xyz@gmail.com)' },
    ],
    CANDIDATE: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'full_name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample)', patternDescription: '' },
        { field: 'pincode', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: 560062)', patternDescription: '' },
        { field: 'district', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: Bengaluru)', patternDescription: '' },
        { field: 'state', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: Karnataka)', patternDescription: '' },
        { field: 'phone', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: 9999999999)', patternDescription: '' },
        { field: 'email', datatype: 'string', required: false, regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, dataDescription: 'text (Ex: xyz@gmail.com)', patternDescription: 'text (Ex: xyz@gmail.com)' },
    ],
    LEAD_MASTER: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'business_vertical_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'spoc_ids', datatype: 'string', required: true, regex: null, dataDescription: 'text', patternDescription: 'text (Ex: sample12^&@;' },
        { field: 'address', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'status_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'estimated_units', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'estimated_revenue', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'industry_vertical_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'pincode_id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'type_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'geography_id', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
    ],
    CUSTOMER_MASTER: [
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'name', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'business_vertical_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'gstin', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'pan', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'primary_spoc_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'spoc_ids', datatype: 'string', required: true, regex: null, dataDescription: 'text', patternDescription: 'text (Ex: sample12^&@;' },
        { field: 'address', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'logo_file_name', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'industry_vertical_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'type_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'status_id', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'pincode_id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'geography_id', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },

    ],

    assessments:[
        { field: 'id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: '' },
        { field: 'organization_id', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'title', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'description', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'instructions', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: '' },
        { field: 'start_datetime', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'end_datetime', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'tags', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'custom_attrs', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'rules', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'address_line1', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'address_line2', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'district', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'state', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'pincode', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},     
        { field: 'status', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'is_archived', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'is_deleted', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'is_active', datatype: 'string', required: true, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'created_user_id', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'created_datetime', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'modified_user_id', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},
        { field: 'modified_datetime', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'mode', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'access', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'count', datatype: 'number', required: true, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},     
        { field: 'languages', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'singleTimer', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'jumpSections', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'stakeholders', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'jumpQuestions', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'assessmentLogs', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'remoteBlocking', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'videoStreaming', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableRecording', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableTutorials', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'screenRecording', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableGeoFencing', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'availableOnMobile', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableChatSupport', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableSpecialKeys', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'partialSubmission', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'IDCardVerification', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'accessNotification', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'additionalEvidence', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'displayOfQuestions', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableAppSwitching', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'languagesSpecifier', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'maxViolationsCount', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'additionalEvidences', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableFaceDetection', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableStateTracking', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'showDifficultyLevel', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'showMarkPerQuestion', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableMultipleLogins', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableFaceRecognition', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'enableObjectDetection', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'generateResponseSheet', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'showFeedbackQuestions', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'stakeholderCategories', datatype: 'number', required: false, regex: null, dataDescription: 'number (Ex: 1234)', patternDescription: ''},     
        { field: 'captureCandidatesImage', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'enableHeadPoseTracking', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'generateAttendanceSheet', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'generateFlagsInRealTime', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'showResultsToCandidates', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'candidatesVideoRecording', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'enableRemoteInvigilation', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'enableBrowserTabSwitching', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'enablePracticeAssessments', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'showResponsesToCandidates', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'asynchronousIntegrityCheck', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'notifyCandidatesForResults', datatype: 'string', required: false, regex: null, dataDescription: '(Ex: sample12^&@)', patternDescription: ''},     
        { field: 'enableChatAmongStakeholders', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'generateHallTicketAdmitCard', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'generateWarningsForCandidates', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'captureImageAtRegularIntervals', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'forcedScreenSharingAndRecording', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
        { field: 'captureImageAtRegularIntervalsInterval', datatype: 'string', required: false, regex: null, dataDescription: 'text (Ex: sample12^&@;', patternDescription: ''},
    
    ]

}

