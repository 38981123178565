import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { UtilService } from './shared/services/util.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: Socket;
  env: string;
  socketBaseURL: string;

  get userId(): number {
    return parseInt(localStorage.getItem('UserId'));
  }

  constructor(private util: UtilService) {
	this.init();
    this.socket = io(`${this.socketBaseURL}/notifications-${this.userId}`, {
      query: {
        user: this.userId,
      },
      reconnection: true,
    });

    this.socket.on('disconnect', () => {
      this.socket.connect();
    });
  }

  init(){
    // setup env
    this.socketBaseURL = this.util.getApiUrl()
  }

  onNewNotification() {
    return new Observable((observer) => {
      this.socket.on(`${this.userId}_notification_receiver`, (msg) => {
        observer.next(msg);
      });
    });
  }
}
