import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';

// Common Components
import { NgVerticalTimelineComponent } from '@app-shared/components/ng-vertical-timeline/ng-vetical-timeline.component';
import { SimpleDialogComponent } from '@app-shared/components/simple-dialog/simple-dialog.component';
import { AddTagsComponent } from '@app-shared/components/tags/add-tags.component';
import { FooterComponent } from '@app/footer/footer.component';
import { HeaderComponent } from '@app/header/header.component';

import { environment } from '@env/environment';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { StripHtmlPipe } from './Pipes/striphtml.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AccessControlDirective } from './directives/access-control.directive';
import { TableComponent } from './table/table.component';
import { DataPropertyGetterPipe } from './table/data-property-getter-pipe/data-property-getter-pipe.pipe';
import { HighlightSearchPipe } from './table/highlight-search-pipe/highlight-search.pipe';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ModalComponent } from './components/modal/modal.component';
import { UploadfilesComponent } from './uploadfiles/uploadfiles.component';
import { PiechartSharedComponent } from './components/piechart/piechart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { BarChartComponent } from './components/barchart/barchart.component';
import { UploadComponent } from './components/upload/upload.component';
import { AppNotificationsComponent } from './components/app-notifications/app-notifications.component';
import { TreeviewModule } from 'ngx-treeview';
import { CandidatetableComponent } from './components/candidatetable/candidatetable.component';
import { FilterComponent } from './components/filter/filter.component';
import { GlobalsearchComponent } from './components/globalsearch/globalsearch.component';
import { BoldPipe } from './Pipes/bold.pipe';
import { InvoiceComponent } from './templates/invoice/invoice.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PopoverModule } from './components/popover/popover.module';
import { MinutesToTimePipe } from './Pipes/minutesToTime';
import { NonedittextboxComponent } from './components/nonedittextbox/nonedittextbox.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SegmentComponent } from './components/segment/segment.component';
import { AutoSuggestionsComponent } from './autocomplete/autocomplete.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: environment.DATE_FORMAT, //'DD/MMM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

let commonModules = [
  CommonModule,
  HttpClientModule,
  ReactiveFormsModule,
  FormsModule,
  MatTableExporterModule,
  MatIconModule,
  MatButtonModule,
  MatDividerModule,
  MatToolbarModule,
  MatMenuModule,
  MatListModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatSlideToggleModule,
  MatAutocompleteModule,
  MatSidenavModule,
  MatCardModule,
  MatPaginatorModule,
  MatTableModule,
  MatInputModule,
  MatTabsModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatSelectModule,
  MatTooltipModule,
  MatChipsModule,
  MatRadioModule,
  MatDialogModule,
  RouterModule,
  MatDatepickerModule,
  MatMomentDateModule,
  HighchartsChartModule,
  MatExpansionModule,
];

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SimpleDialogComponent,
    NgVerticalTimelineComponent,
    AddTagsComponent,
    BreadcrumbComponent,
    StripHtmlPipe,
    MinutesToTimePipe,
    BoldPipe,
    ClickOutsideDirective,
    AccessControlDirective,
    TableComponent,
    DataPropertyGetterPipe,
    HighlightSearchPipe,
    ModalComponent,
    UploadfilesComponent,
    PiechartSharedComponent,
    BarChartComponent,
    UploadComponent,
    AppNotificationsComponent,
    CandidatetableComponent,
    FilterComponent,
    GlobalsearchComponent,
    InvoiceComponent,
    NonedittextboxComponent,
    SegmentComponent,
    AutoSuggestionsComponent,
  ],
  imports: [
    ...commonModules,
    InfiniteScrollModule,
    BsDatepickerModule.forRoot(),
    TreeviewModule.forRoot(),
    PopoverModule,
    EditorModule,
  ],
  exports: [
    ...commonModules,
    BsDatepickerModule,
    HeaderComponent,
    FooterComponent,
    NgVerticalTimelineComponent,
    AppNotificationsComponent,
    AddTagsComponent,
    BreadcrumbComponent,
    StripHtmlPipe,
    MinutesToTimePipe,
    ClickOutsideDirective,
    AccessControlDirective,
    TableComponent,
    PiechartSharedComponent,
    BarChartComponent,
    CandidatetableComponent,
    GlobalsearchComponent,
    SegmentComponent,
    AutoSuggestionsComponent,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SharedModule {}
