import { Injectable } from '@angular/core';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { UtilService } from '@app-shared/services/util.service';
import { DELETE } from '../constants/application-constants';

@Injectable({
  providedIn: 'root'
})
export class DeleteService {
  
  constructor(
    private api: ApiService,
    private util: UtilService
  ) {}

  get jwt(): string | null {
    return localStorage.getItem('JwtToken');
  }
  get orgID(): string | null{
    const value = localStorage.getItem('OrgId');
    if (value) {
      return this.util.decrypt(value);
    }
    return null;
  }

  delete(data: any ,module: any) {
    const headers = {
      "x-operation" : DELETE
    }
    var postURL = '/delete/' + module;
    const postData: APIRequest = {
       
        requestBody: {
          ...data
        }
      };

    return this.api.post(postURL, postData, headers);
    }
}