import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-auto-suggestions',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoSuggestionsComponent implements OnInit {
  @Input() placeholder: string = 'Type to search...';
  @Input() suggestions: string[] = [];
  @Input() isLoading: boolean = false;
  @Input() noSuggestions: boolean = false;

  @Output() searchTermChange = new EventEmitter<string>();
  @Output() selectedItem = new EventEmitter<string>();

  public searchTerm: string = '';
  public isFocused: boolean = false;

  ngOnInit(): void {}

  onSearchChange(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.searchTermChange.emit(searchTerm);
  }

  onSuggestionClick(suggestion: any) {
    this.searchTerm = suggestion.value;
    this.suggestions = [];
    this.isFocused = false;
    this.selectedItem.emit(suggestion);
  }

  clearSearch() {
    this.searchTerm = '';
    this.suggestions = [];
    this.noSuggestions = false;
    this.isLoading = false;
    this.searchTermChange.emit(this.searchTerm);
  }

  toggleDropdown() {
    if (this.searchTerm.length === 0) {
      this.isFocused = !this.isFocused;
      this.noSuggestions = false;
    } else {
      this.searchTermChange.emit(this.searchTerm);
    }
  }

  onFocus() {
    this.isFocused = true;
    if (this.searchTerm.length > 0) {
      this.searchTermChange.emit(this.searchTerm);
    }
  }

  onBlur() {
    setTimeout(() => (this.isFocused = false), 100);
  }
}
