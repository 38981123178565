// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*-------------------------------------------------------------------------------------------------*/

  DATE_FORMAT: 'DD-MMM-YYYY',
  LOCALE: 'en-IN',

  /*-------------------------------------------------------------------------------------------------*/
  DEFAULT_VISIBILITY_IDS: [8, 21],
  SUB_MENU_IDS: [19],
  EDITOR_API_KEY: 'rcs2ne9ddapicjwo91rxce0hzf4jmzfgb4rihlvodi3diojx',
  FRESHDESK_CHAT_KEY: '//in.fw-cdn.com/32049251/1090631.js',
  SENTRY_DSN:
    'https://c06b12f4e28f883a27aeee292019e436@o4505114550206464.ingest.us.sentry.io/4507661010206720',
  VERSION: 'V3.0.0.0',
  APP_ENVIRONMENT: "development",
  firebaseConfig: {
    apiKey: 'AIzaSyCjnO8vwNytRlkZhVJ_gKf8KyMux780z5o',
    authDomain: 'certiplate-dev.firebaseapp.com',
    databaseURL: 'https://certiplate-dev-default-rtdb.firebaseio.com',
    projectId: 'certiplate-dev',
    storageBucket: 'certiplate-dev.appspot.com',
    messagingSenderId: '99066774983',
    appId: '1:99066774983:web:a062e1bd924099b719c5e2',
    measurementId: 'G-ZFB52LVM90',
  },
  vapidKey:
    'BJjYtq42krETXHjiKR2yFPfVTFbzsIodUvHdAS-xkvmyfztriQ3WOZBkTKQ-ghkkaphxuLE3kS9wmAy1f1G6Muw',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
