<ng-container class="container">
  <div class="mt-3">
    <div class="dropdown-container" *ngIf="isOrgSuperAdmin">
      <select
        class="dropdown"
        (change)="handleOrgChange($event)"
      >
        <option value="" disabled [selected]="!selectedUserOrg">
          -Select Org-
        </option>
        <option *ngFor="let list of userOrgList" [value]="list.id">
          {{ list.name }}
        </option>
        <option *ngIf="userOrgList.length === 0" value="" disabled>
          No Orgs found
        </option>
      </select>
    </div>
  </div>
  <div class="row row-alignment">
    <div class="col-lg-4 column-left">
      <div class="mt-3" *ngFor="let record of data">
        <record-progress [data]="record"></record-progress>
      </div>
    </div>
    <div class="col-lg-8 column-right">
      <div *ngIf="categories && categories.length > 0; else noRecords">
        <app-barchart
          [chartOptions]="chartOptions"
          [categories]="categories"
          [updateCategories]="true"
          [seriesData]="seriesData"
        ></app-barchart>
      </div>
      <ng-template #noRecords>
        <div class="no_records_found">
          <img
            src="assets/img/svgicons/no_records_found.svg"
            width="36"
            height="36"
          />
          <p style="padding-top: 6px">No records found</p>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
