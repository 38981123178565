
<div class="container">
    <!-- <div *ngIf="isLoading" id="spinner">
      <mat-progress-spinner class="mat-spinner-color"  mode="indeterminate"></mat-progress-spinner>
    </div> -->
    <!------------------------------------------------Button--------------------------------->

    <h3>Tutorials</h3>
    
    
    <mat-divider></mat-divider>
    <br>

    <!------------------------------------------------table content-------------------------->
    <button class="btnClass selected" mat-raised-button ><i class="fa fa-book" aria-hidden="true"></i> User Manual</button>
    <button class="btnClass"  mat-raised-button ><i class="fa fa-play" aria-hidden="true"></i> Videos</button>
    <button class="btnClass"  mat-raised-button (click)="apiDocWindow()"  ><i class="fa fa-file" aria-hidden="true"></i>API Docs</button>
    <button class="btnClass"  mat-raised-button ><i class="fa fa-list" aria-hidden="true"></i>Release Notes</button>

    <div class="float-right">
        <button class="add" mat-mini-fab>
            <mat-icon class="white-icon" matTooltip="Add Bill" (click)="addTutorial()">add</mat-icon>
        </button>
    </div><br>

    <div class="table-container mat-elevation-z8" #TABLE>
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef> S.No. </th>
                <td mat-cell *matCellDef="let element; let tableIndex = index"> {{(tableIndex + 1) || element.sno }} </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let element">{{element.title}}</td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let element"> {{element.type}}</td>
            </ng-container>

            <ng-container matColumnDef="url">
                <th mat-header-cell *matHeaderCellDef>URL</th>
                <td mat-cell *matCellDef="let element">
                    <a href="{{element.url}}"><i class="fa fa-external-link" aria-hidden="true"></i></a>
                        
                </td>
            </ng-container>

            <ng-container matColumnDef="attachments">
                <th mat-header-cell *matHeaderCellDef>Attachments</th>
                <td mat-cell *matCellDef="let row">
                    <button style="margin-left:20px;" (click)="viewAttachments()" class="btnTableRow" mat-mini-fab matTooltip="View Attachments"><mat-icon class="matclass">link</mat-icon></button>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    <mat-slide-toggle [checked]="true">Active</mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let row">
                    <button class="btnTableRow" mat-mini-fab matTooltip="Modify Bill" (click)="addTutorial()"><mat-icon class="matclass">create</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div> 

</div>




<!---------------------------------------------------------------Add travel request popup-------------------------------------------->
    <div class="modal fade" id="AddTutorialPopup" role="dialog" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg ">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header text-center">
                    <h3 id="hdrImageTitle" class="modal-title" style="color: black;">Add Tutorial</h3>
                    <button type="button" style="color: black;" class="close float-right" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- Modal body -->
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card bg-secondary shadow">
                                <div class="card-body">
                                    <form>
                                        <div class="pl-lg-4">       
                                                    <div class="row">

                                                        <div class="col-lg-6">
                                                            <div class="form-group required">
                                                                <label class="form-control-label">Title</label>
                                                                <input type="text" id="txtLabel" class="form-control form-control-alternative" placeholder="Enter Title" >                        
                                                            </div>
                                                        </div>
                                                    
                                                
                                                        <div class="col-lg-6">
                                                            <div class="form-group required">
                                                                <label class="form-control-label">Tutorial Type</label>
                                                                <select class="form-control" (change)="getTutorialType($event.target.value)">
                                                                    <option value="0">-Select Tutorial Type-</option>
                                                                    <option value="1">User Manual</option>
                                                                    <option value='2'>Videos</option>
                                                                    <option value='3'>External URL</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                            

                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div *ngIf="tutorialTypeValue=='0' || tutorialTypeValue=='1' || tutorialTypeValue=='2' " class="form-group required">
                                                                <label class="form-control-label">Title</label>
                                                                <input type="file" id="txtFileAttachment" class="form-control form-control-alternative" multiple >                        
                                                            </div>

                                                            <div *ngIf="tutorialTypeValue=='3'" class="form-group required">
                                                                <label class="form-control-label">Enter an https:// URL</label>
                                                                <input type="url" class="form-control form-control-alternative"  placeholder="https://example.com" pattern="https://.*">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                
                                                        <div class="col-lg-6">
                                                            <div class="form-group required">
                                                                <label class="form-control-label">Description</label>
                                                                <input type="text" id="txtDescription" class="form-control form-control-alternative" placeholder="Enter Description" >                        
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <button type="submit" id="submit" class="bounce-out-on-hover">Save</button>
                                                        </div>
                                                        </div>
                                                    </div>

                                            <!----------------------------------------------------------------------------------------------->
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!------------------------------------------------------------------View Attachments---------------------------------------------->
<div class="modal fade" id="AttachmentLinkPopup" role="dialog" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header text-center">
                <h3 id="hdrImageTitle" class="modal-title" style="color: black;">Attachments</h3>
                <button type="button" style="color: black;" class="close float-right" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- Modal body -->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card bg-secondary shadow">
                            <div class="card-body">
                                <form>
                                    <div class="pl-lg-4">
                                        
                                        <div id="questionAttachmentInfoRow" class="row">
                                            <div class="col-2">
                                                <label class="form-control-label">Attachments : </label>
                                            </div>
                                            <div class="col-10">
                                                <span>
                                                    <a href="" [routerLink]="null" id="attachmentLink" (click)="ShowAttachmentPopup('assets/images/test1.jpg')">
                                                        <img *ngIf="isImage('image.png')" src="assets/images/test1.jpg" class="img-thumbnail" alt="Cinque Terre" width="100" height="100">
                                                        <img *ngIf="isPdf('image.png')" src="assets/images/thumbnailPdf.jpg" class="img-thumbnail" alt="Cinque Terre" width="50" height="50">
                                                        <img *ngIf="isDoc('image.png')" src="assets/images/thumbnailDoc.jpg" class="img-thumbnail" alt="Cinque Terre" width="50" height="50">
    
                                                    </a>
                                                    <!-- <a href="" [routerLink]="" id="attachmentLink"
                                                        (click)="ShowAttachmentFiles(attachment)">
                                                        <img src="{{attachment}}" class="img-thumbnail" alt="Cinque Terre" width="100" height="100"> 
                                                    </a> -->
                                                    <!-- <a id="attachmentLabel" href="" [routerLink]="">(<i class="fa fa-pencil"
                                                            aria-hidden="true"></i>){{isLast?'':', '}}</a> -->
                                                </span>
                                            </div>
                                        </div><br>

                                        <!----------------------------------------------------------------------------------------------->
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

