<!------------------------------------------------card content-------------------------->
<div class="changepassword-page">
	<mat-card class="mat-elevation-z12">
        <br>
		<mat-error class="service-errors">{{serviceError}}</mat-error>
		<mat-card-content>
			<form [formGroup]="cpForm" (ngSubmit)="update()">
                <div id="success" class="text-center" *ngIf="successMsg">
                    <span>{{successMsg}}</span>
                </div>
				<mat-form-field>
                    <input type="password" matInput placeholder="Old Password" formControlName="currentPassword"/>
				    <mat-icon matSuffix>lock</mat-icon>
                    <mat-error *ngIf="cpForm.get('currentPassword').invalid && (cpForm.get('currentPassword').dirty || cpForm.get('currentPassword').touched)">
                        <span *ngIf="cpForm.get('currentPassword').errors?.required">Password is required</span>
                        <span *ngIf="cpForm.get('currentPassword').errors?.missingNumber">Password must have a atleast one digit</span>
						<span *ngIf="cpForm.get('currentPassword').errors?.missingUpperCase">Password must have a atleast one uppercase letter</span>
						<span *ngIf="cpForm.get('currentPassword').errors?.missingLowerCase">Password must have a atleast one lowercase letter</span>
						<span *ngIf="cpForm.get('currentPassword').errors?.missingMinLength">Password must have a atleast 8 character</span>
                    </mat-error>
				</mat-form-field>
				<mat-form-field>
                    <input type="password" matInput placeholder="New Password" formControlName="newPassword" [errorStateMatcher]="matcher1"/>
				    <mat-icon matSuffix>lock</mat-icon>
                    <mat-error>
                        <span *ngIf="cpForm.get('newPassword').errors?.required">Password is required</span>
                        <span *ngIf="cpForm.get('newPassword').errors?.missingNumber">Password must have a atleast one digit</span>
						<span *ngIf="cpForm.get('newPassword').errors?.missingUpperCase">Password must have a atleast one uppercase letter</span>
						<span *ngIf="cpForm.get('newPassword').errors?.missingLowerCase">Password must have a atleast one lowercase letter</span>
						<span *ngIf="cpForm.get('newPassword').errors?.missingMinLength">Password must have a atleast 8 character</span>
                        <span *ngIf="cpForm.get('newPassword').valid && cpForm.errors?.newPassword">Old Password and New Password should not be same</span>
                    </mat-error>
				</mat-form-field>
                <mat-form-field>
                    <input type="password" matInput placeholder="Confirm Password" formControlName="confirmPassword" [errorStateMatcher]="matcher2"/>
				    <mat-icon matSuffix>lock</mat-icon>
                    <mat-error>
                        <span *ngIf="cpForm.get('confirmPassword').errors?.required">Confirm Password is required</span>
                        <span *ngIf="cpForm.get('confirmPassword').errors?.missingNumber">Password must have a atleast one digit</span>
						<span *ngIf="cpForm.get('confirmPassword').errors?.missingUpperCase">Password must have a atleast one uppercase letter</span>
						<span *ngIf="cpForm.get('confirmPassword').errors?.missingLowerCase">Password must have a atleast one lowercase letter</span>
						<span *ngIf="cpForm.get('confirmPassword').errors?.missingMinLength">Password must have a atleast 8 character</span>
                        <span *ngIf="cpForm.get('confirmPassword').valid && cpForm.errors?.confirmPassword">Password and Confirm Password should be same</span>
                    </mat-error>
				</mat-form-field>
				<button type="submit" mat-raised-button class="login-button" [disabled]="!cpForm.valid">Update Password</button>
			</form>
		</mat-card-content>
	</mat-card>
</div>
<!-------------------------------------------------end card content---------------------->
