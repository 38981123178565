<button class="mat-close-btn" mat-button mat-dialog-close>
    <mat-icon>close</mat-icon>
</button>
<h2 mat-dialog-title>{{pageType}} MOU</h2>
<mat-dialog-content>
    <div class="container-fluid">
        <div class="row">
        <div class="col-xl-12">
            <div class="card bg-secondary shadow">
            <div class="card-body">
                <form [formGroup]="mouForm" (ngSubmit)="createMOU()">
                <div class="pl-lg-4">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group required">
                                <label class="form-control-label">Agreement Start Date</label>
                                <input type="text" formControlName="agreement_start_date" class="form-control form-control-alternative" placeholder="dd-mmm-yyyy" [matDatepicker]="picker1">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <div  *ngIf="mouForm.get('agreement_start_date')?.invalid && (mouForm.get('agreement_start_date')?.dirty || mouForm.get('agreement_start_date')?.touched)">
                                    <mat-error *ngIf="mouForm.get('agreement_start_date').errors?.required">Start Date is required</mat-error>
                                    <mat-error *ngIf="mouForm.get('agreement_start_date').errors?.dateRangeError">Start Date should be greater than existing agreement date range</mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group required">
                                <label class="form-control-label">Agreement Expiry Date</label>
                                <input type="text" formControlName="agreement_expiry_date" class="form-control form-control-alternative" placeholder="dd-mmm-yyyy" [min]="mouForm?.get('agreement_start_date')?.value" [matDatepicker]="picker2">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                                <div  *ngIf="mouForm.get('agreement_expiry_date')?.invalid && (mouForm.get('agreement_expiry_date')?.dirty || mouForm.get('agreement_expiry_date')?.touched)">
                                    <mat-error *ngIf="mouForm.get('agreement_expiry_date').errors?.required">Expiry Date is required</mat-error>
                                    <mat-error *ngIf="mouForm.get('agreement_expiry_date').errors?.dateRangeError">Expiry Date should not be in existing agreement date range</mat-error>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group required">
                                <label class="form-control-label">
                                    Upload Agreement Copy <small>({{validFileExtensions.join(', ')}})</small>
                                </label>
                                <input type="file" formControlName="agreement_file" class="form-control form-control-alternative" (change)="onFileChange($event)" [accept]="'image/'+validFileExtensions.join(', image/')+',application/pdf'"/>
                                <p>{{uploadedMouFile}}</p>
                                <div  *ngIf="mouForm.get('agreement_file')?.invalid && (mouForm.get('agreement_file')?.dirty || mouForm.get('agreement_file')?.touched)">
                                    <mat-error *ngIf="mouForm.get('agreement_file').errors?.required">
                                        Agreement Copy is required
                                    </mat-error>
                                    <mat-error *ngIf="mouForm.get('agreement_file').errors?.requiredFileType">
                                        Agreement Copy file type should be in any of these ({{validFileExtensions.join(', ')}})
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label class="form-control-label">Description</label>
                                <input type="text" formControlName="agreement_desc" class="form-control form-control-alternative" placeholder="Description">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="d-flex" style="justify-content: flex-end;">
                              <button type="button" style="margin-right: 1rem;" class="btn btn-success" id="modal-btn-cancel" mat-dialog-close>Cancel</button>
                              <button type="submit" class="btn btn-success mat-raised-button" id="modal-btn-confirm" [disabled]="!mouForm.valid">Save</button>
                            </div>
                            <!-- <div class="form-group">
                            <button type="submit" class="form-submit-btn mat-raised-button bounce-out-on-hover" [disabled]="!mouForm.valid">Save</button>
                            </div> -->
                        </div>
                    </div>
                </div>
                </form>
                <!----------------------------------------success modal---------------------------------------->
                <div id="myModal" class="modal fade">
                <div class="modal-dialog modal-confirm">
                    <div class="modal-content">
                    <div class="modal-header justify-content-center">
                        <div class="icon-box">
                        <i class="material-icons">&#xE876;</i>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4>Great!</h4>	
                        <p>Your details has been saved successfully.</p>
                    </div>
                    </div>
                </div>
                </div>     
                <!----------------------------------------------------------------------------------------------->
            </div>
            </div>
        </div>
        </div>
    </div>
</mat-dialog-content>
