import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'finance-and-resources-overview',
  templateUrl: './finance-and-resources-overview.component.html',
  styleUrls: ['./finance-and-resources-overview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceAndResourcesOverviewComponent implements OnInit {
  @Input() option: any;
  @Input() overviewData: any;
  @Input() dateFilter: any;
  @Input() selectedOrg: number;
  @Output() onOrganizationChange: EventEmitter<any> = new EventEmitter<any>();

  isOrgSuperAdmin: boolean = false;

  selectedOption: string = '';
  selectedDateFilter: string = '';
  dataSource: any = [];
  isIncreased: boolean = false;
  data: any;
  userOrgList: any;
  selectedUserOrg: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const superUserId = localStorage.getItem('superUserId');
    if (superUserId && parseInt(superUserId, 10) === 1) {
      this.isOrgSuperAdmin = true;
    }
    this.userOrgList = JSON.parse(localStorage.getItem('allOrgList'));
    this.selectedUserOrg = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedOrg']) {
      this.selectedUserOrg = this.selectedOrg
    }

    if (changes['overviewData'] || changes['option'] || changes['dateFilter']) {
      this.selectedOption = this.option
      if (this.option === 'finance' && this.overviewData) {
        this.data = [
          this.createDataItem(
            'Bills Total',
            this.overviewData.bill_stats.current_period,
            this.overviewData.bill_stats.previous_period
          ),
          this.createDataItem(
            'Invoice Total',
            this.overviewData.invoice_stats.current_period,
            this.overviewData.invoice_stats.previous_period
          ),
          this.createDataItem(
            'Collections Total',
            this.overviewData.receipt_stats.current_period,
            this.overviewData.receipt_stats.previous_period
          ),
        ];
      } else if (this.option === 'resources' && this.overviewData) {
        this.data = [
          this.createDataItem(
            'Roles',
            this.overviewData.role_stats.current_period,
            this.overviewData.role_stats.previous_period
          ),
          this.createDataItem(
            'Categories',
            this.overviewData.user_category_stats.current_period,
            this.overviewData.user_category_stats.previous_period
          ),
          this.createDataItem(
            'Users',
            this.overviewData.user_stats.current_period,
            this.overviewData.user_stats.previous_period
          ),
        ];
      } else {
        this.data = [];
      }
      this.cdr.markForCheck();
    }
  }

  createDataItem(title: string, current: number, previous: number) {
    const { percentage, isIncreased } = this.calculatePercentageChange(
      current,
      previous
    );
    return {
      title,
      totalCount:
        this.option == 'finance'
          ? this.formatNumber(current, previous)
          : current.toString(),
      percentage,
      isIncreased,
      selectedDateFilter: this.dateFilter,
    };
  }

  calculatePercentageChange(current: number, previous: number) {
    let percentage: any = ((current - previous) / previous) * 100;
    if (percentage === Infinity) {
      return {
        percentage: 100,
        isIncreased: true,
      };
    } else if (!Number.isNaN(percentage) || percentage < 0) {
      return {
        percentage: percentage === 0 ? 0 : Math.abs(percentage).toFixed(2),
        isIncreased: false,
      };
    } else if (!Number.isNaN(percentage) || percentage > 0) {
      return {
        percentage: percentage === 0 ? 0 : percentage.toFixed(2),
        isIncreased: true,
      };
    } else {
      return {
        percentage: 0,
        isIncreased: true,
      };
    }
  }

  formatNumber(current: number, previous: number) {
    let value = current;
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'K';
    }
    return value.toString();
  }

  getIncreasedOrDecreasedPercentage(current: number, previous: number) {
    let percentage: any = ((current - previous) / previous) * 100;
    if (!Number.isNaN(percentage) || percentage < 0) {
      this.isIncreased = false;
      return Math.abs(percentage);
    } else if (!Number.isNaN(percentage) || percentage > 0) {
      this.isIncreased = true;
      return percentage;
    } else {
      this.isIncreased = true;
      return 0;
    }
  }

  handleOrgChange(e: any) {
    const orgId = e.target.value;
    this.selectedUserOrg = orgId
    this.onOrganizationChange.emit({orgId: orgId, option: this.option});
  }
}
