import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToTime'
})
export class MinutesToTimePipe implements PipeTransform {
  transform(minutes: number): string {
    if (isNaN(minutes) || minutes < 0) return '';

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const seconds = 0; // Since we're converting to HH:MM:SS, we'll set seconds to 0

    return `${this.padNumber(hours)}:${this.padNumber(remainingMinutes)}:${this.padNumber(seconds)}`;
  }

  private padNumber(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }
}
