<div *ngIf="isLoading" id="spinner">
  <mat-progress-spinner
    class="mat-spinner-color"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>

<div class="container" style="position: absolute; ">
  <div class="row">
    <div class="col">
      <button
        class="btn ticket_button text-white"
        data-toggle="modal"
        data-target="#createTicketModal"
        onclick="onClickCreateTicket()"
      >
        Create Ticket
      </button>
    </div>
  </div>
</div>
<div class="container">
  <custom-table
    [tableData]="allTickets"
    [tableColumns]="tableColumns"
    hideTableTitle="true"
    [moduleType]="'tickets'"
    [pageType]="'tickets'"
    (rowAction)="onRowAction($event)"
    (rowClick)="onRowClick($event)"
  >
  </custom-table>
</div>


<!-- Modal -->
<div
  class="modal"
  id="createTicketModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="createTicketModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content" style="background-color: #e9ebee">
      <div class="modal-header">
        <h3 class="modal-title">Create Ticket</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="createTicketForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <textarea
              class="form-control"
              id="summary"
              placeholder="Summary"
              rows="3"
              formControlName="summary"
            ></textarea>
            <div
              *ngIf="
                createTicketForm.get('summary').invalid &&
                createTicketForm.get('summary').touched
              "
              class="text-danger"
            >
              Summary is required.
            </div>
          </div>
          <div class="form-group">
            <textarea
              class="form-control"
              id="description"
              rows="3"
              placeholder="Description"
              formControlName="description"
            ></textarea>
          </div>
          <div class="form-group bg-white">
            <input
              type="file"
              class="form-control-file d-none"
              id="file"
              (change)="onFileChange($event)"
              multiple
              accept=".jpg,.jpeg,.png,.pdf"
              formControlName="file"
            />
            <label for="file" class="custom-file-input-wrapper">
              <div>
                <i class="fa fa-cloud-upload file-icon"></i>
              </div>
              <span class="text-black browseFiles">Browse files</span>
            </label>
          </div>

          <div class="row pb-4" *ngIf="selectedFileNames.length > 0">
              <div class="col-lg-12">
                <span class="font-weight-bold">All Attachments</span>
                <div class="d-flex flex-row flex-wrap">
                <ng-container 
                   class="d-flex flex-row gap-2"
                   style="align-items: center;" *ngFor="let file of selectedFileNames">
                  <mat-chip
                    class="mx-2 my-1 py-1 px-2 browseFiles"
                  >
                    {{ file }}
                    <mat-icon class="icon" (click)="remove(file)">close</mat-icon>
                  </mat-chip>
                </ng-container>

                </div>
              </div>
            </div>

          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="highPriority"
              formControlName="highPriority"
            />
            <label class="form-check-label text-black" for="highPriority">
              Mark as high priority
            </label>
          </div>
          <div class="col text-right">
            <button
              type="submit"
              class="btn ticket_button text-white"
              [disabled]="createTicketForm.invalid"
            >
              Create Ticket
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
