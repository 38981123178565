import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilService } from '@app/shared/services/util.service';
import { TableColumn } from '@app/shared/table/TableColumn';
import { UserService } from './ticket.service';

declare var $: any;

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css'],
})
export class TicketComponent implements OnInit {
  isLoading: boolean = false;
  allTickets: any = [];
  requester_id: string | null = null;
  selectedFiles: any[] = [];
  selectedFileNames: string[] = [];
  createTicketForm: FormGroup;

  tableColumns: Array<TableColumn> = [
    {
      name: 'Id',
      dataKey: 'id',
      isSortable: true,
      type: 'text',
    },
    {
      name: 'Summary',
      dataKey: 'subject',
      isSortable: false,
      type: 'text',
    },
    {
      name: 'Priority',
      dataKey: 'priority',
      type: 'text',
      isSortable: true,
    },
    {
      name: 'Status',
      dataKey: 'status',
      type: 'text',
      isSortable: false,
    },
    {
      name: 'Created Date',
      dataKey: 'created_at',
      type: 'text',
      isSortable: true,
    },
  ];

  statusMap: { [key: number]: string } = {
    2: 'Open',
    3: 'Pending',
    4: 'Resolved',
    5: 'Closed',
  };

  priorityMap: { [key: number]: string } = {
    1: 'Low',
    2: 'Medium',
    3: 'High',
    4: 'Urgent',
  };

  constructor(
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder,
    private util: UtilService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const userId = localStorage.getItem('UserId');

    if (userId) {
      this.userService.getUser(userId).subscribe(
        (response: any) => {
          if (!response.length) {
            this.userService.createContact(userId).subscribe(
              (contactResponse: any) => {
                console.log('Contact created successfully:', contactResponse);
                this.requester_id = contactResponse.id;
                localStorage.setItem('requested_user_id', contactResponse.id);
                this.loadTickets();
              },
              (error) => {
                console.error('Error creating contact:', error);
              }
            );
            this.cdr.detectChanges();
          } else {
            const existingUser = response[0];
            this.requester_id = existingUser.id;
            localStorage.setItem('requested_user_id', existingUser.id);
            this.loadTickets();
          }
        },
        (error) => {
          this.isLoading = false;
          console.error('API error', error);
          this.cdr.detectChanges();
        }
      );
    } else {
      console.error('UserId is missing in localStorage');
    }

    this.createTicketForm = this.fb.group({
      summary: ['', Validators.required],
      description: [''],
      highPriority: [false],
      file: [],
    });
  }

  loadTickets(): void {
    if (this.requester_id) {
      this.userService.getTickets(this.requester_id).subscribe(
        (response) => {
          this.allTickets = response.map((ticket: any) => {
            const formattedDate = new Date(ticket.created_at)
              .toISOString()
              .split('T')[0];

            return {
              ...ticket,
              status: this.statusMap[ticket.status],
              priority: this.priorityMap[ticket.priority],
              created_at: formattedDate,
            };
          });
          this.isLoading = false;
          this.cdr.detectChanges();
        },
        (error) => {
          this.isLoading = false;
          console.error('Error loading tickets:', error);
        }
      );
    } else {
      console.error('Requester ID is not set');
    }
  }

  onRowAction(event: { rowName: string; row: any }) {
    console.log('Row action event:', event);
    if (event.rowName === 'rowClick') {
      console.log('Navigating to view ticket:', event.row.id);
      this.router.navigate(['/view-ticket', event.row.id]);
    }
  }

  onRowClick(event: any): void {
    const ticketId = event?.row?.id;
    if (ticketId) {
      this.router.navigate(['/view-ticket', ticketId]);
    }
  }

  onFileChange(event: any): void {
    const files = event.target.files;
    const fileArray = Array.from(files);
    this.selectedFiles.push(...fileArray);
    if (files.length > 0) {
      this.selectedFileNames.push(
        ...Array.from(files).map((file: File) => file.name)
      );
    } else {
      this.selectedFileNames = [];
    }
  }

  onClickCreateTicket(): void {
    this.selectedFileNames = [];
    this.selectedFiles = [];
    $('#createTicketModal').modal('show');
    this.createTicketForm.reset();
  }

  remove(fileName: string): void {
    const index = this.selectedFileNames.indexOf(fileName);
    if (index > -1) {
      this.selectedFileNames.splice(index, 1);
      this.selectedFiles.splice(index, 1);
    }
    this.cdr.detectChanges();
  }


  onSubmit(): void {
    if (this.createTicketForm.valid) {
      const formData = this.createTicketForm.value;
      const priority = formData.highPriority ? '3' : '1';
      this.userService
        .createTicket(
          formData.summary,
          formData.description,
          this.selectedFiles,
          priority,
          this.requester_id
        )
        .subscribe(
          (response) => {
            this.util.simpleDialog({
              title: 'success',
              message: 'Ticket created successfully',
            });
            console.log('Ticket created successfully:', response);
            window.location.reload();
            this.cdr.detectChanges();
          },
          (error) => {
            this.util.simpleDialog({
              title: 'error',
              message: error,
            });
            console.error('Error creating ticket:', error);
            this.cdr.detectChanges();
          }
        );
      this.selectedFileNames = [];
      this.selectedFiles = [];
      $('#createTicketModal').modal('hide');
      this.createTicketForm.reset();
      this.loadTickets();
      this.cdr.detectChanges();
    } else {
      console.error('Form is invalid');
      this.cdr.detectChanges();
    }
  }
}
