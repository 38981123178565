import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  SYSTEM_ACCESS,
  TOKEN_NAME
} from '@app-shared/constants/application-constants';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to modify headers
    let headers = request.headers;

    // Check if custom Authorization header exists
    if (!headers.has('Authorization')) {
      headers = headers.set('Authorization', this.jwt);
    }

    const responseType = request.params.get('responseType');
    const type = request.urlWithParams.search(/type=formdata/) > -1;
    const isPublicApi = this.isPublicApi(request.url);
    request =
      request.method == 'POST' || request.method == 'PUT'
        ? type
          ? request.clone({
              headers: headers.set('Content-type', 'multipart/form-data'),
              body: request.body,
            })
          : request.clone({
              headers: headers,
              body: isPublicApi
                ? request.body
                : {
                    ...request.body,
                    requestHead: {
                      appName: 'Certiplate UI',
                      platform: 'web',
                    },
                  },
            })
        : request.clone({
            headers: headers,
            //@ts-ignore
            responseType: responseType ? String(responseType) : 'json',
          });

    let handled: boolean = false;
    return next.handle(request).pipe(
      retry(1),
      catchError((returnedError) => {
        let errorMessage = null;
        return this.handleServerSideError(returnedError);
      })
    );
  }

  get jwt(): string {
    return `bearer ${localStorage.getItem('JwtToken')}`;
  }

  private isPublicApi(url: string): boolean {
    const publicApiPatterns = ['https://navrititechnologies.freshdesk.com/api/v2/'];
    return publicApiPatterns.some((pattern) => url.includes(pattern));
  }

  private handleServerSideError(returnedError: any): Observable<any> {
    let errorMessage: String;
    if (returnedError.error instanceof ErrorEvent) {
      errorMessage = `Error: ${returnedError.error.message}`;
    } else if (returnedError instanceof HttpErrorResponse) {
      switch (returnedError.status) {
        case 401:
          errorMessage =
            'UnAuthorized Access,Logged out. Please Re-login Again';
          this.clearStorage();
          break;
        case 0:
          errorMessage =
            'Backend API are not Responding, Please try again after sometime';
          break;
        default:
          errorMessage = `Error Status ${returnedError.status}: ${returnedError?.error?.responseHead?.statusCode} - ${returnedError?.error?.responseHead?.statusDesc}`;
      }
    }
    return throwError(errorMessage);
  }

  private clearStorage() {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(SYSTEM_ACCESS);
  }
}
