import { Injectable } from "@angular/core";
import * as XLSX from 'xlsx';
import { URL } from "../constants/service-urls";
import { ApiService } from "./api.service";
import { APIRequest } from "./req-res.types";

@Injectable({
    providedIn: 'root',
})
export class BulkUpload {
    constructor(
    private api: ApiService,
    ) {

    }
    parseExcel(file) {
        var reader = new FileReader();
        reader.onload = (e) => {
            var data = (<any>e.target).result;
            var workbook = XLSX.read(data, {
                type: 'binary'
            });
            workbook.SheetNames.forEach((function (sheetName) {
                // Here is your object
                var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                var json_object = JSON.stringify(XL_row_object);
                console.log(json_object);
            }).bind(this), this);
        };

        reader.onerror = function (ex) {
            console.log(ex);
        };
        reader.readAsBinaryString(file);
    };

    public onSuccess(args: any): void {
        var files = args.target.files; // FileList object
        this.parseExcel(files[0]);
    }

  getGeographicalIDs(pincodes: number[]) {
    const postData: APIRequest = {

     requestBody: {
        pincodes: pincodes,
      },
    };

    return this.api.post(URL.CONTENT.GET_ALL_GEO_IDS, postData );
  }
}