import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'striphtml'
})

export class StripHtmlPipe implements PipeTransform {
    transform(value: any): any {
        value = value + "";
        if(value != null || value != undefined || value != ''){
            return value ? value.replace(/(<([^>]+)>)/ig , "") : value; // replace tags
        }
    }
}