<div class="modal-header text-center">
  <h3 id="hdrImageTitle" class="modal-title mb-3" style="color: black">
    Invoice
  </h3>
  <button
    type="button"
    style="color: black"
    title="Close"
    class="close float-right"
    data-dismiss="modal"
    aria-label="Close"
    mat-dialog-close
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="isLoading" id="spinner">
  <mat-progress-spinner
    class="mat-spinner-color"
    mode="indeterminate"
  ></mat-progress-spinner>
</div>
<div *ngIf="!isLoading">
  <div class="mr-10 mt-2">
    <button
      type="button"
      mat-raised-button
      class="sample-download"
      (click)="download()"
    >
      Download Invoice
    </button>
  </div>
  <div id="pdfTable" #pdfTable>
    <h1 style="padding-top: 3pt; text-indent: 0pt; text-align: center">
      Tax Invoice
    </h1>
    <table style="border-collapse: collapse; margin: auto" cellspacing="0">
      <tr style="height: 25pt; vertical-align: text-top">
        <td
          style="
            width: 200pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
          "
          colspan="3"
          rowspan="4"
        >
          <p
            class="s1"
            style="
              padding-top: 3pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            {{ organizationName }}
          </p>
          <p
            class="s4"
            style="
              padding-left: 1pt;
              text-indent: 0pt;
              line-height: 8pt;
              text-align: left;
            "
          >
            {{ organizationAddress }}
          </p>
          <!-- <p
            class="s4"
            style="padding-left: 1pt;text-indent: 0pt;line-height: 8pt;text-align: left;"
          >
            GSTIN/UIN: 29AADCN9869N1ZC
          </p> -->
          <p
            style="
              padding-left: 1pt;
              padding-right: 74pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            <a href="mailto:finance@navriti.com" class="s4" target="_blank"
              >E-Mail : {{ orgEmail }}</a
            >
          </p>
        </td>
        <td
          style="
            width: 150pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 2pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Invoice No.
          </p>
          <p
            class="s1"
            style="
              padding-left: 1pt;
              text-indent: 0pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            {{ (data.invoice_date | date : "yy") - 1 }}-{{
              data.invoice_date | date : "yy"
            }}/{{ data.customer_name }}/{{ data.id }}
          </p>
        </td>
        <td
          style="
            width: 150pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="3"
        >
          <p
            class="s4"
            style="
              padding-top: 2pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Dated
          </p>
          <p
            class="s1"
            style="
              padding-left: 1pt;
              text-indent: 0pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            {{ data?.invoice_date | date : "dd/MM/yyyy" }}
          </p>
        </td>
      </tr>
      <tr style="height: 24pt; vertical-align: text-top">
        <td
          style="
            width: 110pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Delivery Note
          </p>
        </td>
        <td
          style="
            width: 117pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="3"
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Mode/Terms of Payment
          </p>
          <p
            class="s1"
            style="
              padding-left: 1pt;
              text-indent: 0pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            Immediate
          </p>
        </td>
      </tr>
      <tr style="height: 24pt; vertical-align: text-top">
        <td
          style="
            width: 110pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Dispatch Doc No.
          </p>
        </td>
        <td
          style="
            width: 117pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="3"
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Delivery Note Date
          </p>
        </td>
      </tr>
      <tr style="height: 5pt; vertical-align: text-top">
        <td
          style="
            width: 110pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          rowspan="2"
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Dispatched through
          </p>
        </td>
        <td
          style="
            width: 117pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="3"
          rowspan="2"
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Destination
          </p>
        </td>
      </tr>
      <tr style="height: 19pt; vertical-align: text-top">
        <td
          style="
            width: 224pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
          "
          colspan="3"
          rowspan="4"
        >
          <p class="s4">Buyer (Bill to)</p>
          <p
            class="s1"
            style="
              padding-top: 2pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            {{ data.address_name }}
          </p>
          <p
            class="s4"
            style="
              padding-left: 1pt;
              padding-right: 34pt;
              text-indent: 0pt;
              text-align: justify;
            "
          >
            {{ data.address_line1 }}, {{ data.address_line2 }},
            {{ data.district }}, {{ data.state }} {{ data.pincode }}
          </p>
          <p
            class="s4"
            style="padding-left: 1pt; text-indent: 0pt; text-align: justify"
          >
            State Name : {{ data.state }}
          </p>
        </td>
      </tr>
      <tr style="height: 24pt; vertical-align: text-top">
        <td
          style="
            width: 110pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Vessel/Flight No.
          </p>
        </td>
        <td
          style="
            width: 117pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="3"
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Place of receipt by shipper:
          </p>
        </td>
      </tr>
      <tr style="height: 24pt; vertical-align: text-top">
        <td
          style="
            width: 110pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            City/Port of Loading
          </p>
        </td>
        <td
          style="
            width: 117pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="3"
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            City/Port of Discharge
          </p>
        </td>
      </tr>
      <tr style="height: 80pt; vertical-align: text-top">
        <td
          style="
            width: 227pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="4"
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Terms of Delivery
          </p>
        </td>
      </tr>
      <tr style="height: 30pt">
        <td
          style="
            width: 12pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s5"
            style="
              padding-top: 2pt;
              padding-left: 1pt;
              text-indent: 0pt;
              line-height: 13pt;
              text-align: left;
            "
          >
            Sl No.
          </p>
        </td>
        <td
          style="
            width: 193pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 4pt;
              padding-left: 68pt;
              padding-right: 80pt;
              text-indent: 0pt;
              text-align: center;
            "
          >
            Particulars
          </p>
        </td>
        <td
          style="
            width: 51pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 4pt;
              padding-left: 2pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            HSN/SAC
          </p>
        </td>
        <td
          style="
            width: 49pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 4pt;
              padding-left: 5pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Quantity
          </p>
        </td>
        <td
          style="
            width: 53pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 4pt;
              padding-left: 15pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Rate
          </p>
        </td>
        <td
          style="
            width: 21pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 4pt;
              padding-left: 2pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            per
          </p>
        </td>
        <td
          style="
            width: 76pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 4pt;
              padding-left: 19pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Amount
          </p>
        </td>
      </tr>
      <tr style="height: 275pt; vertical-align: text-top">
        <td
          style="
            width: 12pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s5"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            1
          </p>
        </td>
        <td
          style="
            width: 193pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s6"
            style="
              padding-top: 7pt;
              padding-left: 27pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            {{ data.product_name }}
          </p>
          <!-- <p
            class="s7"
            style="padding-top: 2pt;padding-left: 27pt;text-indent: 0pt;line-height: 130%;text-align: left;"
          >
            Rajgarh Accommodation 75*90*211.8644*70%
          </p>
          <p
            class="s7"
            style="padding-left: 27pt;text-indent: 0pt;text-align: left;"
          >
            1001059.29*30%
          </p> -->
          <p
            class="s7"
            style="
              padding-top: 2pt;
              padding-left: 27pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            {{ data.service }}
          </p>
          <p
            class="s6"
            style="
              padding-top: 1pt;
              padding-right: 9pt;
              text-indent: 0pt;
              text-align: right;
            "
            *ngIf="data.igst_percent"
          >
            IGST {{ data.igst_percent }}%
          </p>
          <p
            class="s6"
            style="
              padding-top: 1pt;
              padding-right: 9pt;
              text-indent: 0pt;
              text-align: right;
            "
            *ngIf="data.cgst_percent"
          >
            CGST {{ data.cgst_percent }}%
          </p>
          <p
            class="s6"
            style="
              padding-top: 1pt;
              padding-right: 9pt;
              text-indent: 0pt;
              text-align: right;
            "
            *ngIf="data.sgst_percent"
          >
            SGST {{ data.sgst_percent }}%
          </p>
          <p
            class="s6"
            style="
              padding-top: 1pt;
              padding-left: 27pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Round Off
          </p>
        </td>
        <td
          style="
            width: 51pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="padding-top: 7pt; text-indent: 0pt; text-align: left"
          >
            <br />
          </p>
        </td>
        <td
          style="
            width: 49pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p style="text-indent: 0pt; text-align: center">{{ data.units }}</p>
        </td>
        <td
          style="
            width: 53pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p style="text-indent: 0pt; text-align: center">
            {{ data.unit_rate }}
          </p>
        </td>
        <td
          style="
            width: 21pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p style="text-indent: 0pt; text-align: left"><br /></p>
        </td>
        <td
          style="
            width: 76pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s1"
            style="
              padding-top: 6pt;
              padding-right: 9pt;
              text-indent: 0pt;
              text-align: right;
            "
          >
            {{ data.total_amount | currency : "INR" }}
          </p>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <p
            class="s1"
            style="padding-right: 7pt; text-indent: 0pt; text-align: right"
            *ngIf="data.igst_percent"
          >
            {{
              (data.total_amount * data.igst_percent) / 100 | currency : "INR"
            }}
          </p>
          <p
            class="s1"
            style="padding-right: 7pt; text-indent: 0pt; text-align: right"
            *ngIf="data.cgst_percent"
          >
            {{
              (data.total_amount * data.cgst_percent) / 100 | currency : "INR"
            }}
          </p>
          <p
            class="s1"
            style="padding-right: 7pt; text-indent: 0pt; text-align: right"
            *ngIf="data.sgst_percent"
          >
            {{
              (data.total_amount * data.sgst_percent) / 100 | currency : "INR"
            }}
          </p>
        </td>
      </tr>
      <tr style="height: 16pt">
        <td
          style="
            width: 12pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p style="text-indent: 0pt; text-align: left"><br /></p>
        </td>
        <td
          style="
            width: 193pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s8"
            style="
              padding-top: 3pt;
              padding-right: 6pt;
              text-indent: 0pt;
              text-align: right;
            "
          >
            Total
          </p>
        </td>
        <td
          style="
            width: 51pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p style="text-indent: 0pt; text-align: left"><br /></p>
        </td>
        <td
          style="
            width: 49pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p style="text-indent: 0pt; text-align: left"><br /></p>
        </td>
        <td
          style="
            width: 53pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p style="text-indent: 0pt; text-align: left"><br /></p>
        </td>
        <td
          style="
            width: 21pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p style="text-indent: 0pt; text-align: left"><br /></p>
        </td>
        <td
          style="
            width: 76pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s9"
            style="padding-top: 2pt; text-indent: 0pt; text-align: center"
          >
            <b>{{ data.invoice_value | currency : "INR" }}</b>
          </p>
        </td>
      </tr>
      <tr style="height: 29pt">
        <td
          style="
            width: 455pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="7"
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Amount Chargeable (in words) <i>E. &amp; O.E</i>
          </p>
          <p
            class="s1"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            INR {{ toWords.convert(data.invoice_value) }}
          </p>
        </td>
      </tr>
      <tr style="height: 14pt">
        <td
          style="
            width: 200pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="2"
          rowspan="2"
        >
          <p
            class="s4"
            style="
              padding-top: 2pt;
              padding-left: 96pt;
              padding-right: 106pt;
              text-indent: 0pt;
              text-align: center;
            "
          >
            HSN/SAC
          </p>
        </td>
        <td
          style="
            width: 0pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          rowspan="2"
        >
          <p
            class="s4"
            style="
              padding-top: 2pt;
              padding-right: 16pt;
              text-indent: 0pt;
              text-align: right;
            "
          >
            Taxable Value
          </p>
        </td>
        <td
          style="
            width: 90pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="3"
        >
          <p
            class="s4"
            style="
              padding-top: 2pt;
              padding-left: 13pt;
              text-indent: 0pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            Integrated Tax
          </p>
        </td>
        <td
          style="
            width: 60pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          rowspan="2"
        >
          <p
            class="s4"
            style="
              padding-top: 2pt;
              padding-left: 2pt;
              padding-right: 5pt;
              text-indent: 0pt;
              text-align: center;
            "
          >
            Total
          </p>
          <p
            class="s8"
            style="
              padding-top: 2pt;
              padding-left: 2pt;
              padding-right: 9pt;
              text-indent: 0pt;
              text-align: center;
            "
          >
            Tax Amount
          </p>
        </td>
      </tr>
      <tr style="height: 12pt">
        <td
          style="
            width: 33pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s8"
            style="
              padding-top: 1pt;
              padding-left: 4pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Rate
          </p>
        </td>
        <td
          style="
            width: 57pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="2"
        >
          <p
            class="s8"
            style="
              padding-top: 1pt;
              padding-left: 11pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Amount
          </p>
        </td>
      </tr>
      <tr style="height: 11pt">
        <td
          style="
            width: 245pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="2"
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 1pt;
              text-indent: 0pt;
              line-height: 8pt;
              text-align: left;
            "
          >
            <br />
          </p>
        </td>
        <td
          style="
            width: 60pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 8pt;
              text-indent: 0pt;
              line-height: 8pt;
              text-align: left;
            "
          >
            {{ data.total_amount }}
          </p>
        </td>
        <td
          style="
            width: 33pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 12pt;
              text-indent: 0pt;
              line-height: 8pt;
              text-align: left;
            "
          >
            {{ data.igst_percent + data.cgst_percent + data.sgst_percent }}%
          </p>
        </td>
        <td
          style="
            width: 57pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="2"
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-left: 14pt;
              text-indent: 0pt;
              line-height: 8pt;
              text-align: left;
            "
          >
            {{
              (data.total_amount *
                (data.igst_percent + data.cgst_percent + data.sgst_percent)) /
                100 | currency : "&nbsp;"
            }}
          </p>
        </td>
        <td
          style="
            width: 60pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s4"
            style="
              padding-top: 1pt;
              padding-right: 6pt;
              text-indent: 0pt;
              line-height: 8pt;
              text-align: right;
            "
          >
            {{
              (data.total_amount *
                (data.igst_percent + data.cgst_percent + data.sgst_percent)) /
                100 | currency : "&nbsp;"
            }}
          </p>
        </td>
      </tr>
      <tr style="height: 15pt">
        <td
          style="
            width: 245pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="2"
        >
          <p
            class="s12"
            style="
              padding-top: 2pt;
              padding-right: 4pt;
              text-indent: 0pt;
              text-align: right;
            "
          >
            Total
          </p>
        </td>
        <td
          style="
            width: 60pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s12"
            style="
              padding-top: 2pt;
              padding-left: 8pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            {{ data.total_amount | currency : "&nbsp;" }}
          </p>
        </td>
        <td
          style="
            width: 33pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p style="text-indent: 0pt; text-align: left"><br /></p>
        </td>
        <td
          style="
            width: 57pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="2"
        >
          <p
            class="s12"
            style="
              padding-top: 2pt;
              padding-left: 14pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            {{
              (data.total_amount *
                (data.igst_percent + data.cgst_percent + data.sgst_percent)) /
                100 | currency : "&nbsp;"
            }}
          </p>
        </td>
        <td
          style="
            width: 60pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
        >
          <p
            class="s12"
            style="
              padding-top: 2pt;
              padding-right: 6pt;
              text-indent: 0pt;
              text-align: right;
            "
          >
            {{
              (data.total_amount *
                (data.igst_percent + data.cgst_percent + data.sgst_percent)) /
                100 | currency : "&nbsp;"
            }}
          </p>
        </td>
      </tr>
      <tr style="height: 92pt">
        <td
          style="
            width: 455pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="7"
        >
          <p
            class="s4"
            style="
              padding-top: 5pt;
              padding-left: 1pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            Tax Amount (in words) :
            <span class="s1"
              >INR
              {{
                toWords.convert(
                  (data.total_amount *
                    (data.igst_percent +
                      data.cgst_percent +
                      data.sgst_percent)) /
                    100
                )
              }}</span
            >
          </p>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <p
            class="s11"
            style="padding-left: 1pt; text-indent: 0pt; text-align: left"
          >
            Remarks:
          </p>
          <p
            class="s4"
            style="
              padding-left: 1pt;
              padding-right: 239pt;
              text-indent: 0pt;
              line-height: 106%;
              text-align: left;
            "
          >
            <br />
          </p>
        </td>
      </tr>
      <tr style="height: 42pt">
        <td
          style="
            width: 228pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="3"
        >
          <p style="text-indent: 0pt; text-align: left"><br /></p>
        </td>
        <td
          style="
            width: 227pt;
            border-top-style: solid;
            border-top-width: 1pt;
            border-left-style: solid;
            border-left-width: 1pt;
            border-bottom-style: solid;
            border-bottom-width: 1pt;
            border-right-style: solid;
            border-right-width: 1pt;
          "
          colspan="4"
        >
          <p
            class="s6"
            style="
              padding-top: 1pt;
              padding-left: 98pt;
              text-indent: 0pt;
              text-align: left;
            "
          >
            for {{ organizationName }}
          </p>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <p
            class="s4"
            style="
              padding-top: 7pt;
              padding-left: 148pt;
              text-indent: 0pt;
              line-height: 10pt;
              text-align: left;
            "
          >
            Authorised Signatory
          </p>
        </td>
      </tr>
    </table>
    <p style="padding-top: 6pt; text-indent: 0pt; text-align: center">
      This is a Computer Generated Invoice
    </p>
  </div>
</div>
