import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UtilService } from '@app-shared/services/util.service';
import { AuthguardServiceService } from '@app/auth/authguard-service.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authSrvc: AuthguardServiceService,
    private util: UtilService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isPageAccessExist = this.authSrvc.checkPageAccess(state.url);

    if (localStorage.getItem('JwtToken')) {
      if (!isPageAccessExist) {
        const serviceError = this.util.getIntlErrorMessage(
          'INVALID_REQUEST',
          '1007'
        );
        this.util.simpleDialog({
          title: 'Error',
          message: serviceError,
        });
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    }
    const serviceError = this.util.getIntlErrorMessage(
      'UNAUTHORIZED_REQUEST',
      '1007'
    );
    this.util.simpleDialog({
      title: 'Error',
      message: serviceError,
    });
    this.router.navigate(['/login']);
    return false;
  }
}
