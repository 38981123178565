import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AppNotificationsService } from '../../services/app-notifications.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { UtilService } from '../../services/util.service';
import {
  goToAll,
  recentSearches,
  searchResults,
  searchResultsAll,
} from '../../constants/application-constants';
import { SocketService } from '@app/socket.service';
import { SearchService } from '@app/search/search.service';
import { convertObjectToArray } from '@app/operation/project/project.constants';

@Component({
  selector: 'app-globalsearch',
  templateUrl: './globalsearch.component.html',
  styleUrls: ['./globalsearch.component.css'],
  exportAs: 'globalSearchComponent',
  encapsulation: ViewEncapsulation.None,
})
export class GlobalsearchComponent implements OnInit {
  private readonly notificationService: AppNotificationsService;
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  hoverActive: boolean = false;
  itemIndex = -1;
  itemKey = '';
  // @Input()  searchInput!: string;
  searchInput: string = '';
  @Output() searchInputChange = new EventEmitter<number>();
  recentSearches = [];
  searchResults = [];
  allRecent = [];
  allResults = 0;
  isLoading = false;

  constructor(
    service: AppNotificationsService,
    private util: UtilService,
    private socketService: SocketService,
    private notificationsService: AppNotificationsService,
    private changeDetectorRef: ChangeDetectorRef,
    private searchSrvc: SearchService
  ) {
    this.notificationService = service;
    this.notificationService.menuEmitter.subscribe(this.toggleMenu.bind(this));
  }

  ngOnInit() {
    this.getRecentSearches();
  }

  getRecentSearches() {
    this.searchSrvc.getRecentSearches().subscribe(
      (data: any) => {
        this.recentSearches = data?.body?.responseBody;
        this.allRecent = this.recentSearches;
        this.changeDetectorRef.markForCheck();
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  toggleMenu(): void {
    if (this.searchInput?.length >= 3) {
      this.searchResults = searchResults;
      this.recentSearches = this.recentSearches.slice(0, 3);
    } else {
      this.recentSearches = this.allRecent;
    }
  }

  onSearchInputChange() {
    this.isLoading = true;
    if (this.searchInput.length >= 3) {
      this.searchSrvc.getAutoSuggestions(this.searchInput).subscribe(
        (data: any) => {
          this.searchResults = data?.body?.responseBody;
          this.allResults = this.searchResults.length;
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        },
        (err: any) => {
          this.isLoading = false;
          this.util.errorNotification(err);
          this.changeDetectorRef.markForCheck();
        }
      );
    }
    this.notificationsService.open();
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }
}