import { ChangeDetectionStrategy, Component, OnInit,  Input, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
import { Chart } from 'highcharts';

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-piechart-shared',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css']
})
export class PiechartSharedComponent implements OnInit, OnChanges {

  Highcharts = Highcharts;
  chartOptions = {};
  @Input() data: any;
  updateFlag = false;
  initialized = false;


  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.chartOptions = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          events: {
            load () {
              const sum = this.series[0].data
              .reduce((acc, p) => { acc += p.y; return acc }, 0)
              this.series[0].addPoint({ name: 'Unselected', y: 100 - sum, color: 'transparent' })
            }
          }
      },
      title: {
        text:''
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
              }
          }
      },
      exporting:{
        enabled:true,
      },
      credits:{
        enabled:false,
      },
      series: [{
          name: 'BFSI',
          colorByPoint: true,
          data: this.data
      }]
  };
  this.initialized = true;

    HC_exporting(Highcharts);
    HC_exportData(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateFlag = false;
    if (changes.data && this.initialized) {
      // Handle the change here
      //@ts-ignore
      this.chartOptions.series[0].data = this.data;
      this.updateFlag = true;
      this.cdr.markForCheck();
      this.cdr.detectChanges();
    }
  }

}
