import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import { UtilService } from '@app/shared/services/util.service';
import { URL } from '@app-shared/constants/service-urls';
import { GLOBAL, VIEW } from '@app/shared/constants/application-constants';
import { APIRequest } from '@app/shared/services/req-res.types';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private api: ApiService, private util: UtilService) {}

  getRecentSearches() {
    const headers = {
      'x-operation': VIEW,
      'x-referer': GLOBAL,
    };
    return this.api.get(URL.SEARCH.RECENT_SEARCHES, undefined, headers);
  }

  getAutoSuggestions(searchKey: any) {
    const headers = {
      'x-operation': VIEW,
      'x-referer': GLOBAL,
    };
    return this.api.get(
      `${URL.SEARCH.SEARCH_SUGGESTIONS}?search=${searchKey}`,
      undefined,
      headers
    );
  }

  postSearch(data: any) {
    const headers = {
      'x-operation': VIEW,
      'x-referer': GLOBAL,
    };

    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };
    return this.api.post(URL.SEARCH.SEARCH, postData, headers);
  }
}
