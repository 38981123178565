import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SYSTEM_ACCESS, TOKEN_NAME } from '@app-shared/constants/application-constants';
import { User } from '@app/auth/user';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(private router: Router) { }

  login(user: User) {
    if (user.username !== '' && user.password !== '' ) {
      this.loggedIn.next(true);
      this.router.navigate(['/']);
    }
  }

  logout() {
    this.clearStorage();
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  clearStorage() {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(SYSTEM_ACCESS);
  }
}
