import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-addassessor',
  templateUrl: './addassessor.component.html',
  styleUrls: ['./addassessor.component.css']
})
export class AddassessorComponent implements OnInit {
  dropdownList = [{Id:'1',Name:'Andhra Pradesh'},
  {Id:'2',Name:'Bihar'},
  {Id:'3',Name:'Chattisgarh'},
  {Id:'4',Name:'Delhi'},
  {Id:'5',Name:'Goa'},
  {Id:'6',Name:'Himachal Pradesh'},
  {Id:'7',Name:'Jharkhand'},
  {Id:'8',Name:'Karnataka'}
];
  selectedItems='0';

  constructor() { }

  ngOnInit(): void {
  }

}
