import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '@app/shared/services/util.service';
import * as moment from 'moment';
import { TicketService } from './viewTicket.service';

@Component({
  selector: 'app-ticket',
  templateUrl: './viewTicket.component.html',
  styleUrls: ['./viewTicket.component.css'],
})
export class ViewTicketComponent implements OnInit {
  isLoading: boolean = false;
  ticketId: number | null = null;
  ticketData: any = {};
  comment: string = '';
  requesterId = localStorage.getItem('requested_user_id') || '';
  username = localStorage.getItem('username');
  status: string = '';
  priority: string = '';

  constructor(
    private route: ActivatedRoute,
    private ticketService: TicketService,
    private util: UtilService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.paramMap.subscribe((params) => {
      this.ticketId = +params.get('id');
      if (this.ticketId) {
        this.fetchTicketData(this.ticketId);
      }
    });
  }

  getFormattedDate(dateString: string): string {
    return moment(dateString).format('YYYY-MM-DD hh:mm A');
  }

  fetchTicketData(id: number): void {
    this.ticketService.getTicketData(id).subscribe(
      (data) => {
        this.isLoading = false;
        this.ticketData = data;
        if (this.ticketData && this.ticketData.status) {
          const status = this.ticketData.status;
          this.status =
            status === 2
              ? 'Open'
              : status === 3
              ? 'Pending'
                : status === 4
                  ? 'Resolved'
                  : 'Closed'
        }
        if (this.ticketData && this.ticketData.priority) {
          const priority = this.ticketData.priority;
          this.priority =
            priority === 1
              ? 'Low'
              : priority === 2
              ? 'Medium'
                : priority === 3
                  ? 'High'
                  : 'Urgent'
        }
        this.cdr.detectChanges();
      },
      (error) => {
        this.isLoading = false;
        console.error('Error fetching ticket data', error);
        this.cdr.detectChanges();
      }
    );
  }

  onDownloadClick = (url: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.target = '_blank';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  onCommentChange(event: any): void {
    this.comment = event.target.value;
  }

  sendComment() {
    this.isLoading = true;
    if (this.comment.trim()) {
      this.ticketService
        .sendComment(this.comment, this.ticketData.id)
        .subscribe(
          (response) => {
            this.isLoading = false;
            this.util.simpleDialog({
              title: 'success',
              message: 'Comment added successfully',
            });
            this.comment = '';
            this.fetchTicketData(this.ticketId);
            this.cdr.detectChanges();
          },
          (error) => {
            this.isLoading = false;
            console.error('Error sending comment:', error);
            this.util.simpleDialog({
              title: 'error',
              message: error,
            });
            this.cdr.detectChanges();
          }
        );
    }
  }

  closeTicket(): void {
    this.isLoading = true;
    this.ticketService.closeTicket(this.ticketData.id).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.util.simpleDialog({
          title: 'success',
          message: 'Ticket closed successfully',
        });
        this.router.navigate(['/tickets']);
        this.cdr.detectChanges();
      },
      error: (error) => {
        console.error('Error closing ticket', error);
        this.isLoading = false;
        this.util.simpleDialog({
          title: 'error',
          message: error,
        });
        this.cdr.detectChanges();
      },
    });
  }
}
