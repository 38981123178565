import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from '@app/auth/auth.service';
import { NavbarService } from '@app/navbar/navbar.service';
import { getXReferer } from '@app/operation/project/project.constants';
import { HEADER_X_REFFERER } from '@app/shared/constants/application-constants';
import { Observable } from 'rxjs';

var username:any
 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  isLoggedIn$: Observable<boolean>;
  username:any

  constructor(private route: Router,private authService: AuthService, public nav: NavbarService) { }

  ngOnInit(): void {
    const x_referer = getXReferer(this.route.url)
    localStorage.setItem(HEADER_X_REFFERER, x_referer);
    this.isLoggedIn$ = this.authService.isLoggedIn;

    $( ".nav-item" ).on( "click", function() {
      //event.preventDefault();
      var clickedItem = $(this);
      $( ".nav-item" ).each( function() {
          $(this).removeClass( "active" );
      });
      clickedItem.addClass( "active" );
      
  });
  }

  ngOnDestroy(): void {
    localStorage.setItem(HEADER_X_REFFERER, '');
  }


  navbarLogic(username:any){
    if(username=='admin'){
      var navbar1 = document.getElementById('adminNav');
      navbar1.style.display = 'flex';
    }
    else if(username=='sales'){
      var navbar2 = document.getElementById('salesNav');
      navbar2.style.display = 'flex';
    }

  }

  onLogout() {
    this.authService.logout();
  }

  logout(){
    localStorage.clear();
    this.route.navigate(['/']);
  }

}
