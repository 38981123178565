import { ChangeDetectionStrategy, Component, OnInit,  Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css']
})
export class PiechartComponent implements OnInit {

  Highcharts = Highcharts;
  chartOptions = {};


  constructor() { }

  ngOnInit(): void {
    this.chartOptions = {
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
      },
      title: {
          text: 'REVENUE GENERATED BY EACH SECTOR'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
              }
          }
      },
      exporting:{
        enabled:true,
      },
      credits:{
        enabled:false,
      },
      series: [{
          name: 'BFSI',
          colorByPoint: true,
          data: [{
              name: 'Electronics',
              y: 61.41,
              sliced: true,
              selected: true
          }, {
              name: 'Power',
              y: 11.84
          }, {
              name: 'Apparel',
              y: 10.85
          }, {
              name: 'Retail',
              y: 4.67
          }, {
              name: 'Telecom',
              y: 4.18
          }, {
              name: 'Construction',
              y: 1.64
          }, {
              name: 'Sambhav',
              y: 1.6
          }, {
              name: 'Labournet',
              y: 1.2
          }, {
              name: 'Corporate',
              y: 2.61
          }]
      }]
  };

    HC_exporting(Highcharts);
    HC_exportData(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

}
