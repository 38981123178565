<div class="container">
  <div class="row px-4 pt-4 pb-2">
    <div class="col-lg-4 font-weight-bold header">Notify me when</div>
    <div class="col-lg-7 font-weight-bold">
      <div class="d-flex">
        <div class="px-5" matTooltip="Receive notifications through email">
          <mat-icon class="mr-2"> email</mat-icon>Email
        </div>
        <div class="px-5" matTooltip="Receive notifications through sms">
          <mat-icon class="mr-2"> message</mat-icon>SMS
        </div>
        <div
          class="px-5"
          matTooltip="Receive push notifications through browser"
        >
          <mat-icon class="mr-2">devices_other</mat-icon>Push
        </div>
        <div class="px-5" matTooltip="Receive notifications in the application">
          <mat-icon class="mr-2">notifications</mat-icon>In-App
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div *ngIf="preferencesList?.length">
    <div *ngFor="let item of preferencesList">
      <div class="row px-4">
        <div class="col-lg-4">
          <div class="d-flex flex-column">
            <div class="p-2 font-weight-bold">{{ item.service }}</div>
            <div
              class="p-2 ml-4"
              *ngFor="let subtask of item.preferences[0].subtasks"
            >
              {{ subtask.description }}
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="d-flex justify-content-start">
            <div *ngFor="let subItem of item.preferences">
              <section class="example-section">
                <span class="example-list-section">
                  <mat-checkbox
                    class="checkbox-section"
                    [checked]="allComplete[item.service][subItem.id]"
                    [indeterminate]="someComplete(item.service, subItem.id)"
                    (change)="setAll($event.checked, item.service, subItem)"
                  >
                  </mat-checkbox>
                </span>
                <span class="example-list-section">
                  <ul>
                    <li *ngFor="let subtask of subItem.subtasks">
                      <mat-checkbox
                        class="checkbox-section"
                        [(ngModel)]="subtask.completed"
                        (ngModelChange)="updateAllComplete(subItem.id, subtask)"
                      >
                      </mat-checkbox>
                    </li>
                  </ul>
                </span>
              </section>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</div>
