import { ChangeDetectionStrategy, Component, OnInit,  Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  @Input() label: string;
  @Input() total: string;
  @Input() percentage: string;
  @Input() data = [];

  Highcharts = Highcharts;
  chartOptions = {};


  constructor() { }

  ngOnInit(): void {
    this.chartOptions = {
      chart: {
          type: 'area',
          backgroundColor:null,
          borderWidth:0,
          margin:[2,2,2,2],
          height:40,
      },
      title: {
          text: null
      },
      subtitle: {
          text: null
      },
      credits : {
        enabled : false,
      },

      tooltip: {
          split: true,
          outside:true,
      },
      legend:{
        enabled:false,
      },
      xAxis:{
        labels:{
          enabled:false,
        },
        title:{
          text:null
        },
        startOnTick:false,
        endOnTIck:false,
        tickOptions:{

        }
      },

      yAxis:{
        labels:{
          enabled:false,
        },
        title:{
          text:null
        },
        startOnTick:false,
        endOnTIck:false,
        tickOptions:{
          
        }
      },
      exporting:{
        enabled:false,
      },
      series: [{
          data : [71,78,39, 66]
      }]
    }

    HC_exporting(Highcharts);
    HC_exportData(Highcharts);

    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

}
