import { Injectable } from '@angular/core';
import { UtilService } from '@app-shared/services/util.service';
import { AdminService } from '@app/admin/admin.service';
import { MENU_ACCESS } from '@app/shared/constants/application-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthguardServiceService {
  menuAccessList = localStorage.getItem(MENU_ACCESS);
  menuAccess = this.menuAccessList
    ? JSON.parse(this.util.decrypt(this.menuAccessList))
    : [];

  constructor(private util: UtilService, private adminSrvc: AdminService) {}

  gettoken() {
    return !!localStorage.getItem('username');
  }

  isEquivalent(value1: string, value2: string) {
    return value1.toLowerCase() === value2.toLowerCase();
  }

  checkAdminAccess() {
    const userRole = this.adminSrvc.userRole;
    const isOrgSuperAdmin = JSON.parse(localStorage.getItem('isOrgSuperAdmin'));
    if (userRole === 'SA' || isOrgSuperAdmin) {
      return true;
    }
    return false;
  }

  checkPageAccess(pageUrl: string) {
    let status = true;
    const [initial, menu, page] = pageUrl.split('/');
    if (menu) {
      if (menu.toLowerCase() === 'account') {
        return true;
      }
      if (menu.toLowerCase() === 'admin') {
        return this.checkAdminAccess();
      }
      const isMenuExist = this.menuAccess.find((ele: any) =>
        this.isEquivalent(ele.menu_name, menu)
      );
      if (!isMenuExist) {
        return false;
      }
      if (page) {
        if (!isMenuExist.pages || !isMenuExist.pages.length) {
          return false;
        }
        const isPageExist = isMenuExist.pages.find((ele: any) =>
          this.isEquivalent(ele.url, `/${menu}/${page}`)
        );
        if (!isPageExist) {
          const isInnerPresent = isMenuExist.pages.find(
            (ele: any) => ele.url === '/#' && !!ele.pages
          );
          if (!isInnerPresent) return false;
          const isInnerPagePresent = isInnerPresent.pages.find((ele: any) =>
            this.isEquivalent(ele.url, `/${menu}/${page}`)
          );
          if (!isInnerPagePresent) return false;
        }
      }
    }
    return status;
  }
}
