<!----------------------------------ADMIN NAVIGATION------------------------------------>
<nav *ngIf="nav.homevisible" class="navbar navbar-expand-lg" style="background-color: #436ca1;">
    <a class="navbar-brand" href="https://navriti.com/">
      <img src="assets/images/certlogo.png">
    </a>

    <div class="container">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="['/Home/Home']">Home <span class="sr-only">(current)</span></a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="['/Sales/Customer']">Sales</a>
        </li>-->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Sales
          </a>
          <div class="dropdown-menu fade-up" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#" [routerLink]="['/Sales/Home']">Home</a>
            <a class="dropdown-item" href="#" [routerLink]="['/Sales/Customer']">Customer</a>
            <a class="dropdown-item" href="#" [routerLink]="['/Sales/Scheme']">Scheme</a>
            <a class="dropdown-item" href="#" [routerLink]="['/Sales/Project']">Project</a>
            <a class="dropdown-item" href="#" [routerLink]="['/Sales/Report']">Report</a>
          </div>
        </li>

        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Content
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#" [routerLink]="['/Content/Courses']">Courses</a>
              <a class="dropdown-item" href="#" [routerLink]="['/Content/QuestionBank']">Question Bank</a>
              <a class="dropdown-item" href="#" [routerLink]="['/Content/Outline']">Outline</a>
              <a class="dropdown-item" href="#" [routerLink]="['/Content/QuestionPaper']">Question Paper</a>
              <a class="dropdown-item" href="#" [routerLink]="['/Content/Report']">Report</a>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Logistics
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Assessor</a>
              <a class="dropdown-item" href="#">Proctor</a>
              <a class="dropdown-item" href="#">Assets</a>
              <a class="dropdown-item" href="#">Report</a>
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="#" [routerLink]="['/Home']">Resources <span class="sr-only">(current)</span></a>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Operations
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Home</a>
              <a class="dropdown-item" href="#">Request</a>
              <a class="dropdown-item" href="#">Plan</a>
              <a class="dropdown-item" href="#">Execute</a>
              <a class="dropdown-item" href="#">Publish</a>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Finance
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Invoice</a>
              <a class="dropdown-item" href="#">Billing</a>
              <a class="dropdown-item" href="#">Report</a>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Compliance
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">ISO Trackers</a>
              <a class="dropdown-item" href="#">Analytics</a>
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="#" [routerLink]="['/Home']">Ticketing <span class="sr-only">(current)</span></a>
          </li>
        
      </ul>
    </div>

    
      <span class="navbar-text">
        <mat-icon style="margin-right: 5px;">person</mat-icon>
      </span>
      <span class="navbar-text">
        <a href="#" style="margin-right: 25px;" >Welcome Mayuri</a>
      </span>

      <span class="navbar-text">
        <mat-icon >power_settings_new</mat-icon>
      </span>
      <span class="navbar-text">
        <a [routerLink]="['/']" (click)="logout()" >Logout</a>
      </span>
      
    </div>

</nav>


<!---------------------------------SALES NAVIGATION----------------------------------->
<nav *ngIf="nav.salesvisible"  class="navbar navbar-expand-lg navbar-dark bg-dark">
    
  <a class="navbar-brand" href="#">
    <img src="assets/images/certlogo.png">
  </a>

  <div class="container">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" href="#" [routerLink]="['/Sales/Home']">Home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" [routerLink]="['/Sales/Customer']">Customer</a>
      </li>
      <!--<li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Dropdown
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li>-->
      <li class="nav-item">
          <a class="nav-link"  href="#" [routerLink]="['/Sales/Scheme']">Scheme</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"  href="#" [routerLink]="['/Sales/Project']">Project</a>
        </li>
        <li class="nav-item">
          <a class="nav-link"  href="#" [routerLink]="['/Sales/Report']">Report</a>
        </li>
    </ul>
  </div>

  
    <span class="navbar-text">
      <mat-icon style="margin-right: 5px;">person</mat-icon>
    </span>
    <span class="navbar-text">
      <a href="#" >Welcome Mayuri</a>
    </span>
    
  </div>

</nav>