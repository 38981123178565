import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { AccountService } from '@app-account/account.service';
import { CustomFormValidator } from '@app-shared/components/custom-validators';
import { UtilService } from '@app-shared/services/util.service';

import { HeaderComponent } from '@app/header/header.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.dirty && control.touched && control.invalid);
    const invalidParent = !!(control && control.dirty && control.touched && control.parent.touched && control.parent.dirty && control.parent.invalid);
    return (invalidCtrl || invalidParent);
  }
}

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild(HeaderComponent) headerChild: HeaderComponent;
  cpForm: UntypedFormGroup;
  serviceError: string;
  successMsg: string;
  showProgress: boolean;
  matcher1 = new MyErrorStateMatcher();
  matcher2 = new MyErrorStateMatcher();

  constructor(
    private service: AccountService,
    private util: UtilService,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.showProgress = false;
    this.cpForm = this.fb.group({
      currentPassword: ['', [Validators.required, CustomFormValidator.passwordStrengthChecker]],
      newPassword: ['', [Validators.required, CustomFormValidator.passwordStrengthChecker]],
      confirmPassword: ['', [Validators.required, CustomFormValidator.passwordStrengthChecker]],
    }, {
      validators: [
        CustomFormValidator.shouldNotMatchFieldValidator('currentPassword', 'newPassword'),
        CustomFormValidator.matchFieldValidator('newPassword', 'confirmPassword')
      ]
    });
  }

  update(){
    this.showProgress = true;
    this.serviceError = '';
    const inputs = this.cpForm.value;
    let resp = this.service.changePassword(inputs.currentPassword, inputs.newPassword);
    resp.subscribe(
      (data:any) => {
        this.showProgress = false;
        this.successMsg = this.util.getIntlErrorMessage("CHANGE_PWD", data.body.responseHead.statusCode, data.body.responseHead.statusDesc);
        this.cpForm.reset();
        this.util.simpleDialog({
          title: 'Added',
          message: this.successMsg,
        });
        this.headerChild.logout();
        this.util.showLoader = false;
      }, (err: any) => {
        this.showProgress = false;
        if (err.responseHead){
          this.serviceError = this.util.getIntlErrorMessage("CHANGE_PWD", err.responseHead.statusCode, err.responseHead.statusDesc);
        }
      });
  }

}
