import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogService } from '@app-admin/dialogs/dialog.service';
import { CustomFormValidator } from '@app-shared/components/custom-validators';
import { UtilService } from '@app-shared/services/util.service';
import { GetService } from '@app/shared/table/get.service';
import { GetAllService } from '@app/shared/table/getAll.service';

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css']
})
export class AddAdminComponent implements OnInit {

  adminForm: UntypedFormGroup;
  pageType: string;
  allStateNames: string[];
  districts: string[];
  protected allStates = {};
  serviceError: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<AddAdminComponent>,
    private fb: UntypedFormBuilder,
    private util: UtilService,
    private dialogSrvc: DialogService,
    private cdr: ChangeDetectorRef,
    private getAllService: GetAllService,
    private getService: GetService) { }

  ngOnInit(): void {
    this.pageType = 'id' in this.modalData ? 'Edit' : 'Add';

    const controls = {
      name: [this.pageType === 'Edit' ? this.modalData.full_name : '', [Validators.required]],
      email: [this.pageType === 'Edit' ? this.modalData.email : '', [
        Validators.required,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]
      ],
      alternate_email: [this.pageType === 'Edit' ? this.modalData.alternate_email : '', [
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]
      ],
      phone: [this.pageType === 'Edit' ? this.modalData.phone : '', [Validators.required]],
      alternate_phone: [this.pageType === 'Edit' ? this.modalData.alternate_phone : '', []],
      designation: [this.pageType === 'Edit' ? this.modalData.designation : '', [Validators.required]],
      havePinCode: [this.modalData.pincode_id ? 'true' : 'false'],
      pinCode: [this.pageType === 'Edit' ? this.modalData.pin_code : ''],
      state: ['', [Validators.required]],
      district: [{value:'', disabled: true}, [Validators.required]],
      pincode_id: [this.pageType === 'Edit' ? this.modalData.pincode_id : ''],
      geography_id: [this.pageType === 'Edit' ? this.modalData.geography_id : ''],
      is_org_admin: [this.pageType === 'Edit' ? this.modalData.is_org_admin : false],
    };
    if (this.pageType === 'Add') {
      controls['setPassword'] = ['false'];
      controls['password'] = ['', [Validators.required, CustomFormValidator.passwordStrengthChecker]];
    }
    else {
      controls['wantPassword'] = ['false'];
    }
    this.adminForm = this.fb.group(controls);

    this.getAllService.getAll('statesAndDistricts').subscribe(
      (data: any) => {
        this.allStates = data.body.responseBody;
        var allStatesObject = Object.values(this.allStates);
        var allStatesArray = allStatesObject.map( function(val){
          return [val['state_name'], val['districts']];
        })
        this.allStates=Object.fromEntries(allStatesArray)
        this.allStateNames = Object.keys(this.allStates);
        if (this.modalData.state_name) {
          this.districts = this.allStates[this.modalData.state_name].sort((a, b) => a > b ? 1 : -1);
          this.adminForm.patchValue({
            state: this.modalData.state_name,
            district: this.modalData.district_name
          });
          this.adminForm.get('district').enable();
          this.cdr.markForCheck();
          this.cdr.detectChanges();
        }
      },
      (err: any) => {
        this.serviceError = err.responseHead 
          ? this.util.getIntlErrorMessage("LIST_ORGS", err.responseHead.statusCode, err.responseHead.statusDesc)
          : err;
      }
    );
  }

  stateDropdownList(e){
    if (e.target.value) {
      this.adminForm.get('district').enable();
      this.adminForm.get('district').patchValue('');
      this.districts = this.allStates[e.target.value].sort((a, b) => a > b ? 1 : -1);
    }
    else {
      this.districts = [];
      this.adminForm.get('district').disable();
    }
  }

  createAdmin(){
    const inputs = this.adminForm.value;
    const payload = {
      full_name: inputs.name,
      organization_id: this.modalData.organization_id || this.modalData.orgId,
      email: inputs.email,
      phone: inputs.phone,
      designation: inputs.designation,
      alternate_email: inputs.alternate_email,
      alternate_phone: inputs.alternate_phone,
      pincode_id: inputs.pincode_id,
      state_name: inputs.state,
      district_name: inputs.district,
      is_org_admin: inputs.is_org_admin,
    };
    payload.organization_id = parseInt(payload.organization_id, 10);
    if (this.modalData.id) {
      payload['id'] = this.modalData.id;
    }
    if (inputs['password']) {
      payload['password'] = inputs.password;
    }
    this.util.showLoader = true;
    this.dialogSrvc.createOrEditAdmin(payload).subscribe(
      (data: any) => {
        const title = this.pageType === 'Add' ? 'Added' : 'Updated';
        const msgIdentifier = this.pageType === 'Add' ? 'ADD' : 'UPDATE';
        const responseHead = data.body.responseHead;
        const successMsg = responseHead ? this.util.getSuccessMessage(`${msgIdentifier}_ADMIN`, responseHead.statusCode, responseHead.statusDesc) : responseHead;
        this.util.simpleDialog({
          title: title,
          message: successMsg,
        });
        this.util.showLoader = false;
        this.dialogRef.close();
      },
      (err: any) => {
        this.util.showLoader = false;
        this.serviceError = err.responseHead 
          ? this.util.getIntlErrorMessage("LIST_ORGS", err.responseHead.statusCode, err.responseHead.statusDesc)
          : err;
        this.util.simpleDialog({
          title: 'Error',
          message: this.serviceError,
        });
      }
    );
  }

  updatePassword(){
    const isAuto = this.adminForm.get('setPassword').value === 'true';
    if (isAuto) {
      this.adminForm.get('password').patchValue(this.util.generatePassword(15));
    }
    else {
      this.adminForm.get('password').patchValue('');
    }
  }

  updatePinCodeValidation() {
    if (this.adminForm.get('havePinCode').value === 'true') {
      this.adminForm.patchValue({
        state: '',
        district: ''
      });
      this.adminForm.get('pinCode').setValidators([Validators.required, Validators.minLength(6)]);
    }
    else {
      this.adminForm.get('pinCode').clearValidators();
      this.adminForm.patchValue({
        geography_id: '',
        pincode_id: ''
      });
    }
    this.cdr.detectChanges();
  }

  updatePwdFields() {
    if (this.adminForm.get('wantPassword').value === 'true') {
      this.adminForm.addControl('setPassword', new UntypedFormControl('false'));
      this.adminForm.addControl('password', new UntypedFormControl('', [Validators.required, CustomFormValidator.passwordStrengthChecker]));
    }
    else if (this.adminForm.contains('setPassword')){
      this.adminForm.removeControl('setPassword');
      this.adminForm.removeControl('password');
    }
    this.cdr.detectChanges();
  }

  loadGeography() {
    const pinCode = this.adminForm.get('pinCode').value;
    if (!pinCode) return;
    this.util.showLoader = true;
    this.serviceError = '';
    this.getService.get('geoByPincode', parseInt(pinCode)).subscribe(
      (data: any) => {
        const response = data.body.responseBody[0];
        this.adminForm.patchValue({
          state: response.state_name,
          district: response.district_name,
          geography_id: response.geography_id,
          pincode_id: response.pin_code_id
        });
        this.adminForm.get('district').enable();
        this.cdr.markForCheck();
        this.util.showLoader = false;
      },
      (err: any) => {
        this.util.showLoader = false;
        this.serviceError = err.responseHead 
          ? this.util.getIntlErrorMessage("PIN_FINDER", err.responseHead.statusCode, err.responseHead.statusDesc)
          : err;
      }
    );
  }

}
