import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

 @Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css']
})
export class SidenavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
