import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UploadService } from './upload.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { AdminService } from '@app/admin/admin.service';
import { UtilService } from '../services/util.service';
import { AddOrEditService } from '../table/addOrEdit.service';

export enum constants {
  BLUEPRINT = 'Blueprint',
  ASSESSMENT_PLAN = 'Assessment Plan'
}

@Component({
  selector: 'app-uploadfiles',
  templateUrl: './uploadfiles.component.html',
  styleUrls: ['./uploadfiles.component.scss'],
})
export class UploadfilesComponent implements OnInit {
  readonly constants: typeof constants = constants;
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: any[] = [];
  fileInfos?: Observable<any>;
  files: any[] = [];
  remarks: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public uploadService: UploadService,
    private adminService: AdminService,
    private addOrEditService: AddOrEditService,
    private util: UtilService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
  }
  selectFiles(event): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }

  uploadFiles(): void {
    this.message = [];

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    if (file) {
      let reader = new FileReader();
      reader.onload = () => {
        let reqBody = {
          folder: this.data.type,
          file: String(reader.result),
        };
        this.adminService.uploadIimage(reqBody).subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progressInfos[idx].value = Math.round(
                (100 * event.loaded) / event.total
              );
            } else if (event instanceof HttpResponse) {
              const response = event.body.responseBody;
              const msg = 'Uploaded the file successfully: ' + file.name;
              this.message.push({ message: msg, fileUrl: response.url });
              this.files.push(response.url);
            }
          },
          error: (err: any) => {
            this.progressInfos[idx].value = 0;
            const msg = 'Could not upload the file: ' + file.name;
            this.message.push(msg);
          },
        });
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
      reader.readAsDataURL(file);
    }
  }

  saveFile() {
    let postData: any;
    if (this.data.title === constants.BLUEPRINT) {
      postData = {
        id: this.data.id,
        blueprint: this.files[0]
      }
    } else {
      postData = {
        id: this.data.id,
        assessmentPlan: this.files,
        remarks: this.remarks
      }
    }
    this.addOrEditService.addOrEdit('courseVersion', postData).subscribe((data: any) => {
      const responseHead = data.body.responseHead;
      const successMsg = responseHead ? this.util.getSuccessMessage(`DOCUMENT_UPLOAD`, responseHead.statusCode, responseHead.statusDesc) : responseHead;
      this.util.simpleDialog({
        title: 'Added',
        message: `${successMsg}`,
      });
      this.dialog.closeAll();
    }, (err: any) => {
      this.util.showLoader = false;
      const serviceError = err.responseHead 
        ? this.util.getIntlErrorMessage("IMAGE_UPLOAD", err.responseHead.statusCode, err.responseHead.statusDesc)
        : err;
      this.util.simpleDialog({
        title: 'Error',
        message: `${serviceError}`,
      });
    });
  }

  downloadFile(file: any): void {
    const data = {
      key: file?.fileUrl,
    };
    this.adminService.download(data).subscribe(
      (data: any) => {
        const response = data.body.responseBody;
        console.log({ response });
        const link = document.createElement('a');
        link.download = 'download.pdf';
        link.target = "_blank";
        link.href = response.url;
        link.click();
        link.remove();
      },
      (err: any) => {
        this.util.errorNotification(err);
        this.cdr.markForCheck();
      }
    );
  }
}
