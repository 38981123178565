<ng-container>
  <div>
    <mat-tab-group
      class="tab"
      mat-stretch-tabs="true"
      appearance="outline"
      (selectedTabChange)="onTabChange($event)"
    >
      <div>
        <mat-tab label="Org Updates">
          <custom-table
            [hideTableTitle]="true"
            [tableData]="dataSource"
            [tableColumns]="orgUpdatesColumns"
            [tableActions]="[]"
            [isFilterable]="false"
            class="paginator"
          >
          </custom-table>
        </mat-tab>
      </div>
      <div *ngIf="isOrgSuperAdmin != true">
        <mat-tab label="My Updates">
          <custom-table
            [hideTableTitle]="true"
            [tableData]="dataSource"
            [tableColumns]="myUpdatesColumns"
            [tableActions]="[]"
            [isFilterable]="false"
          ></custom-table>
        </mat-tab>
      </div>
    </mat-tab-group>
  </div>
</ng-container>
