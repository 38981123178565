import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

interface DateRange {
    start: moment.Moment,
    end: moment.Moment
}

@Injectable({ providedIn: 'root' })
export class CustomFormValidator {

    static matchFieldValidator = (field1, field2):  ValidatorFn => {
        return (formGroup: UntypedFormGroup): ValidationErrors | null => {
            const field1Control = formGroup.get(field1);
            const matchFieldControl = formGroup.get(field2);
            if (field1Control && matchFieldControl){
                if (!matchFieldControl.value){
                    return null;
                }
                const obj = {};
                obj[field2] = true;
                return field1Control.value !== matchFieldControl.value ? obj : null;
            }
            return null;
        };
    }

    static shouldNotMatchFieldValidator = (field1, field2):  ValidatorFn => {
        return (formGroup: UntypedFormGroup): ValidationErrors | null => {
            const field1Control = formGroup.get(field1);
            const matchFieldControl = formGroup.get(field2);
            if (field1Control && matchFieldControl){
                if (!matchFieldControl.value){
                    return null;
                }
                const obj = {};
                obj[field2] = true;
                return field1Control.value === matchFieldControl.value ? obj : null;
            }
            return null;
        };
    }

    static passwordStrengthChecker(control: UntypedFormControl): ValidationErrors | null {
        const value: string | null = control.value;
        if (value) {
            const hasNumber = /\d/.test(value);
            const hasUpper = /[A-Z]/.test(value);
            const hasLower = /[a-z]/.test(value);
            const hasMinLength = value.length >= 8;

            if (!hasNumber) {
                return { missingNumber: true };
            }
            if (!hasUpper) {
                return { missingUpperCase: true };
            }
            if (!hasLower) {
                return { missingLowerCase: true };
            }
            if (!hasMinLength) {
                return { missingMinLength: true };
            }
        }
        return null;
    }

    static fileExtValidator = (allowedExtensions: string[]): ValidatorFn => {
        return (control: UntypedFormControl): ValidationErrors | null => {
            const file = control.value;
            if (file) {
                const path = file.replace(/^.*[\\\/]/, "");
                const extension = path.split(".")[1].toLowerCase();
                if (allowedExtensions.includes(extension)) {
                    return null;
                } else {
                    return {
                        requiredFileType: true
                    };
                }
            }
            return null;
        };
    }

    static dateIsBetween = (availableDateRanges: DateRange[], excludeBeforeCheck: boolean = false): ValidatorFn => {
        return (control: UntypedFormControl): ValidationErrors | null => {
            const val = control.value;
            if (val) {
                const isRangeExist = availableDateRanges.find((i) => {
                    const selectedDate = val.format('YYYY-MM-DD');
                    const startDate = i.start.format('YYYY-MM-DD');
                    const endDate = i.end.format('YYYY-MM-DD');
                    if (!excludeBeforeCheck && moment(selectedDate).isBefore(startDate)) {
                        return true;
                    }
                    return moment(selectedDate).isBetween(startDate, endDate, undefined, "[]");
                });
                if (isRangeExist !== undefined) {
                    return {
                        dateRangeError: true
                    };
                }
            }
            return null;
        };
    }
}