import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FinanceService } from '@app/finance/finance.service';
import { UtilService } from '@app/shared/services/util.service';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
})
export class InvoiceComponent implements OnInit {
  total: any;
  toWords: any;
  data: any;
  isLoading = true;
  organizationName: string;
  organizationAddress: string;
  orgEmail: string;
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<InvoiceComponent>,
    @Inject(MAT_DIALOG_DATA)
    public invoiceData: { id: string; customerId: string },
    private util: UtilService,
    private service: FinanceService
  ) {}

  ngOnInit(): void {
    this.toWords = this.util.getToWordsConfig();
    this.service
      .getInvoice(this.invoiceData.id, this.invoiceData.customerId)
      .subscribe((data: any) => {
        const response = data.body.responseBody;
        this.data = response.invoices[0];
        this.organizationName = response.organization_name;
        this.organizationAddress = response.organization_billing_address;
        this.orgEmail = response.seller_email;
        this.isLoading = false;
      });
  }

  download() {
    const div = document.getElementById('pdfTable');
    const options = {
      background: 'white',
      height: div.clientHeight,
      width: div.clientWidth,
    };

    html2canvas(div, options).then((canvas) => {
      //Initialize JSPDF
      let doc = new jsPDF('p', 'cm', 'a4');
      //Converting canvas to Image
      let imgData = canvas.toDataURL('image/PNG');
      //Add image Canvas to PDF
      doc.addImage(imgData, 'PNG', 0, 0, 22, 28);

      let pdfOutput = doc.output();
      // using ArrayBuffer will allow you to put image inside PDF
      let buffer = new ArrayBuffer(pdfOutput.length);
      let array = new Uint8Array(buffer);
      for (let i = 0; i < pdfOutput.length; i++) {
        array[i] = pdfOutput.charCodeAt(i);
      }
      const fileName = `${this.data.customer_name}_${this.data.id}.pdf`;
      doc.save(fileName);
    });
  }
}
