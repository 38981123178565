<div class="filter-container">
  <div class="filter-name mb-2" (click)="filterClickHandler()">
    <div #filterName>Filters<span class="ml-1" *ngIf="noOfFilters">({{noOfFilters}})</span></div>
  </div>
  <div class="filter-menu-container" *ngIf="isClicked">
    <div (click)="containerHandler($event)">
      <div class="row">
        <div
          class="col-4 border-right pl-3 pr-0 pt-0 pb-0"
          style="height: 100%"
        >
          <div class="option-col">
            <div *ngFor="let filter of filterData; let i = index">
              <div
                class="filter-option mb-1"
                (mouseover)="hoverActive = true; itemIndex = i"
                (mouseout)="hoverActive = false; itemIndex = -1"
                [ngStyle]="
                  ((hoverActive && i === itemIndex) ||
                    filter.name === selectedItem.name) && {
                    'background-color': 'palegreen'
                  }
                "
                (click)="selectedItem = filter; selected = i"
              >
                {{ filter.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-7">
          <ng-container [ngSwitch]="selectedItem.type">
            <ng-container *ngSwitchCase="'list'">
              <div class="p-2">
                <form [formGroup]="filterForm">
                <p>includes</p>
                <input
                  type="text"
                  class="mb-2 w-100 form-control form-control-alternative"
                  placeholder="Search"
                  formControlName="search"
                  (input)="onInputChange($event.target.value)"
                />
                <div class="list-box">
                  <div *ngFor="let item of selectedItem.data">
                    <input
                      type="checkbox"
                      (change)="
                        checkboxHandler(
                          $event,
                          $event.target.checked,
                          item,
                          selectedItem
                        )
                      "
                      [checked]="filterForm.value.tags?.[selectedItem.dataKeyName]?.includes(item)"
                    />
                    <label class="form-control-label chkBox ml-2">
                      {{ item }}
                    </label>
                  </div>
                </div>
                <ng-template
                  [ngTemplateOutlet]="Actions"
                  [ngTemplateOutletContext]="{ $implicit: selectedItem }"
                ></ng-template>
              </form>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              <div class="p-2">
                <form [formGroup]="filterForm">
                  <mat-radio-group
                    aria-label="Select an option"
                    class="d-flex flex-column mt-5 mb-5"
                    formControlName="option"
                  >
                    <mat-radio-button value="is">is</mat-radio-button>
                    <input
                      type="number"
                      *ngIf="filterForm.value.option === 'is'"
                      class="mb-2 ml-4 form-control form-control-alternative"
                      placeholder="Value"
                      formControlName="value"
                    />
                    <mat-radio-button value="between"
                      >is between</mat-radio-button
                    >
                    <div
                      class="d-flex"
                      *ngIf="filterForm.value.option === 'between'"
                    >
                      <input
                        type="number"
                        class="ml-4 w-50 form-control form-control-alternative"
                        placeholder="Start"
                        formControlName="start"
                      />
                      <input
                        type="number"
                        class="ml-1 w-50 form-control form-control-alternative"
                        placeholder="End"
                        formControlName="end"
                      />
                    </div>
                  </mat-radio-group>
                  <div class="mt-2">
                    <ng-template
                      [ngTemplateOutlet]="Actions"
                      [ngTemplateOutletContext]="{
                        $implicit: selectedItem
                      }"
                    ></ng-template>
                  </div>
                </form>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'text'">
              <div class="p-2">
                <form [formGroup]="filterForm">
                  <mat-radio-group
                    aria-label="Select an option"
                    class="d-flex flex-column mt-5 mb-5"
                    formControlName="option"
                  >
                    <mat-radio-button value="is">is</mat-radio-button>
                    <input
                      type="text"
                      *ngIf="filterForm.value.option === 'is'"
                      class="mb-2 ml-4 form-control form-control-alternative"
                      placeholder="Value"
                      formControlName="value"
                    />
                    <mat-radio-button value="contains"
                      >contains</mat-radio-button
                    >
                    <input
                      type="text"
                      class="mb-2 ml-4 form-control form-control-alternative"
                      *ngIf="filterForm.value.option === 'contains'"
                      placeholder="Value"
                      formControlName="value"
                    />
                  </mat-radio-group>
                  <div class="mt-2">
                    <ng-template
                      [ngTemplateOutlet]="Actions"
                      [ngTemplateOutletContext]="{
                        $implicit: selectedItem
                      }"
                    ></ng-template>
                  </div>
                </form>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              <div class="pl-2">
                <form [formGroup]="filterForm">
                  <mat-radio-group
                    aria-label="Select an option"
                    class="d-flex flex-column mt-3 mb-5"
                    formControlName="option"
                  >
                    <mat-radio-button value="is">is</mat-radio-button>
                    <mat-form-field
                      appearance="standard"
                      class="mb-3 ml-3"
                      *ngIf="filterForm.value.option === 'is'"
                    >
                      <mat-label>Choose a date</mat-label>
                      <input matInput [matDatepicker]="picker" formControlName="start" />
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker-toggle
                        matIconSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-radio-button value="between"
                      >is between</mat-radio-button
                    >
                    <mat-form-field
                      appearance="legacy"
                      class="ml-3"
                      *ngIf="filterForm.value.option === 'between'"
                    >
                      <mat-label>Enter a date range</mat-label>
                      <mat-date-range-input
                        [rangePicker]="dateRangePicker"
                      >
                        <input
                          matStartDate
                          formControlName="start"
                          placeholder="Start date"
                          #dateRangeStart
                        />
                        <input
                          matEndDate
                          placeholder="End date"
                          formControlName="end"
                          #dateRangeEnd
                        />
                      </mat-date-range-input>
                      <mat-datepicker-toggle matPrefix [for]="dateRangePicker">
                      </mat-datepicker-toggle>
                      <mat-date-range-picker
                        #dateRangePicker
                      ></mat-date-range-picker>
                    </mat-form-field>
                  </mat-radio-group>
                  <div class="mt-2">
                    <ng-template
                      [ngTemplateOutlet]="Actions"
                      [ngTemplateOutletContext]="{
                        $implicit: selectedItem
                      }"
                    ></ng-template>
                  </div>
                </form>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'all'">
              <div class="p-2 mt-5">
                <p>has any</p>
                <form [formGroup]="filterForm">
                  <input
                    type="text"
                    class="mb-5 form-control form-control-alternative"
                    placeholder="Search"
                    formControlName="value"
                  />
                </form>
                <ng-template
                  [ngTemplateOutlet]="Actions"
                  [ngTemplateOutletContext]="{ $implicit: selectedItem }"
                ></ng-template>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #Actions let-item>
  <div class="d-flex justify-content-between mt-2">
    <button mat-button (click)="clearFilter(item.type)">Clear</button>
    <button mat-button class="button-apply" type="submit" (click)="applyFilter(item.type, item.dataKeyName, item.name)">
      Apply Filter
    </button>
  </div>
</ng-template>
