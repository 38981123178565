import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private isScriptLoaded = false;

  loadChatScript() {
    if (this.isScriptLoaded) {
      return;
    }

    const script = document.createElement('script');
    script.src = environment.FRESHDESK_CHAT_KEY;
    script.setAttribute('chat', 'true');
    script.async = true;
    script.defer = true;

    script.onload = () => {
      this.isScriptLoaded = true;
      console.log('Freshdesk chat script loaded successfully.');
    };

    script.onerror = () => {
      console.error('Error loading Freshdesk chat script.');
    };

    document.body.appendChild(script);
  }
}
